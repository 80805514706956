/* eslint-disable react/no-array-index-key */
/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable operator-linebreak */
/* eslint-disable no-console */
/* eslint-disable no-inner-declarations */
/* eslint-disable import/prefer-default-export */
import React, { useState } from 'react';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { isEmpty, isNil } from 'lodash';

import { Empty, Accept, Reject } from '../../icons';
import { Loader } from '../Loader';
import { Button } from '../atoms/Button';
import useAuth from '../../context/Auth';

import { useUser } from '../../context';

export function Invites() {
  const navigate = useNavigate();
  const { signOut, kycDetails } = useAuth();
  const {
    invites,
    isLoading: isPageLoading,
    handleIsLoading,
    handleAcceptInvite,
    handleRejectInvite,
  } = useUser();

  const [isLoading, setIsLoading] = useState(false);

  const inviteAccept = async (id) => {
    try {
      setIsLoading(true);
      await handleAcceptInvite(id, (error) => {
        if (error) {
          toast.error(
            error?.response?.data?.message ?? 'accept invitation failed',
          );
          return;
        }
        handleIsLoading(true);
      });
      setIsLoading(false);
      navigate('/home');
    } catch (error) {
      toast.error(error?.response?.data?.message ?? 'accept invitation failed');
      if (process.env.NODE_ENV === 'development') {
        console.log('handle inviteAccept  ======>>>  error', error);
      }
    } finally {
      setIsLoading(false);
      handleIsLoading(false);
    }
  };

  const inviteReject = async (id) => {
    try {
      setIsLoading(true);
      await handleRejectInvite(id, async (error) => {
        if (error) {
          toast.error(
            error?.response?.data?.message ?? 'reject invitation failed',
          );
          return;
        }

        handleIsLoading(true);
        if (isNil(kycDetails?.kyc) || isEmpty(kycDetails?.kyc)) {
          await signOut('signout');
          return;
        }
        navigate('/home');
      });
    } catch (error) {
      toast.error(error?.response?.data?.message ?? 'reject invitation failed');
      if (process.env.NODE_ENV === 'development') {
        console.log('handle inviteReject  ======>>>  error', error);
      }
    } finally {
      setIsLoading(false);
      handleIsLoading(false);
    }
  };

  return (
    <>
      <Loader isActive={isPageLoading} />
      {!invites?.length && (
        <div className="flex flex-col items-center mt-40 font-bold">
          <Empty className="m-auto mb-4" />
          <p>No Invitation yet</p>
        </div>
      )}
      {invites?.length >= 1 && (
        <div className="mt-[60px] md:mb-6 mb-10 lg:px-7 px-4 py-6 bg-white rounded-[14px] shadow-[0px_3px_8px_-1px_rgba(50,50,71,0.05)_0px_0px_1px_0px_rgba(12,26,75,0.24);]">
          <p className="text-[#16192C] font-interBold text-xl mb-[30px]">
            Invite Details
          </p>
          {invites.map((item, index) => (
            <div key={index} className="grid md:grid-cols-4 gap-y-6">
              <p className="flex flex-col">
                <small className="text-[#64748B] font-interMedium text-sm mb-2">
                  Company Name
                </small>{' '}
                <span className="text-base text-black font-interMedium capitalize">
                  {item.business.name}
                </span>
              </p>
              <p className="flex flex-col">
                <small className="text-[#64748B] font-interMedium text-sm mb-2">
                  Role
                </small>{' '}
                <span className="text-base text-black font-interMedium capitalize">
                  {item.role.role.split('_').join(' ')}
                </span>
              </p>
              <p className="flex flex-col">
                <small className="text-[#64748B] font-interMedium text-sm mb-2">
                  Expiry Date
                </small>{' '}
                <span className="text-base text-black font-interMedium">
                  {moment(item.invite_expiry).format('Do of MMMM, YYYY.')}
                </span>
              </p>
              <div>
                <div className="md:flex items-center gap-3 justify-end hidden">
                  <Accept
                    onClick={() => {
                      inviteAccept(item.id);
                    }}
                  />
                  <Reject
                    onClick={() => {
                      inviteReject(item.id);
                    }}
                  />
                </div>
                <div className="md:hidden flex gap-2">
                  <Button
                    loading={isLoading}
                    isDisabled={isLoading}
                    outline
                    type="button"
                    label="Accept"
                    backgroundColor="#12B76A"
                    textColor="white"
                    size="medium"
                    custom
                    onClick={() => {
                      inviteAccept(item.id);
                    }}
                  />
                  <Button
                    loading={isLoading}
                    isDisabled={isLoading}
                    outline
                    type="button"
                    label="Reject"
                    backgroundColor="#F04438"
                    textColor="white"
                    size="medium"
                    custom
                    onClick={() => {
                      inviteReject(item.id);
                    }}
                  />
                </div>
              </div>
            </div>
          ))}
        </div>
      )}
    </>
  );
}
