/* eslint-disable no-console */
/* eslint-disable react/prop-types */
import clsx from 'clsx';
import React from 'react';
import { Document, Page, pdfjs } from 'react-pdf';

pdfjs.GlobalWorkerOptions.workerSrc = new URL(
  'pdfjs-dist/build/pdf.worker.min.js',
  import.meta.url,
).toString();

function PDFViewer({
  className,
  file,
  page = {
    pageNumber: 1,
  },
  docs = {
    file: '',
    className: '',
    onDocumentLoadSuccess: () => {
      console.log('pdf successful');
    },
    onLoadError: () => {
      console.log('pdf error');
    },
  },
}) {
  return (
    <div>
      <Document
        file={file}
        className={clsx(docs.className, className)}
        onLoadSuccess={docs.onDocumentLoadSuccess}
        onLoadError={docs.onLoadError}
      >
        {/* eslint-disable-next-line react/jsx-props-no-spreading */}
        <Page {...page} />
      </Document>
    </div>
  );
}
export default PDFViewer;
