/* eslint-disable react/function-component-definition */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/react-in-jsx-scope */
import { useMemo } from 'react';
import { Controller, useFormContext, useWatch } from 'react-hook-form';
import { NumericFormat } from 'react-number-format';
import { isEmpty, isNil, toNumber } from 'lodash';
import clsx from 'clsx';
import { TbTrash } from 'react-icons/tb';

import { useUser } from '../../context';
import { Input } from '../atoms/Input';

const Amount = ({ itemId }) => {
  const { business } = useUser();
  const { control } = useFormContext();
  const items = useWatch({
    name: 'items',
    control,
  });

  const amount = useMemo(() => {
    let total = 0;

    const item = items[itemId];

    if (!isNil(items) && !isEmpty(items)) {
      if (!isNil(items[itemId])) {
        const { price, quantity } = item;
        total = toNumber(quantity) * toNumber(price);
      }
    }

    return total;
  }, [items, itemId]);

  return (
    <div className="w-full h-full flex justify-center items-start pt-2">
      <NumericFormat
        allowNegative={false}
        decimalScale="2"
        prefix={`${business?.country?.currency} `}
        value={amount}
        customInput={Input}
        inputWrapperClassName={clsx('w-full mb-0')}
        className={clsx(
          'md:!h-[38px] max-w-[138px] border-0 !pl-2 !pr-0 text-xs bg-white placeholder:text-xs placeholder:',
        )}
        fixedDecimalScale
        placeholder={`${business?.country?.currency} 0.00`}
        thousandSeparator=","
        disabled
        allowLeadingZeros={false}
      />
    </div>
  );
};

export function InvoiceItem({ id, deleteInvoiceItem, totalItems }) {
  const { business } = useUser();
  const { register, formState, control } = useFormContext();

  let errors = {};

  if (!isNil(formState?.errors?.items) && !isEmpty(formState?.errors?.items)) {
    if (!isNil(formState?.errors?.items[id])) {
      errors = formState?.errors?.items[id];
    }
  }

  return (
    <tr className="pt-2 mb-5">
      <td className="w-[55px] max-w-[55px] h-[55px]">
        <div className="w-full h-full flex justify-start items-start pl-2 pt-2">
          <div className="w-[38px] h-[38px] flex justify-start items-center">
            <p>{id + 1}</p>
          </div>
        </div>
      </td>

      <td className="w-[280px] max-w-[280px] h-[55px]">
        <div className="w-full h-full flex justify-center items-start pt-2">
          <Input
            {...register(`items.${id}.item`, { required: true })}
            placeholder="Enter item description"
            required
            inputWrapperClassName={clsx('w-full mb-0')}
            className={clsx(
              'md:!h-[38px] max-w-[280px] !pl-2 !pr-0 placeholder:text-sm text-sm border-gray-300 bg-white',
            )}
            autoComplete="off"
            error={errors?.item?.message}
          />
        </div>
      </td>

      <td className="w-[90px] max-w-[90px] h-[55px]">
        <div className="w-full h-full flex justify-center items-start pt-2">
          <Controller
            render={({ field: { onChange, value } }) => (
              <NumericFormat
                allowNegative={false}
                decimalScale="2"
                onValueChange={(v) => onChange(v.value)}
                value={value}
                customInput={Input}
                inputWrapperClassName={clsx('w-full mb-0')}
                className={clsx(
                  'md:!h-[38px] max-w-[90px] !px-2 border-gray-300 text-xs placeholder:text-xs',
                )}
                thousandSeparator=","
                allowLeadingZeros={false}
                error={errors?.quantity?.message}
              />
            )}
            name={`items.${id}.quantity`}
            control={control}
            rules={{ required: true, valueAsNumber: true }}
          />
        </div>
      </td>

      <td className="w-[124px] max-w-[105px] h-[55px]">
        <div className="w-full h-full flex justify-center items-start pt-2">
          <Controller
            render={({ field: { onChange, value } }) => (
              <NumericFormat
                allowNegative={false}
                decimalScale="2"
                onValueChange={(v) => onChange(v.value)}
                prefix={`${business?.country?.currency ?? ''} `}
                value={value}
                customInput={Input}
                inputWrapperClassName={clsx('w-full mb-0')}
                className={clsx(
                  'md:!h-[38px] max-w-[124px] border-gray-300 !pl-2 !pr-2 text-xs placeholder:text-xs ',
                )}
                fixedDecimalScale
                placeholder={`${business?.country?.currency} 0.00`}
                thousandSeparator=","
                allowLeadingZeros={false}
                error={errors?.price?.message}
              />
            )}
            name={`items.${id}.price`}
            control={control}
            rules={{ required: true, valueAsNumber: true }}
          />
        </div>
      </td>

      <td className="w-[138px] max-w-[105px] h-[55px]">
        <Amount itemId={id} />
      </td>

      <td className="w-[55px] max-w-[55px] h-[55px]">
        <div className="w-full h-full flex justify-center items-start pl-2 pt-2">
          <div
            className={clsx(
              'w-[38px] h-[38px] flex justify-center items-center hover:text-red-500',
              { 'cursor-pointer': totalItems > 1 },
            )}
            onClick={() => {
              if (totalItems > 1) {
                const removeId = id - 1;
                deleteInvoiceItem(removeId);
              }
            }}
          >
            {totalItems > 1 && (
              <span>
                <TbTrash className={clsx('h-4 w-4')} />
              </span>
            )}
          </div>
        </div>
      </td>
    </tr>
  );
}

export default InvoiceItem;
