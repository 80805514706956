/* eslint-disable no-console */
/* eslint-disable wrap-iife */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable operator-linebreak */
import { yupResolver } from '@hookform/resolvers/yup';
import { isEmpty, isNil, merge, omit, pick } from 'lodash';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import useFormPersist from 'react-hook-form-persist';
import * as yup from 'yup';
import clsx from 'clsx';
import { IoArrowBack } from 'react-icons/io5';
import { toast } from 'react-toastify';

import OnboardLayout from '../../layout/onboardingLayout';
import { Button, FileUploadForm } from '../../atoms/index';
import storageName from '../../../helpers/storageHelpers';
import { useAuth, useUser } from '../../../context';

const schema = yup.object().shape({
  proof_of_address_url: yup.string().url(),
  government_issue_id: yup.object({
    idUrl: yup.string().url(),
  }),
});

function UnregisteredDoc() {
  const { kycDetails, handleAuthenticateUser } = useAuth();
  const { handleIsLoading, handleCreateBusinesskycDraft, isLoading } =
    useUser();

  const {
    handleSubmit,
    getValues,
    setValue,
    watch,
    formState: { errors },
  } = useForm({ resolver: yupResolver(schema) });
  useFormPersist(storageName.business_creation, {
    watch,
    setValue,
    storage: window.localStorage,
  });

  const navigate = useNavigate();

  const setPoa = (res) => {
    setValue('proof_of_address_url', res);
  };
  const setUgi = (res) => {
    setValue('government_issue_id.idUrl', res);
  };

  const handleBusinessSubmit = async (values) => {
    try {
      handleIsLoading(true);
      const parsedData = merge(
        pick(values, ['government_issue_id', 'proof_of_address_url']),
        {
          government_issue_id: merge(
            {},
            {
              idUrl: values.government_issue_id?.idUrl,
              verificationMode: 'FUNDR',
              verificationType: 'NG-GOVT-ID',
              verificationValue: null,
            },
            kycDetails?.kyc?.business_details?.government_issue_id ?? {},
          ),
        },
      );

      handleIsLoading(true);
      await handleCreateBusinesskycDraft(parsedData, (error) => {
        if (error) {
          toast.error(
            error?.response?.data?.message ?? 'kyc information save failed',
          );
          return;
        }
        toast.success('kyc information saved successfully');
        navigate('/onboard/unregistered/review');
      });
    } catch (error) {
      if (process.env.NODE_ENV === 'development') {
        console.log('handle unregistered document ======>>> error', error);
      }
    } finally {
      await handleAuthenticateUser();
      handleIsLoading(false);
    }
  };

  useEffect(() => {
    if (isNil(kycDetails?.kyc) || isEmpty(kycDetails?.kyc)) {
      return;
    }

    setValue(
      'business_name_form_asset_url',
      kycDetails?.kyc?.business_details?.business_name_form_asset_url,
    );

    setValue('proof_of_address_url', kycDetails?.kyc?.proof_of_address_url);

    setValue(
      'government_issue_id',
      pick(kycDetails?.kyc?.business_details?.government_issue_id, [
        'status',
        'verificationStatus',
        'verificationMode',
        'verificationType',
        'verificationValue',
        'idUrl',
      ]),
    );

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <OnboardLayout>
      <div className="lg:px-16 pl-12 pr-8 xl:w-[65%] mx-auto">
        <div
          className="sm:mt-[5rem] mt-24 text-base text-[#292D32] font-interMedium flex items-center gap-x-2 lg:mb-5 mb-3 cursor-pointer"
          onClick={() => navigate('/home')}
          role="button"
          onKeyDown={() => {}}
          tabIndex={-1}
        >
          <IoArrowBack className={clsx('w-5 h-5 text-[#3976E8]')} />
          <span className="hidden md:block text-[#3976E8]">
            Back to Dashboard
          </span>
        </div>

        <div className="flex flex-col py-6 mx-2 xl:w[60rem]">
          <p className="mb-10 text-xl font-interSemiBold">
            Unregistered Business
          </p>
          <form onSubmit={handleSubmit(handleBusinessSubmit)}>
            <div className="lg:gap-y-2 grid">
              <FileUploadForm
                label="Upload Utility Bill"
                response={setPoa}
                persisted={getValues('proof_of_address_url')}
                error={errors.proof_of_address_url?.message}
              />
              <FileUploadForm
                label="Upload Government Issued ID"
                response={setUgi}
                persisted={getValues('government_issue_id.idUrl')}
                error={errors.government_issue_id?.idUrl?.message}
              />
            </div>

            <div className="mt-10">
              <Button
                loading={isLoading}
                type="submit"
                label="Save and Continue"
                btnColor="default"
                size="block"
                className={clsx('h-[55px]')}
              />
            </div>
          </form>
        </div>
      </div>
    </OnboardLayout>
  );
}

export default UnregisteredDoc;
