/* eslint-disable object-curly-newline */
/* eslint-disable no-console */
/* eslint-disable camelcase */
import React, {
  createContext,
  useMemo,
  useContext,
  useEffect,
  useState,
} from 'react';
import { first, isEmpty, isNil, merge, pick } from 'lodash';
import { format, subWeeks } from 'date-fns';

import useAuth from '../Auth';
import {
  createBusinessSectionVirtualAccount,
  fetchTransactionDeposits,
  getActiveInvites,
  getBalance,
  getTransactions,
  getVirtualAccounts,
  getBusinessCollectionAccounts,
  getVirtualAccountTransactions,
  fetchWithdrawals,
  getBusinessDetails,
  updateBusinessDetails,
  withdrawalRequest,
  fetchBusinessTypes,
  getInviteRoles,
  sendInvite,
  getBanks,
  validateBankAccount,
  createBusinessKycDraft,
  validateBvn,
  registerBusiness,
  fetchBusinessTeams,
  fetchBusinessTeam,
  acceptInvite,
  rejectInvite,
  getTransactionExportCsv,
  fetchReferAndEarn,
  fetchAllTransactionAlert,
  fetchBusinessTransactionAlert,
} from '../Actions/user';

export function useProviderUser() {
  const [balance, setBalance] = useState(0);
  const [transactions, setTransactions] = useState([]);
  const [virtualAccounts, setVirtualAccounts] = useState([]);
  const [dynamicAccounts, setDynamicAccounts] = useState([]);
  const [invites, setInvites] = useState([]);
  const [deposits, setDeposits] = useState([]);
  const [accounts, setAccounts] = useState([]);
  const [virtualAccountDeposits, setVirtualAccountDeposits] = useState([]);
  const [withdrawals, setWithdrawals] = useState([]);
  const [business, setBusiness] = useState({});
  const [businessTypes, setBusinessTypes] = useState();
  const [inviteRoles, setInviteRoles] = useState([]);

  const [banks, setBanks] = useState([]);
  const [businessTeams, setBusinessTeams] = useState([]);
  const [businessTeamMembers, setBusinessTeamMembers] = useState([]);
  const [referDetails, setReferDetails] = useState();
  const [transactionAlert, setTransactionAlert] = useState([]);
  const [businessTransactionAlert, setBusinessTransactionAlert] = useState([]);

  const [isLoading, setIsLoading] = useState(false);

  const today = new Date();

  const { userDetails: user, authToken, kycDetails, signOut } = useAuth();

  const handleIsLoading = (loading = true) => setIsLoading(loading);

  const userBusinessId = useMemo(() => user?.business?.id ?? 0, [user]);

  async function handleFetchBusinessTypes() {
    try {
      const res = await fetchBusinessTypes();
      if (res.data.response === true) {
        setBusinessTypes(res.data.data.business_types);
      }
    } catch (error) {
      if (process.env.NODE_ENV === 'development') {
        console.log(
          'handleFetchBusinessTypes ------->>> error =====>>>',
          error,
        );
      }
    }
  }

  async function handleFetchBanks() {
    try {
      const res = await getBanks();
      if (res.data.response === true) {
        setBanks(res.data.data.banks);
      }
    } catch (error) {
      if (process.env.NODE_ENV === 'development') {
        console.log('handleFetchBanks ------->>> error =====>>>', error);
      }
    }
  }
  async function handleFetchBusinessTeam(businessTeamId) {
    try {
      const res = await fetchBusinessTeam(businessTeamId);
      if (res.data.response === true) {
        setBusinessTeamMembers(res.data.data.members);
      }
    } catch (error) {
      if (process.env.NODE_ENV === 'development') {
        console.log('handleFetchBusinessTeam ------->>> error =====>>>', error);
      }
    }
  }
  const handlePromiseResolveReject = async (
    promise,
    callback = () => {},
    dataString = null,
  ) => {
    try {
      if (promise.status === 'rejected') {
        throw promise.reason;
      }
      if (!isNil(dataString) && !isEmpty(dataString)) {
        if (typeof callback === 'function') {
          callback(pick(promise?.value, [dataString]));
        }
        return;
      }
      if (typeof callback === 'function') {
        callback();
      }
    } catch (error) {
      if (error?.status === 401) {
        await signOut();
      }
      if (process.env.NODE_ENV === 'development') {
        console.log(
          'handlePromiseResolveReject -------->>> error =========>> ',
          error,
        );
      }
    }
  };

  const handleFetchUserDetails = async (
    callback = () => {},
    showLoading = false,
    businessId = userBusinessId,
  ) => {
    try {
      if (kycDetails?.kyc?.status !== 'completed') return;
      if (showLoading) {
        setIsLoading(true);
      }
      // eslint-disable-next-line operator-linebreak
      const [
        respTransactions,
        respBalance,
        respVirtualAccount,
        respInvites,
        respTransactionDeposits,
        respCollectionsAccounts,
        respWithdrawals,
        respBusiness,
        respInviteRoles,
        respBusinessTeams,
        respReferDetails,
        respTransactionAlert,
        respBusinessTransactionAlert,
      ] = await Promise.allSettled([
        getTransactions(businessId),
        getBalance(businessId),
        getVirtualAccounts(businessId),
        getActiveInvites(),
        fetchTransactionDeposits(businessId),
        getBusinessCollectionAccounts(businessId),
        fetchWithdrawals(businessId),
        getBusinessDetails(businessId),
        getInviteRoles(),
        fetchBusinessTeams(businessId),
        fetchReferAndEarn(),
        fetchAllTransactionAlert(),
        fetchBusinessTransactionAlert(),
      ]);

      // console.log(
      //   'respWithdrawals.data.data.balance =========>>> ',
      //   respWithdrawals.data.data,
      // );

      handlePromiseResolveReject(respTransactions, () => {
        setTransactions(respTransactions?.value?.data.data.transactions);
      });

      handlePromiseResolveReject(respBalance, () => {
        setBalance(respBalance?.value?.data.data.balance);
      });

      handlePromiseResolveReject(respVirtualAccount, () => {
        setVirtualAccounts(
          respVirtualAccount?.value?.data.data.reserved_accounts,
        );
        setDynamicAccounts(
          respVirtualAccount?.value?.data.data.dynamic_accounts,
        );
      });

      handlePromiseResolveReject(respInvites, () => {
        setInvites(respInvites?.value?.data.data.invites);
      });

      handlePromiseResolveReject(respTransactionDeposits, () => {
        setDeposits(respTransactionDeposits?.value?.data.data.deposits);
      });

      handlePromiseResolveReject(respCollectionsAccounts, () => {
        setAccounts(respCollectionsAccounts?.value?.data.data);
      });

      handlePromiseResolveReject(respWithdrawals, () => {
        setWithdrawals(respWithdrawals?.value?.data.data.settlements);
      });

      handlePromiseResolveReject(respBusiness, () => {
        setBusiness(respBusiness?.value?.data.data?.business);
      });

      handlePromiseResolveReject(respInviteRoles, () => {
        setInviteRoles(respInviteRoles?.value?.data.data.roles);
      });

      handlePromiseResolveReject(respBusinessTeams, () => {
        setBusinessTeams(respBusinessTeams?.value?.data.data.teams);
      });

      handlePromiseResolveReject(respReferDetails, () => {
        setReferDetails(respReferDetails?.value?.data.data);
      });
      handlePromiseResolveReject(respTransactionAlert, () => {
        setTransactionAlert(respTransactionAlert?.value?.data.data);
      });

      handlePromiseResolveReject(respBusinessTransactionAlert, () => {
        setBusinessTransactionAlert(
          respBusinessTransactionAlert?.value?.data.data,
        );
      });

      if (typeof callback === 'function') {
        callback();
      }
    } catch (error) {
      if (process.env.NODE_ENV === 'development') {
        console.log(
          'useUser -------->>>> handleFetchUserDetails -------->>> error =========>> ',
          error,
        );
      }
    } finally {
      setIsLoading(false);
    }
    // eslint-disable-next-line operator-linebreak
  };

  const handleCreateBusinessSectionVirtualAccount = async (
    values,
    callback = () => {},
    showIsLoading = false,
  ) => {
    try {
      if (showIsLoading) {
        handleIsLoading(true);
      }
      const res = await createBusinessSectionVirtualAccount(
        user.business.id,
        values,
      );

      const { data } = res;

      if (typeof callback === 'function') {
        callback(null, data);
      }

      return res;
    } catch (error) {
      callback(error);
      if (process.env.NODE_ENV === 'development') {
        console.log(
          'handleCreateBusinessSectionVirtualAccount  ------->>> error =======>>>',
          error,
        );
      }
      return Promise.reject(error);
    } finally {
      await handleFetchUserDetails();
      handleIsLoading(false);
    }
  };

  const handleFetchTransactionDeposit = async (
    businessId,
    config = {},
    showLoading = false,
  ) => {
    try {
      const formatString = 'yyyy-MM-dd';
      if (showLoading) {
        setIsLoading(true);
      }
      const res = await fetchTransactionDeposits(
        businessId,
        merge({}, config, {
          params: merge(
            {},
            {
              start_date: format(subWeeks(today, 1), formatString),
              end_date: format(today, formatString),
              page: 1,
              per_page: 20,
            },
            config.params,
          ),
        }),
      );
      setDeposits(res.data.data.deposits);
      return res;
    } catch (error) {
      if (process.env.NODE_ENV === 'development') {
        console.log(
          'handleFetchTransactionDeposit ------->>> error =======>>>',
          error,
        );
      }
      return Promise.reject(error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleUpdateBusinessDetails = async (
    payload = {},
    callback = () => {},
    showIsLoading = true,
    businessId = userBusinessId,
  ) => {
    try {
      if (showIsLoading) {
        handleIsLoading(true);
      }

      const res = await updateBusinessDetails(businessId, payload);
      const { data } = res;
      if (typeof callback === 'function') {
        callback(null, data);
      }
      return res;
    } catch (error) {
      if (process.env.NODE_ENV === 'development') {
        console.log(
          'handleUpdateBusinessDetails ------->>> error =======>>>',
          error,
        );
      }
      callback(error);
      return Promise.reject(error);
    } finally {
      await handleFetchUserDetails();
      handleIsLoading(false);
    }
  };
  async function handleFetchVirtualActionTransactions(
    methodId,
    filter = {},
    showIsLoading = false,
    businessId = userBusinessId,
  ) {
    try {
      if (showIsLoading) {
        setIsLoading(true);
      }
      const res = await getVirtualAccountTransactions(
        businessId,
        methodId,
        filter,
      );

      setVirtualAccountDeposits(res.data.data.revenue);

      return res;
    } catch (error) {
      if (process.env.NODE_ENV === 'development') {
        console.log(
          'handleFetchVirtualActionTransactions ------->>> error =======>>>',
          error,
        );
      }

      return Promise.reject(error);
    } finally {
      setIsLoading(false);
    }
  }

  const handleWithdrawalRequest = async (
    payload,
    callback = () => {},
    showIsLoading = false,
    businessId = userBusinessId,
  ) => {
    try {
      if (showIsLoading) {
        setIsLoading(true);
      }
      const res = await withdrawalRequest(businessId, payload);

      const { data } = res;

      if (typeof callback === 'function') {
        callback(null, data);
      }

      return res;
    } catch (error) {
      if (process.env.NODE_ENV === 'development') {
        console.log(
          'handleWithdrawalRequest ------->>> error =======>>>',
          error,
        );
      }
      callback(error);
      return Promise.reject(error);
    } finally {
      await handleFetchUserDetails();
      setIsLoading(false);
    }
  };

  const handleSendInvite = async (
    payload,
    callback = () => {},
    showIsLoading = false,
  ) => {
    try {
      if (showIsLoading) {
        setIsLoading(true);
      }
      const res = await sendInvite(payload);

      const { data } = res;

      if (typeof callback === 'function') {
        callback(null, data);
      }
    } catch (error) {
      if (process.env.NODE_ENV === 'development') {
        console.log('handleSendInvite ------->>> error =======>>>', error);
      }
      callback(error);
    } finally {
      await handleFetchUserDetails();
      setIsLoading(false);
    }
  };

  const handleValidateBankAccount = async (
    payload,
    callback = () => {},
    showIsLoading = false,
  ) => {
    try {
      if (showIsLoading) {
        setIsLoading(true);
      }
      const res = await validateBankAccount(payload);
      const { data } = res;
      if (typeof callback === 'function') {
        callback(null, data);
      }
    } catch (error) {
      if (process.env.NODE_ENV === 'development') {
        console.log(
          'handleValidateBankAccount ------->>> error =======>>>',
          error,
        );
      }
      callback(error);
    } finally {
      await handleFetchUserDetails();
      setIsLoading(false);
    }
  };
  const handleValidateBvn = async (
    payload,
    callback = () => {},
    showIsLoading = false,
  ) => {
    try {
      if (showIsLoading) {
        setIsLoading(true);
      }
      const res = await validateBvn(payload);
      const { data } = res;
      if (typeof callback === 'function') {
        callback(null, data);
      }
    } catch (error) {
      if (process.env.NODE_ENV === 'development') {
        console.log('handleValidateBvn ------->>> error =======>>>', error);
      }
      callback(error);
    } finally {
      await handleFetchUserDetails();
      setIsLoading(false);
    }
  };
  const handleCreateBusinesskycDraft = async (
    payload,
    callback = () => {},
    showIsLoading = false,
  ) => {
    try {
      if (showIsLoading) {
        setIsLoading(true);
      }
      const res = await createBusinessKycDraft(payload);
      const { data } = res;
      if (typeof callback === 'function') {
        callback(null, data);
      }
    } catch (error) {
      if (process.env.NODE_ENV === 'development') {
        console.log(
          'handleCreateBusinesskycDraft ------->>> error =======>>>',
          error,
        );
      }
      callback(error);
    } finally {
      await handleFetchUserDetails();
      setIsLoading(false);
    }
  };
  const handleRegisterBusiness = async (
    payload,
    callback = () => {},
    showIsLoading = false,
  ) => {
    try {
      if (showIsLoading) {
        setIsLoading(true);
      }
      const res = await registerBusiness(payload);
      const { data } = res;
      if (typeof callback === 'function') {
        callback(null, data);
      }
    } catch (error) {
      if (process.env.NODE_ENV === 'development') {
        console.log(
          'handleCreateBusinesskycDraft ------->>> error =======>>>',
          error,
        );
      }
      callback(error);
    } finally {
      await handleFetchUserDetails();
      setIsLoading(false);
    }
  };
  const handleAcceptInvite = async (
    id,
    callback = () => {},
    showIsLoading = false,
  ) => {
    try {
      if (showIsLoading) {
        setIsLoading(true);
      }
      const res = await acceptInvite(id);
      const { data } = res;
      if (typeof callback === 'function') {
        callback(null, data);
      }
    } catch (error) {
      if (process.env.NODE_ENV === 'development') {
        console.log('handleAcceptInvite ------->>> error =======>>>', error);
      }
      callback(error);
    } finally {
      await handleFetchUserDetails();
      setIsLoading(false);
    }
  };

  const handleRejectInvite = async (
    id,
    callback = () => {},
    showIsLoading = false,
  ) => {
    try {
      if (showIsLoading) {
        setIsLoading(true);
      }
      const res = await rejectInvite(id);
      const { data } = res;
      if (typeof callback === 'function') {
        callback(null, data);
      }
    } catch (error) {
      if (process.env.NODE_ENV === 'development') {
        console.log('handleRejectInvite ------->>> error =======>>>', error);
      }
      callback(error);
    } finally {
      await handleFetchUserDetails();
      setIsLoading(false);
    }
  };

  const handleGetTransactionExportCsv = async (
    id,
    config = {},
    callback = () => {},
    showIsLoading = false,
  ) => {
    try {
      if (showIsLoading) {
        setIsLoading(true);
      }
      const res = await getTransactionExportCsv(id, config);
      const { data } = res;
      if (typeof callback === 'function') {
        callback(null, data);
      }
    } catch (error) {
      if (process.env.NODE_ENV === 'development') {
        console.log(
          'handleGetTransactionExportCsv ------->>> error =======>>>',
          error,
        );
      }
      callback(error);
    } finally {
      await handleFetchUserDetails();
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (!isNil(user) && !isEmpty(user)) {
      handleFetchBusinessTypes();
      handleFetchUserDetails();
      handleFetchBanks();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  useEffect(() => {
    if (!isEmpty(accounts)) {
      handleFetchVirtualActionTransactions(first(accounts).id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accounts]);

  useEffect(() => {
    (async () => {
      if (!isEmpty(businessTeams)) {
        const firstTeam = first(businessTeams);
        if (!isNil(firstTeam?.id)) {
          await handleFetchBusinessTeam(firstTeam?.id);
        }
      }
    })();
  }, [businessTeams]);

  return useMemo(
    () => ({
      isLoading,
      balance,
      transactions,
      virtualAccounts,
      dynamicAccounts,
      handleIsLoading,
      handleCreateBusinessSectionVirtualAccount,
      invites,
      handleFetchTransactionDeposit,
      deposits,
      accounts,
      handleFetchVirtualActionTransactions,
      virtualAccountDeposits,
      withdrawals,
      business,
      handleUpdateBusinessDetails,
      handleWithdrawalRequest,
      businessTypes,
      inviteRoles,
      handleSendInvite,
      banks,
      handleValidateBankAccount,
      handleCreateBusinesskycDraft,
      handleValidateBvn,
      handleRegisterBusiness,
      businessTeams,
      businessTeamMembers,
      handleAcceptInvite,
      handleRejectInvite,
      handleGetTransactionExportCsv,
      referDetails,
      transactionAlert,
      businessTransactionAlert,
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      authToken,
      balance,
      transactions,
      virtualAccounts,
      dynamicAccounts,
      isLoading,
      user,
      invites,
      deposits,
      accounts,
      virtualAccountDeposits,
      withdrawals,
      business,
      businessTypes,
      inviteRoles,
      banks,
      businessTeams,
      businessTeamMembers,
      referDetails,
      transactionAlert,
      businessTransactionAlert,
    ],
  );
}

const UserContext = createContext({});

// eslint-disable-next-line react/prop-types
export function UserProvider({ children }) {
  const user = useProviderUser();
  return <UserContext.Provider value={user}>{children}</UserContext.Provider>;
}

export default function useUser() {
  return useContext(UserContext);
}
