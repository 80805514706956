/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable import/prefer-default-export */
import React, { useState } from 'react';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { useToggle } from 'usehooks-ts';
import { Button, Input } from '../atoms';
import { Close } from '../../icons';
import { Modal } from '../Modal';
import { useAuth } from '../../context';
import Success from '../../assets/images/success.png';

const schema = yup.object().shape({
  from: yup.string().required(),
  to: yup.string().required(),
  subject: yup.string().required(),
  message: yup.string().required(),
});

export function SendInvoice({ onClose, props }) {
  const [showSuccessModal, setShowSuccessModal] = useToggle();
  const { userDetails } = useAuth();
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm({ resolver: yupResolver(schema) });
  // const bizId = localStorage.getItem(storageName.bizId);
  const [isLoading, setIsLoading] = useState(false);

  const handlePaymentRequest = async (data) => {
    // try {
    //   setIsLoading(true);
    //   const res = await withdrawFund(userDetails?.business?.id, data);
    //   toast.success(res.message);
    //   setIsLoading(false);
    //   onClose();
    // } catch (error) {
    //   onClose();
    //   if (process.env.NODE_ENV === 'development') {
    //     console.log('login--> error', error);
    //   }
    // } finally {
    //   setIsLoading(false);
    // }
  };

  const onSubmit = (data) => handleSubmit(handlePaymentRequest);

  const handleAmountChange = (values) => {
    const { value } = values;
    setValue('amount', value);
  };
  const onSuccessClose = () => {};

  return (
    <Modal size="">
      <>
        {!showSuccessModal && (
          <div>
            <div className="sticky top-0 z-40 flex items-center justify-between px-3 py-6 bg-white border sm:px-5 border-brand-border">
              <p className="text-xl text-[#04004D] font-interMedium">
                Send Invoice
              </p>
              {/* <Close className="cursor-pointer" } /> */}
            </div>
            <div className="h-full px-4 py-8 sm:p-8 md:h-fit">
              <form onSubmit={onSubmit}>
                <div>
                  <Input
                    {...register('from')}
                    placeholder="johndoe@gmail.com"
                    required
                    label="From"
                  />
                  <p className="text-brand-red">{errors.amount?.message}</p>
                </div>
                <div>
                  <Input
                    {...register('to')}
                    placeholder="johndoe@gmail.com"
                    required
                    label="To"
                  />
                  <p className="text-brand-red">{errors.amount?.message}</p>
                </div>
                <div>
                  <Input
                    {...register('from')}
                    placeholder="Invoice"
                    required
                    label="Email Subject"
                  />
                  <p className="text-brand-red">{errors.amount?.message}</p>
                </div>
                <div>
                  <label
                    htmlFor="message"
                    className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                  >
                    Email Message
                  </label>
                  <textarea
                    {...register('message')}
                    className="border border-[#D0D5DD] text-[15px] text-brand-black leading-[22.5px] rounded-lg placeholder:text-[#B7BABF] placeholder:text-[15px] focus:border-[#3976E8] focus:ring-0 focus:outline-none block w-full"
                    placeholder="This is the message attached to the email..."
                    required
                    rows={4}
                  />
                  <p className="text-brand-red">{errors.amount?.message}</p>
                </div>
                <div className="flex space-x-4 mt-6">
                  <Button
                    loading={isLoading}
                    type="submit"
                    btnColor="default"
                    label="Send invoice"
                    size="block"
                    custom
                  />
                  <Button
                    loading={isLoading}
                    type="button"
                    btnColor="outline"
                    label="Cancel"
                    size="block"
                    custom
                    onClick={onClose}
                  />
                </div>
              </form>
            </div>
          </div>
        )}

        {showSuccessModal && (
          <div>
            <div
              className="flex justify-end pt-2 pr-3 cursor-pointer"
              onClick={onSuccessClose}
              role="button"
              onKeyDown={() => {}}
              tabIndex={-1}
            >
              <div>
                <Close />
              </div>
            </div>
            <div className="flex flex-col items-center px-10 py-14">
              <div>
                <img src={Success} className="w-12 h-12" alt="success" />
              </div>
              <div>
                <p className="text-lg text-center text-black font-interMedium">
                  Invoice sent!
                </p>
              </div>
              <div>
                <p className="text-center text-base font-interMedium text-[#878787]">
                  Please hold on while we review your documents
                </p>
              </div>
            </div>
          </div>
        )}
      </>
    </Modal>
  );
}

export default SendInvoice;
