/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable no-nested-ternary */
/* eslint-disable import/prefer-default-export */

import { Helmet } from 'react-helmet';
import React, { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { toNumber } from 'lodash';
import DashboardLayout from '../components/layout/dashboardLayout';
import {
  Profile,
  Team,
  Invites,
  Business,
  Alert,
} from '../components/settings/index';
import { Tab } from '../components/Tab';

export function Settings() {
  const [searchParams] = useSearchParams();
  const [tabNumber, setTabNumber] = useState(1);

  const [tabs] = useState([
    {
      name: 'Profile ',
    },
    {
      name: 'Business Profile ',
    },
    {
      name: 'Team',
    },
    {
      name: 'Invitation',
    },
    {
      name: 'Preferences',
    },
  ]);

  const switchTab = (val) => {
    setTabNumber(val);
  };

  useEffect(() => {
    const query = searchParams.get('tab');
    if (!query) return;
    setTabNumber(toNumber(query));
  }, [searchParams, tabNumber]);
  return (
    <DashboardLayout>
      <Helmet>
        <title>FundR | Settings</title>
      </Helmet>
      <div className="w-full px-4 lg:px-0 lg:pl-8 lg:pr-20  pt-4 pb-20 lg:pb-8">
        <div className="flex flex-col mt-9 max-md:max-w-full">
          <Tab
            changeTab={switchTab}
            numberOfTabs={tabs}
            currentTab={tabNumber}
            borderColor="border-blue-500"
          >
            {tabNumber === 1 && (
              <div
                id="general"
                role="tabpanel"
                aria-labelledby="general-tab"
                className="lg:mx-10"
              >
                <Profile />
              </div>
            )}

            {tabNumber === 2 && (
              <div
                id="general"
                role="tabpanel"
                aria-labelledby="general-tab"
                className="lg:mx-10"
              >
                <Business />
              </div>
            )}
            {tabNumber === 3 && (
              <div
                id="general"
                role="tabpanel"
                aria-labelledby="general-tab"
                className="lg:mx-10"
              >
                <Team />
              </div>
            )}

            {tabNumber === 4 && (
              <div
                id="general"
                role="tabpanel"
                aria-labelledby="general-tab"
                className="lg:mx-10"
              >
                <Invites />
              </div>
            )}

            {tabNumber === 5 && (
              <div
                id="general"
                role="tabpanel"
                aria-labelledby="general-tab"
                className="lg:mx-10"
              >
                <Alert />
              </div>
            )}
          </Tab>
        </div>
      </div>
    </DashboardLayout>
  );
}
