/* eslint-disable operator-linebreak */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable react/no-array-index-key */
/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable wrap-iife */
/* eslint-disable no-console */
/* eslint-disable no-alert */
/* eslint-disable import/prefer-default-export */
/* eslint-disable implicit-arrow-linebreak */
import React from 'react';
import { Flip, toast } from 'react-toastify';
import { Fade, Zoom } from 'react-reveal';
import { isEmpty, map } from 'lodash';
import { Card } from '../atoms/index';
import { ReactComponent as Copy } from '../../assets/icons/copy.svg';
import { useUser } from '../../context';
import { Chart } from '../BarChart';

export function DashboardView() {
  const { accounts: acccountDetails } = useUser();

  const copyToClipboard = (text) => {
    if (navigator.clipboard) {
      navigator.clipboard
        .writeText(text)
        .then(() => {
          toast.info('Details copied to clipboard!!', {
            transition: Flip,
          });
        })
        .catch((error) => {
          console.error('Copy to clipboard failed:', error);
        });
    } else {
      console.error('Clipboard API is not available');
    }
  };

  const handleButtonClick = (itemDescription, index) => {
    const textToCopy = document.getElementById(
      `item-desc-${index}`,
    ).textContent;
    copyToClipboard(textToCopy);
  };

  // Get Account Details
  // useEffect(() => {
  //   (async function fetchAccountDetails() {
  //     try {
  //       const id = userDetails?.business?.id;
  //       const res = await getAccount(id);
  //       setAccountDetails(res.data);
  //     } catch (error) {
  //       if (process.env.NODE_ENV === 'development') {
  //         console.log('login--> error', error);
  //       }
  //     }
  //   })();
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, []);

  return (
    <div className="flex flex-col lg:px-11 mt-12 max-md:px-5 max-md:mt-10 max-md:max-w-full">
      <Fade right>
        <div className="flex overflow-x-auto gap-x-4  snap-x">
          {!isEmpty(acccountDetails) &&
            map(acccountDetails, (item, index) => (
              <Card
                className="snap-center bg-white border border-[#E4E4E7] rounded-md p-4 lg:w-[20rem] sm:my-5 my-7"
                key={index}
              >
                <p className="text-xs text-[#8F8E8E] mb-1">ACCOUNT DETAILS</p>
                <div>
                  <p className="mb-1 text-xs text-black uppercase font-interMedium">
                    {item.account_details?.bank_name}
                  </p>

                  <div className="flex items-center justify-between mt-2">
                    <div>
                      <p
                        className="mr-4 text-xl text-black font-interBold"
                        id={`item-desc-${index}`}
                      >
                        {item.account_details?.account_number}
                      </p>
                      <p className="text-sm text-black uppercase font-inter mb-">
                        {item.account_details?.account_name}
                      </p>
                    </div>
                    <p
                      data-desc-ref={`item-desc-${index}`}
                      id={`btn-${index}`}
                      className="bg-[#9F56D433]/20 rounded-lg p-2 flex items-center cursor-pointer"
                      onClick={() => handleButtonClick(item.description, index)}
                    >
                      <Copy className="mr-2" />
                      <span className="text-[#9F56D4] text-xs">Copy</span>
                    </p>
                  </div>
                </div>
              </Card>
            ))}
        </div>
      </Fade>

      {/* max-md:px-5 might add later to below */}
      <div className="flex flex-col md:px-8 py-6 mt-10 rounded-xl border border-solid bg-neutral-50 border-[color:var(--gray-200,#E4E4E7)] max-md:max-w-full">
        <Zoom>
          <Chart />
        </Zoom>
      </div>
    </div>
  );
}
