/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable no-shadow */
/* eslint-disable no-console */
/* eslint-disable react/prop-types */
/* eslint-disable no-restricted-syntax */
/* eslint-disable no-nested-ternary */
/* eslint-disable import/prefer-default-export */
import React, { useCallback, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { toast } from 'react-toastify';
import { includes, isEmpty, last } from 'lodash';
import clsx from 'clsx';
import { Line } from 'rc-progress';

import { Upload } from '../../icons';
import { upload } from '../../context/Actions/user';

import PdfView from '../PdfViewer';
import PDFDefault from '../../assets/images/fundr-pdf-default.png';

export function FileUploadForm({
  uploadUrl,
  label,
  multiple = false,
  response,
  persisted,
  fileType = {
    'image/png': ['.png'],
    'image/jpeg': ['.jpeg', '.jpg'],
    'application/pdf': ['.pdf'],
  },
  error,
}) {
  const [isUploading, setIsUploading] = useState(false);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [selectedImage, setSelectedImage] = useState(null);
  const [errorMessage, setErrorMessage] = useState('');

  const onDrop = useCallback(
    async (acceptedFiles) => {
      try {
        if (isEmpty(acceptedFiles)) {
          return;
        }
        setIsUploading(true);
        const results = [];
        for (const file of acceptedFiles) {
          const formData = new FormData();
          formData.append('file', file);
          results.push(
            upload(formData, {
              onUploadProgress: (progressEvent) => {
                const progress = Math.round(
                  (progressEvent.loaded / file.size) * 100,
                );
                setUploadProgress(progress);
              },
            }),
          );
        }

        const uploads = await Promise.all(results);

        if (uploads?.length > 1) {
          const uploadsArray = [];

          for (const uploaded of uploads) {
            if (uploaded.data.status) {
              uploadsArray.push(uploaded.data.assetUrl);
            }
          }

          response(uploadsArray);
          setSelectedImage(uploadsArray);
          setErrorMessage('');
        } else {
          const uploadedFile = last(uploads);

          if (uploadedFile?.data?.status) {
            response(uploadedFile?.data?.assetUrl);
            setSelectedImage(uploadedFile?.data?.assetUrl);
            setErrorMessage('');
          }
        }
        // response(await Promise.all(results));
      } catch (error) {
        toast.error('Failed to upload file');
        if (process.env.NODE_ENV !== 'production') {
          console.error('Error uploading file:', error);
        }
      } finally {
        setIsUploading(false);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [uploadUrl],
  );

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: {
      'image/png': ['.png'],
      'image/jpeg': ['.jpeg', '.jpg'],
      'application/pdf': ['.pdf'],
      ...fileType,
    },
    maxSize: 10000000, // 10MB
    multiple,
  });

  return (
    <div className="mb-4 file-dropzone">
      <label
        htmlFor="image"
        className="block mb-1.5 text-base tracking-tight font-inter text-[#344054] leading-5"
      >
        {label}
      </label>
      {!isUploading && (
        <div
          {...getRootProps()}
          className={clsx(
            'dropzone border border-dashed rounded-lg flex justify-center p-4',
            selectedImage || persisted
              ? 'border-brand-green'
              : 'h-[44px] md:h-[87px]',
            errorMessage && !selectedImage ? 'border-brand-red' : '',
            error && !selectedImage ? 'border-brand-red' : '',
          )}
        >
          <input {...getInputProps()} />

          {!selectedImage && !errorMessage && !persisted ? (
            <p className="flex items-center text-sm text-center font-inter">
              <Upload className="hidden lg:block" />
              <span
                className={clsx([
                  'lg:ml-2.5',
                  error ? 'text-brand-red' : 'text-[#919191]',
                ])}
              >
                Drag & drop an image here, or click to upload
              </span>
            </p>
          ) : selectedImage || persisted ? (
            <div className="flex items-center flex-col justify-center">
              {includes(selectedImage || persisted, '.pdf') ? (
                <PdfView
                  className="w-[15rem] h-[15rem]"
                  file={selectedImage || persisted}
                  docs={{
                    onLoadError: setSelectedImage(PDFDefault),
                  }}
                />
              ) : (
                <img
                  className="w-[15rem] h-[15rem] object-cover"
                  src={selectedImage || persisted}
                  alt="Selected"
                />
              )}
              <div
                className={clsx(
                  'text-center cursor-pointer border border-[#3976E8] ',
                  'rounded-md hover:bg-[#3976E8] mt-6 p-2 text-[#3976E8] hover:text-white',
                )}
              >
                <p className={clsx('text-sm')}>Change Image</p>
              </div>
            </div>
          ) : errorMessage ? (
            <div>
              <p className="error text-brand-red">{errorMessage}</p>
              <div
                className={clsx(
                  'text-center cursor-pointer border border-[#3976E8] ',
                  'rounded-md hover:bg-[#3976E8] mt-6 p-2 text-[#3976E8] hover:text-white',
                )}
              >
                <p className={clsx('text-sm')}>Change Image</p>
              </div>
            </div>
          ) : (
            ''
          )}
        </div>
      )}

      {isUploading && (
        <div
          className={clsx(
            'w-full h-[44px] md:h-[87px] flex flex-col justify-center items-center',
            ' border-2 border-dashed rounded-lg border-gray-400',
          )}
        >
          <div>
            <p className={clsx('text-sm text-blue-500')}>
              {`${uploadProgress}%`}
            </p>
          </div>
          <div className={clsx('w-4/5')}>
            <Line
              percent={uploadProgress}
              strokeWidth={3}
              strokeColor="#3b82f6"
            />
          </div>
        </div>
      )}
    </div>
  );
}
