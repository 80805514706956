import React, { useEffect } from 'react';
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from 'recharts';
import { Zoom } from 'react-reveal';
import { useMediaQuery } from '../helpers/useMediaQuery';

export function MainChart({ data, ...props }) {
  const isMobileView = useMediaQuery('(max-width: 640px)');
  const isTabletView = useMediaQuery('(max-width: 840px)');

  return (
    <Zoom>
      <div style={{ width: '100%', height: 200 }}>
        <ResponsiveContainer>
          <BarChart
            width={500}
            height={200}
            data={data}
            margin={{
              top: 5,
              right: 30,
              left: 20,
              bottom: 5,
            }}
          >
            <CartesianGrid horizontal vertical={false} stroke="#E4E4E7" />
            <XAxis
              dataKey="date"
              tickLine={false}
              axisLine={false}
              tick={{ fontSize: 12 }}
            />
            <YAxis
              dataKey="amount"
              axisLine={false}
              tickLine={false}
              tick={{ fontSize: 12 }}
            />
            <Tooltip />
            <Bar
              dataKey="amount"
              fill="#FFC145"
              barSize={isMobileView && isTabletView ? 10 : 25}
            />
          </BarChart>
        </ResponsiveContainer>
      </div>
    </Zoom>
  );
}

// export default MainChart;
