/* eslint-disable react/forbid-prop-types */
/* eslint-disable react/require-default-props */
import React from 'react';
import PropTypes from 'prop-types';

export function Text({ textColor, design, ...props }) {
  return (
    <p
      className={['text-base text-black', design].join(' ')}
      style={{ color: textColor }}
    >
      {props.children}
    </p>
  );
}

Text.propTypes = {
  textColor: PropTypes.string,
  design: PropTypes.string,
  children: PropTypes.any,
};

export default Text;
