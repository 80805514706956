/* eslint-disable object-curly-newline */
/* eslint-disable react/prop-types */
import React, { forwardRef } from 'react';

export const Radio = forwardRef(
  ({ onChange, label, id, value, checked }, ref) => (
    <div className="flex items-center mb-4">
      <input
        id={id}
        type="radio"
        ref={ref}
        onChange={onChange}
        checked={checked}
        value={value}
        name="default-radio"
        className="w-4 h-4 bg-white border-[#D0D5DD] focus:ring-[#3976E8]"
      />
      <label
        htmlFor={id}
        className="ml-2.5 text-base font-inter text-[#344054]"
      >
        {label}
      </label>
    </div>
  ),
);

export default Radio;
