/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable operator-linebreak */
/* eslint-disable react/no-array-index-key */
/* eslint-disable indent */
/* eslint-disable no-nested-ternary */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable no-console */
/* eslint-disable import/prefer-default-export */
// eslint-disable react/no-unstable-nested-components
import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useLocation, useNavigate } from 'react-router-dom';
import moment from 'moment';
import { NumericFormat } from 'react-number-format';
import DatePicker from 'react-datepicker';
import { isNil, isEmpty } from 'lodash';
import DataTable from 'react-data-table-component';
import clsx from 'clsx';
import DashboardLayout from '../components/layout/dashboardLayout';
import { SubHeader } from '../components/SubHeader';
import { Cross, FilterFill, EmptyInvoice } from '../icons';
import { Button } from '../components/atoms';
import { Tab } from '../components/Tab';

export function Invoice() {
  const [tabNumber, setTabNumber] = useState(1);
  const [tabs] = useState([
    {
      name: 'Completed Invoices ',
    },
    {
      name: 'Pending',
    },
  ]);
  const navigate = useNavigate();
  const switchTab = (val) => {
    setTabNumber(val);
  };

  return (
    <DashboardLayout>
      <Helmet>
        <title>FundR | Invoices</title>
      </Helmet>

      <SubHeader>
        <div className="lg:flex items-center justify-between pb-3.5">
          <div className={clsx('')}>
            <p className="text-[#101928] text-xl font-interSemiBold lg:pt-0">
              All Invoices
            </p>
          </div>

          <div className="flex space-x-4">
            <Button
              label="Filter"
              iconLeft={<FilterFill />}
              icon
              textColor="#252C32"
              backgroundColor="#ffffff"
              borderLine="1px solid #DDE2E4"
              className={clsx('h-[52px] w-[120px]')}
            />
            <Button
              label="Create new invoice"
              iconLeft={<Cross />}
              icon
              textColor="#ffffff"
              backgroundColor="#3976E8"
              onClick={() => {
                navigate('/invoice/create');
              }}
              className={clsx('h-[52px] w-[224px]')}
            />
          </div>
        </div>
      </SubHeader>
      <div>
        {false && (
          <div className="flex flex-col items-center mt-52 hidden">
            <EmptyInvoice />
            <p className="text-base text-[#667085] font-interMedium">
              No invoice created
            </p>
            <p className="text-sm text-[#667085]">
              Any invoice created will show here.Click the button below to get
              started.
            </p>
          </div>
        )}
        <div className={clsx('pt-4')}>
          <Tab
            changeTab={switchTab}
            numberOfTabs={tabs}
            currentTab={tabNumber}
            borderColor="border-blue-500"
            counts={[]}
            titleClassName={clsx('font-normal')}
            activeTitleClassName={clsx('text-blue-500')}
          >
            {tabNumber === 1 && (
              <div className={clsx('py-4')}>
                <div>
                  {/* <p>Completed</p> */}
                  {true && (
                    <div className="flex flex-col items-center mt-52">
                      <div>
                        <EmptyInvoice />
                      </div>
                      <div>
                        <p className="text-base text-[#667085] font-interMedium">
                          No invoice created
                        </p>
                      </div>
                      <div>
                        <p className="text-sm text-[#667085]">
                          Any invoice created will show here.Click the button
                          below to get started.
                        </p>
                      </div>
                    </div>
                  )}
                </div>

                {/* <div className="flex flex-col items-center mt-52">
                  <div>
                    <EmptyInvoice />
                  </div>
                  <div>
                    <p className="text-base text-[#667085] font-interMedium">
                      No invoice created
                    </p>
                  </div>
                  <div>
                    <p className="text-sm text-[#667085]">
                      Any invoice created will show here.Click the button below
                      to get started.
                    </p>
                  </div>
                </div> */}
              </div>
            )}
            {tabNumber === 2 && (
              <div className={clsx('py-4')}>
                <div>
                  {/* <p>pending</p> */}
                  {true && (
                    <div className="flex flex-col items-center mt-52">
                      <div>
                        <EmptyInvoice />
                      </div>
                      <div>
                        <p className="text-base text-[#667085] font-interMedium">
                          No invoice created
                        </p>
                      </div>
                      <div>
                        <p className="text-sm text-[#667085]">
                          Any invoice created will show here.Click the button
                          below to get started.
                        </p>
                      </div>
                    </div>
                  )}
                </div>
                {/* <DataTable
                  columns={columns}
                  data={transactionDetails}
                  selectableRows
                  onRowClicked={openTransactionDetails}
                  pagination
                /> */}
                {/* <div className="flex flex-col items-center mt-52">
                  <div>
                    <EmptyInvoice />
                  </div>
                  <div>
                    <p className="text-base text-[#667085] font-interMedium">
                      No invoice created
                    </p>
                  </div>
                  <div>
                    <p className="text-sm text-[#667085]">
                      Any invoice created will show here.Click the button below
                      to get started.
                    </p>
                  </div>
                </div> */}
              </div>
            )}
          </Tab>
        </div>
      </div>

      {/* {invoiceModal ? <CreateInvoice onClose={closeInvoice} /> : ''} */}
    </DashboardLayout>
  );
}
