/* eslint-disable import/prefer-default-export */
/* eslint-disable react/no-array-index-key */
/* eslint-disable react/react-in-jsx-scope */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */

import { Transition } from '@headlessui/react';
import { useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { isArray, isEmpty, isNil } from 'lodash';
import clsx from 'clsx';

import { sidebarRoutes } from './data';
import { useMediaQuery } from '../../helpers/useMediaQuery';
import { useAuth } from '../../context';

import Logo from '../../assets/images/dashLogo.png';

function Tabs(props) {
  const { data, active, className, containerClassName, onClose } = props;
  const isMobileView = useMediaQuery('(max-width: 640px)');
  const isTabletView = useMediaQuery('(max-width: 840px)');
  const { userDetails } = useAuth();
  const roles = isArray(userDetails?.roles) ? userDetails?.roles : [];

  return (
    <>
      {Object.keys(data).map((route, index) => {
        const item = data[route];
        const allowed = item?.allowed;
        if (index === 0 && !isNil(userDetails?.business)) {
          return null; // Hide the first object if userDetails.business is null or undefined
        }
        if (isArray(allowed) && !isEmpty(allowed)) {
          if (!allowed.some((allowedRole) => roles.includes(allowedRole))) {
            return null; // Hide the route if the user is not allowed
          }
        } else if (typeof allowed === 'boolean' && !allowed) {
          return null; // Hide the route if allowed is false
        }
        return (
          <li
            className={`${containerClassName}`}
            key={index}
            onClick={isMobileView || isTabletView ? onClose : () => {}}
          >
            <Link className="hover:text-black" to={item.route}>
              <div
                className={clsx(
                  'flex w-full items-center py-2 px-5 capitalize ',
                  className,
                  active(item)
                    ? 'bg-brand-blue font-medium text-white'
                    : 'text-black',
                )}
              >
                {active(item) ? (
                  <item.activeIcon className="mr-4 h-5" />
                ) : (
                  <item.icon className="mr-4 h-5" />
                )}
                <p>{item.title}</p>
              </div>
            </Link>
          </li>
        );
      })}
    </>
  );
}

export function Sidebar(props) {
  const { open, onClose } = props;
  const { pathname } = useLocation();

  return (
    <Transition
      as="div"
      className="fixed z-30 h-full w-60 flex-none bg-white lg:static"
      enter="transition-all ease-in duration-300"
      enterFrom="transform -translate-x-full"
      enterTo="transform -translate-x-0"
      leave="transition-all ease-out duration-300"
      leaveFrom="transform -translate-x-0"
      leaveTo="transform -translate-x-full"
      show={open}
    >
      <div className="relative  h-full">
        <div className="mb-8 ml-3 mt-4 hidden lg:block">
          <Link to="/">
            <a href="/dashboard" className="flex object-cover ml-2 md:mr-24">
              <img src={Logo} className="w-28 mr-3 " alt="Logo" />
            </a>
          </Link>
        </div>

        <div className="flex h-[90vh] flex-col justify-between mt-24 pb-10">
          <ul>
            <Tabs
              active={(item) => pathname === item.route}
              className="hover:font-medium"
              data={sidebarRoutes}
              onClose={onClose}
            />
          </ul>
        </div>
      </div>
    </Transition>
  );
}
