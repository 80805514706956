/* eslint-disable object-curly-newline */
/* eslint-disable react/prop-types */
/* eslint-disable import/prefer-default-export */
import clsx from 'clsx';
import React from 'react';

export function SelectableCard({ title, details, id, onChange, value }) {
  return (
    <div className="items-center justify-center">
      <div className="grid grid-cols-1 lg:flex gap-2 w-full xl:w-[90%]">
        <div className="relative grow">
          <input
            className="hidden peer"
            value={value}
            id={id}
            type="radio"
            name="radio"
            onChange={onChange}
          />
          <span
            className={clsx(
              'absolute right-[16px] top-[40px] box-content block h-4 w-4 -translate-y-1/2 rounded-full border-[1px] flex justify-center items-center',
              {
                'bg-[#fbfbfe]': false,
                'bg-white': false,
                'border-[#dadada]': true,
                'peer-checked:border-[#3976E8] peer-hover:border-[#3976E8] peer-checked:bg-[#3976E8] peer-checked:after:content-["✓"] peer-checked:text-white text-xs': true,
              },
            )}
          />
          <label
            className="flex cursor-pointer flex-col justify-center rounded-lg border border-[#DADADA] py-4 pl-4 pr-4 peer-checked:border peer-checked:border-[#3976E8] peer-hover:border-[#3976E8] h-[80px]"
            htmlFor={id}
          >
            <span className="text-base font-interMedium capitalize text-[#11151F] capitalize-first">
              {title}
            </span>
            {true && (
              <span className="lg:mt-4 mt-2 text-base font-inter text-[#2B2B2B] capitalize-first">
                {details}
              </span>
            )}
          </label>
        </div>
      </div>
    </div>
  );
}
