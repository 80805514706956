/* eslint-disable operator-linebreak */
/* eslint-disable react/prop-types */
import React, { useState, useEffect } from 'react';
import Dojah from 'react-dojah';
import { useToggle } from 'usehooks-ts';
import { isEmpty, isNil } from 'lodash';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { useAuth } from '../context';
import storageName from '../helpers/storageHelpers';

export function DojahVerification({
  newWidgetId = null,
  useWidget = 'bvn',
  successData,
  isDisabled = false,
  className,
  ...props
}) {
  const { usetDetails } = useAuth();
  const userId =
    usetDetails?.id ?? JSON.parse(localStorage.getItem(storageName.userId));
  const [value, toggle] = useToggle(false);
  const [widgetId, setWidgetId] = useState({
    govtId: process.env.REACT_APP_DOJAH_GOVT_WIDGET_ID,
    bvn: process.env.REACT_APP_DOJAH_BVN_WIDGET_ID,
    custom: '',
  });
  const [dojahCreds] = useState({
    appID: process.env.REACT_APP_DOJAH_APP_ID,
    publicKey: process.env.REACT_APP_DOJAH_PUBLIC_KEY,
    type: 'custom',
  });
  const [dojahConfig, setDojahConfig] = useState({
    debug: true,
    widget_id: widgetId[useWidget],
    webhook: false,
    // pages: [],
  });
  const [metaData] = useState({ user_id: userId });

  const response = (type, data) => {
    if (type === 'success') {
      successData(data);
    } else if (type === 'error') {
      // add function
      setTimeout(() => {
        // setValue(false);
        window.location.reload();
      }, 1000);
    } else if (type === 'close') {
      // add function
      setTimeout(() => {
        // setValue(false);
        window.location.reload();
      }, 1000);
      // console.log(type, 'has been clicked');
    } else if (type === 'begin') {
      // add function
    } else if (type === 'loading') {
      // add function
    }
  };

  useEffect(() => {
    setDojahConfig((prevDojahConfig) => ({
      ...prevDojahConfig,
      widget_id: widgetId[useWidget],
    }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [widgetId]);

  useEffect(() => {
    if (isEmpty(newWidgetId) || isNil(newWidgetId)) return;

    setWidgetId((prevWidgetId) => ({
      ...prevWidgetId,
      [useWidget]: newWidgetId,
    }));
  }, [newWidgetId, useWidget]);

  return (
    <>
      <div
        onClick={() => {
          if (!isDisabled) {
            toggle();
          }
        }}
        onKeyUp={() => {}}
        role="button"
        tabIndex="0"
        className={clsx('h-[55px] flex justify-center items-center', {
          'cursor-not-allowed': isDisabled,
          className,
        })}
      >
        {props.children}
      </div>
      {value && (
        <Dojah
          response={response}
          appID={dojahCreds.appID}
          publicKey={dojahCreds.publicKey}
          type={dojahCreds.type}
          config={dojahConfig}
          metadata={metaData}
        />
      )}
    </>
  );
}

DojahVerification.propTypes = { children: PropTypes.node.isRequired };

export default DojahVerification;
