export const StorageName = {
  userDetails: 'fundr@user-details',
  userToken: 'fundr@user-token',
  userId: 'fundr@user-id',
  businessType: 'fundr@business-type',
  bizId: 'fundr@business-id',
  kycDetails: 'fundr@kyc-details',
  business_creation: 'fundr@business-creation',
  invoice_creation: 'fundr@invoice-creation',
};

export default StorageName;
