/* eslint-disable operator-linebreak */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable react/no-array-index-key */
/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable wrap-iife */
/* eslint-disable no-console */
/* eslint-disable no-alert */
/* eslint-disable import/prefer-default-export */
/* eslint-disable implicit-arrow-linebreak */
import React, { useState } from 'react';
import { Fade } from 'react-reveal';
import { toast } from 'react-toastify';
import { useAuth } from '../../context';
import { ReactComponent as Generate } from '../../assets/images/generate.svg';
import { generateVirtualAccount } from '../../context/Actions/user';
import { Loader } from '../Loader';

export function GenerateAccount() {
  const { userDetails: user } = useAuth();
  const [pageLoading, setPageLoading] = useState(false);

  const generateAccount = async () => {
    try {
      setPageLoading(true);
      const res = await generateVirtualAccount(user?.business?.id);
      if (res.data.status === 200) {
        toast.success(res?.data?.message);
        window.location.reload();
      }
    } catch (error) {
      return Promise.reject(error);
    } finally {
      setPageLoading(false);
    }
  };

  return (
    <>
      <Loader isActive={pageLoading} />
      <div className="flex flex-col lg:px-11 mt-12 max-md:px-5 max-md:mt-10 max-md:max-w-full">
        <Fade right>
          <div className="flex flex-col items-center my-40">
            <Generate />
            <div className=" text-center my-6">
              <p className="font-interMedium text-[#101828] text-base textcenter">
                Generate account number
              </p>
              <p className="text-[#475467] text-sm">
                Generate an account number to send and receive funds
              </p>
            </div>
            <button
              className="bg-[#3976E8] rounded-lg text-white font-interMedium px-[18px] py-2.5"
              onClick={generateAccount}
            >
              Generate
            </button>
          </div>
        </Fade>
      </div>
    </>
  );
}
