/* eslint-disable object-curly-newline */
/* eslint-disable react/jsx-wrap-multilines */
/* eslint-disable react/jsx-filename-extension */
import { BrowserRouter, Route, Routes, Navigate } from 'react-router-dom';
import React from 'react';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ToastContainer, Bounce } from 'react-toastify';
import { Dashboard } from './pages/Dashboard';
import { Home } from './pages/Home';
import { PageNotFound } from './pages/PageNotFound';
import {
  UnregisteredInfo,
  UnregisteredDoc,
  Review,
  BusinessInfo,
  BusinessDoc,
  CompanyDoc,
  CompanyInfo,
} from './components/onboard/index';
import { Accounts } from './pages/Accounts';
import { Transfers } from './pages/Transfers';
import { Transactions } from './pages/Transactions';
import { Refer } from './pages/Refer';
import { TransactionDetails } from './pages/TransactionDetails';
import { Invoice } from './pages/Invoice';
import { CreateInvoice, PreviewInvoice } from './components/invoice';
import { Settings } from './pages/Settings';
import { Register } from './pages/SignUp';
import { Login } from './pages/Login';
import { ForgetPassword } from './pages/ForgetPassword';
import { ForgetPasswordOtp } from './pages/ForgetPasswordOtp';
import { ResetPassword } from './pages/ResetPassword';
import { RequireAuth, RequireKYCAuth } from './helpers/route';
import AppContext from './context';
import { InstallPopup } from './components/installPopup';

import 'react-toastify/dist/ReactToastify.css';
import 'react-datepicker/dist/react-datepicker.css';
// import '@wojtekmaj/react-daterange-picker/dist/DateRangePicker.css';
// import 'react-calendar/dist/Calendar.css';
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';

const queryClient = new QueryClient();

function App() {
  return (
    <>
      {process.env.NODE_ENV === 'production' && <InstallPopup />}
      <AppContext>
        <BrowserRouter>
          <ToastContainer
            limit={1}
            position="top-right"
            autoClose={5000}
            newestOnTop
            hideProgressBar={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            theme="dark"
            transition={Bounce}
            // position="top-right"
            // autoClose={5000}
            // newestOnTop={false}
          />
          <QueryClientProvider client={queryClient}>
            <Routes>
              <Route path="/" element={<Navigate to="/login" />} />
              <Route exact path="/login" element={<Login />} />
              <Route exact path="/register" element={<Register />} />
              <Route
                exact
                path="/forget-password"
                element={<ForgetPassword />}
              />
              <Route
                exact
                path="/forget-password-otp"
                element={<ForgetPasswordOtp />}
              />
              <Route exact path="/reset-password" element={<ResetPassword />} />
              <Route path="/404" element={<PageNotFound />} />
              <Route path="*" element={<Navigate to="/404" />} />
              <Route
                exact
                path="/home"
                element={
                  <RequireAuth>
                    <Home />
                  </RequireAuth>
                }
              />
              <Route
                exact
                path="/dashboard"
                element={
                  <RequireAuth>
                    <RequireKYCAuth>
                      <Dashboard />
                    </RequireKYCAuth>
                  </RequireAuth>
                }
              />
              <Route
                exact
                path="/accounts"
                element={
                  <RequireAuth>
                    <RequireKYCAuth>
                      <Accounts />
                    </RequireKYCAuth>
                  </RequireAuth>
                }
              />
              <Route
                exact
                path="/wallet"
                element={
                  <RequireAuth>
                    <RequireKYCAuth>
                      <Transfers />
                    </RequireKYCAuth>
                  </RequireAuth>
                }
              />
              <Route
                exact
                path="/transactions"
                element={
                  <RequireAuth>
                    <RequireKYCAuth>
                      <Transactions />
                    </RequireKYCAuth>
                  </RequireAuth>
                }
              />
              <Route
                exact
                path="/transactions/:transactionId"
                element={
                  <RequireAuth>
                    <RequireKYCAuth>
                      <TransactionDetails />
                    </RequireKYCAuth>
                  </RequireAuth>
                }
              />
              <Route
                exact
                path="/invoices"
                element={
                  <RequireAuth>
                    <RequireKYCAuth>
                      <Invoice />
                    </RequireKYCAuth>
                  </RequireAuth>
                }
              />

              <Route
                exact
                path="/invoice/create"
                element={
                  <RequireAuth>
                    <RequireKYCAuth>
                      <CreateInvoice />
                    </RequireKYCAuth>
                  </RequireAuth>
                }
              />

              <Route
                exact
                path="/invoice/preview"
                element={
                  <RequireAuth>
                    <RequireKYCAuth>
                      <PreviewInvoice />
                    </RequireKYCAuth>
                  </RequireAuth>
                }
              />

              <Route
                exact
                path="/refer"
                element={
                  <RequireAuth>
                    <RequireKYCAuth>
                      <Refer />
                    </RequireKYCAuth>
                  </RequireAuth>
                }
              />

              <Route
                exact
                path="/settings"
                element={
                  <RequireAuth>
                    <RequireKYCAuth>
                      {' '}
                      <Settings />
                    </RequireKYCAuth>
                  </RequireAuth>
                }
              />
              <Route
                exact
                path="/onboard/unregistered/information"
                element={
                  <RequireAuth>
                    <UnregisteredInfo />
                  </RequireAuth>
                }
              />
              <Route
                exact
                path="/onboard/unregistered/documentation"
                element={
                  <RequireAuth>
                    <UnregisteredDoc />
                  </RequireAuth>
                }
              />
              <Route
                exact
                path="/onboard/unregistered/review"
                element={
                  <RequireAuth>
                    <Review />
                  </RequireAuth>
                }
              />
              <Route
                exact
                path="/onboard/business/information"
                element={
                  <RequireAuth>
                    <BusinessInfo />
                  </RequireAuth>
                }
              />
              <Route
                exact
                path="/onboard/business/documentation"
                element={
                  <RequireAuth>
                    <BusinessDoc />
                  </RequireAuth>
                }
              />
              <Route
                exact
                path="/onboard/business/review"
                element={
                  <RequireAuth>
                    <Review />
                  </RequireAuth>
                }
              />
              <Route
                exact
                path="/onboard/company/documentation"
                element={
                  <RequireAuth>
                    <CompanyDoc />
                  </RequireAuth>
                }
              />
              <Route
                exact
                path="/onboard/company/information"
                element={
                  <RequireAuth>
                    <CompanyInfo />
                  </RequireAuth>
                }
              />
              <Route
                exact
                path="/onboard/company/review"
                element={
                  <RequireAuth>
                    <Review />
                  </RequireAuth>
                }
              />
            </Routes>
          </QueryClientProvider>
        </BrowserRouter>
      </AppContext>
    </>
  );
}

export default App;
