/* eslint-disable object-curly-newline */
/* eslint-disable max-len */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable import/prefer-default-export */
import { yupResolver } from '@hookform/resolvers/yup';
import { Helmet } from 'react-helmet';
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import * as yup from 'yup';

import { verifyForgetPassword } from '../context/Actions/auth';
import { Show, Hide } from '../icons';
import { Button, Card, Text, Input } from '../components/atoms/index';
import { AuthLayout } from '../components/layout/authLayout';

const symbols = new RegExp(process.env.REACT_APP_PASSWORD_VALIDATION, 'g');

const schema = yup.object().shape({
  password: yup
    .string()
    .required('Please enter a password')
    .min(8, 'Password too short')
    .max(24, 'Password too long')
    .matches(symbols, process.env.REACT_APP_PASSWORD_VALIDATION_MESSAGE),
});

export function ResetPassword() {
  const [isLoading, setIsLoading] = useState(false);
  const [showPassword, setShowpassword] = useState(false);
  const navigate = useNavigate();

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({ resolver: yupResolver(schema), mode: 'onChange' });

  const handleReset = async (data) => {
    try {
      setIsLoading(true);
      let existing = localStorage.getItem('email');
      existing = existing ? JSON.parse(existing) : {};
      existing.new_password = data.password;
      localStorage.setItem('email', JSON.stringify(existing));

      const details = JSON.parse(localStorage.getItem('email'));
      const res = await verifyForgetPassword(details);
      toast.success(res.data.message);
      navigate('/');
    } catch (error) {
      reset();
      setIsLoading(false);
    }
  };

  return (
    <>
      <Helmet>
        <title>FundR | ResetPassword</title>
      </Helmet>
      <AuthLayout>
        <Card backgroundColor="white">
          <Text
            textColor="#04004D"
            design="flex justify-center md:text-xl text-lg font-interMedium md:font-interSemiBold md:mb-5 mb-2"
          >
            Reset password
          </Text>
          <Text
            textColor="#70747E"
            design="flex justify-center md:text-[15px] text-xs font-inter md:mb-6 mb-4 leading-5"
          >
            Password must contain at least 8 letters, characters and number
          </Text>
          <form onSubmit={handleSubmit(handleReset)}>
            <Input
              {...register('password')}
              label="Password"
              type={showPassword ? 'text' : 'password'}
              placeholder="Enter password"
              borderColor="border-[#E6EAEE]"
              required
              error={errors.password?.message}
              icon={
                !showPassword ? (
                  <Show
                    className="mr-2"
                    onClick={() => {
                      setShowpassword(true);
                    }}
                  />
                ) : (
                  <Hide
                    className="mr-2"
                    onClick={() => {
                      setShowpassword(false);
                    }}
                  />
                )
              }
            />

            <div className="mt-8">
              <Button
                loading={isLoading}
                isDisabled={isLoading}
                type="submit"
                label="Reset password"
                btnColor="default"
                size="block"
                custom
              />
            </div>
          </form>
          {/* <Text design="flex justify-center text-sm font-inter mt-5">
            <span
              className="text-[#3976E8] cursor-pointer ml-1"
              onClick={() =>
                handleOtpResend(JSON.parse(localStorage.getItem("email")))
              }
            >
              Resend Code
            </span>
          </Text> */}
        </Card>
      </AuthLayout>
      {/* <div className="w-full h-screen grid-cols-1 lg:grid lg:grid-cols-2">
        <div className="grid content-center bg-white">
          <div className=" rounded-[30px] lg:px-20 px-6 md:px-10 grow">
            <div className="mb-10 lg:mb-16">
              <p className="text-base text-left text-black font-interSemiBold lg:text-3xl">
                Reset Password
              </p>
              <small className="text-[#2F2F2F]">
                Password must contain at least 7 letters and 1 number
              </small>
            </div>
            <form onSubmit={handleSubmit(handleReset)}>
              <div className="lg:mb-20 mb-60">
                <label
                  htmlFor="first_name"
                  className="block mb-4 text-sm lg:text-base font-interMedium text-[#2F2F2F]"
                >
                  New Password
                </label>
                <input
                  {...register("password")}
                  type="password"
                  id="password"
                  className="border border-[#ECECEC] text-base rounded-lg focus:border-[#ECECE] focus:ring-0 focus:outline-none block w-full lg:p-4 py-3 px-4"
                  required
                />
                <p className="text-brand-red">{errors.password?.message}</p>
              </div>

              <div className="mb-8">
                <Button
                  loading={isLoading}
                  type="submit"
                  label="Reset Password"
                  btnColor="login"
                  size="block"
                  custom
                />
              </div>
            </form>
          </div>
        </div>
      </div> */}
    </>
  );
}
