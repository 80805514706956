/* eslint-disable react/jsx-curly-newline */
/* eslint-disable implicit-arrow-linebreak */
/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable max-len */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable no-shadow */
/* eslint-disable no-console */
/* eslint-disable import/prefer-default-export */
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import OtpInput from 'react-otp-input';
import { forgetPasswordOtp } from '../context/Actions/auth';
import { Card, Text } from '../components/atoms/index';
import { AuthLayout } from '../components/layout/authLayout';

export function ForgetPasswordOtp() {
  const [code, setCode] = useState('');
  const navigate = useNavigate();
  const [userDetails, setuserDetails] = useState('');

  useEffect(() => {
    setuserDetails(JSON.parse(localStorage.getItem('email')));
  }, []);

  const handleOtpResend = async (data) => {
    try {
      const res = await forgetPasswordOtp(data);
      toast.success(res.data.message);
    } catch (error) {
      if (process.env.NODE_ENV === 'development') {
        console.log('login--> error', error);
      }
    }
  };

  const handleChange = (code) => {
    setCode(code);
    if (code.length === 5) {
      let existing = localStorage.getItem('email');
      existing = existing ? JSON.parse(existing) : {};
      existing.otp = code;
      localStorage.setItem('email', JSON.stringify(existing));
      navigate('/reset-password');
    }
  };

  return (
    <>
      <AuthLayout>
        <Card backgroundColor="white">
          <Text
            textColor="#04004D"
            design="flex justify-center md:text-xl text-lg font-interMedium md:font-interSemiBold md:mb-5 mb-2"
          >
            Enter OTP
          </Text>
          <Text
            textColor="#70747E"
            design="flex justify-center md:text-[15px] text-xs font-inter md:mb-8 mb-4 leading-5"
          >
            Please input the five digit code that was sent to your provided
            email: {userDetails.email}
          </Text>
          <div className="flex justify-center">
            <OtpInput
              value={code}
              onChange={handleChange}
              numInputs={5}
              renderSeparator={<span style={{ width: '13px' }} />}
              renderInput={(props) => <input {...props} />}
              shouldAutoFocus
              inputStyle={{
                border: '1px solid #E6EAEE',
                borderRadius: '8px',
                width: '50px',
                height: '48px',
                fontSize: '16px',
                color: '#000',
                fontWeight: '400',
                caretColor: 'black',
                boxShadow: '0px 1px 2px 0px rgba(16, 24, 40, 0.05)',
              }}
              focusStyle={{
                border: '1px solid #CFD3DB',
                outline: 'none',
              }}
            />
          </div>
          <Text
            textColor="#04004D"
            design="flex justify-center text-sm font-inter md:mb-5 mb-2 mt-5"
          >
            Didn’t receive code?{' '}
            <span
              className="text-[#3976E8] cursor-pointer ml-1"
              onClick={() =>
                handleOtpResend(JSON.parse(localStorage.getItem('email')))
              }
            >
              Resend
            </span>
          </Text>
          {/* <div className="mt-5">
            <Button
              loading={isLoading}
              isDisabled={isLoading}
              type="submit"
              label="Confirm OTP"
              btnColor="default"
              size="block"
              custom
            />
          </div> */}
        </Card>
      </AuthLayout>
      {/* <div className="w-full h-screen grid-cols-1 lg:grid lg:grid-cols-2">
        <div className="hidden grid-cols-6 grid-rows-3 bg-brand-background items-streth lg:grid">
          <img
            src={Logo}
            alt="logo"
            className="col-start-2 row-start-1 mt-20 userDetailsect-cover row-end row-span-ful"
          />

          <img
            src={Auth}
            alt="logo"
            className="self-center col-start-2 col-end-7 row-end-3 userDetailsect-cover"
          />
        </div>
        <img
          src={smallLogo}
          alt="logo"
          className="pt-20 mx-auto mb-6 userDetailsect-cover lg:mb-12 lg:hidden"
        />
        <div className="grid content-center bg-white">
          <div className=" rounded-[30px] lg:px-20 px-6 md:px-10 grow">
            <div className="mb-10 lg:mb-16">
              <p className="text-base text-left text-black font-interSemiBold lg:text-3xl">
                Enter OTP
              </p>
              <small className="text-[#2F2F2F]">
                Please input the five digit code that was sent to your provided
                email
              </small>
            </div>
            <form onSubmit={handleSubmit(handleRegister)}>
              <div className="lg:mb-14 mb-60">
                <label
                  htmlFor="first_name"
                  className="block mb-4 text-sm lg:text-base font-interMedium text-[#2F2F2F]"
                >
                  OTP
                </label>
                <input
                  {...register("otp")}
                  type="text"
                  id="otp"
                  className="border border-[#ECECEC] text-base rounded-lg focus:border-[#ECECE] focus:ring-0 focus:outline-none block w-full lg:p-4 py-3 px-4"
                  required
                />
                <p className="text-brand-red">{errors.otp?.message}</p>

                <p className="text-[#2F2F2F] text-base mt-12">
                  Didn’t receive code?{" "}
                  <span
                    className="text-[#9F56D4] cursor-pointer"
                    onClick={() => handleOtp(JSON.parse(localStorage.getItem("email")))}
                  >
                    Resend
                  </span>
                </p>
              </div>

              <div className="mb-8">
                <Button
                  loading={isLoading}
                  type="submit"
                  label="Continue"
                  btnColor="login"
                  size="block"
                  custom
                />
              </div>
            </form>
          </div>
        </div>
      </div> */}
    </>
  );
}
