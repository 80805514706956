/* eslint-disable no-console */
/* eslint-disable wrap-iife */
/* eslint-disable max-len */
/* eslint-disable import/prefer-default-export */
import React, { useState } from 'react';
import { Helmet } from 'react-helmet';
import { NumericFormat } from 'react-number-format';
import { useToggle } from 'usehooks-ts';
import { Fade } from 'react-reveal';
import clsx from 'clsx';
import DashboardLayout from '../components/layout/dashboardLayout';
import { WithdrawalPage, WithdrawModal } from '../components/transfers';
import { Button } from '../components/atoms/index';
import { OutlineCross, Cross } from '../icons';
import { Tab } from '../components/index';
import { useUser } from '../context';

export function Transfers() {
  const { balance: walletBalance } = useUser();
  const [tabNumber, setTabNumber] = useState(1);
  const [withdrawDialog, toggleWithdrawDialog, setWithdrawDialog] = useToggle();

  const [tabs] = useState([
    {
      name: 'Withdrawals',
    },
  ]);

  const switchTab = (val) => {
    setTabNumber(val);
  };

  const handleCloseWithdrawDialog = () => toggleWithdrawDialog(false);

  return (
    <DashboardLayout>
      <Helmet>
        <title>FundR | Transfers</title>
      </Helmet>
      <div className="w-full px-4 lg:px-0 lg:pl-8 lg:pr-10  pt-4 pb-10 lg:pb-8">
        <div className="flex flex-col mt-9 max-md:max-w-full">
          <div className="flex gap-5 justify-between self-center w-full font-medium whitespace-nowrap  max-md:flex-wrap mb-3 lg:mb-0 border-b-2 pb-3">
            <i className="border-t-2 border-[#EDEDF2] mt-5 -mr-10 -ml-8" />
          </div>
          <div className={clsx('lg:px-5 mt-8 max-md:px-5 mb-10')}>
            <Fade left>
              <div className="flex gap-5 justify-between self-center w-full font-medium whitespace-nowrap  max-md:flex-wrap">
                <div>
                  <p className="text-[#252C32] font-interSemiBold text-lg">
                    Total Balance
                  </p>
                  <p className="text-[#424242] font-interBold text-4xl">
                    <NumericFormat
                      thousandSeparator
                      value={walletBalance}
                      decimalSeparator="."
                      displayType="text"
                      prefix="₦"
                    />
                  </p>
                </div>
                <div className="items-start">
                  <Button
                    label="Withdraw"
                    btnColor="outline"
                    icon
                    iconLeft={<OutlineCross />}
                    onMouseOver={<Cross />}
                    onClick={() => {
                      setWithdrawDialog(true);
                    }}
                  />
                </div>
              </div>
            </Fade>
          </div>
          <Tab
            changeTab={switchTab}
            numberOfTabs={tabs}
            currentTab={tabNumber}
            borderColor="border-black"
          />
          <div className="mt-8 ">
            {tabNumber === 1 && (
              <div
                id="general"
                role="tabpanel"
                aria-labelledby="general-tab"
                className=""
              >
                <WithdrawalPage reload={withdrawDialog} />
              </div>
            )}
          </div>
        </div>
      </div>

      {withdrawDialog ? (
        <WithdrawModal onClose={handleCloseWithdrawDialog} />
      ) : (
        ''
      )}
    </DashboardLayout>
  );
}
