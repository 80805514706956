/* eslint-disable react/forbid-prop-types */
/* eslint-disable react/require-default-props */
/* eslint-disable object-curly-newline */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable import/prefer-default-export */
import React from 'react';
import PropTypes from 'prop-types';

export function Card({ backgroundColor, textColor, borderColor, ...props }) {
  return (
    <div
      className="border rounded-[10px] md:px-8 md:py-10 md:p-4 px-3 py-6"
      style={{ backgroundColor, color: textColor, borderColor }}
      {...props}
    >
      {props.children}
    </div>
  );
}

Card.propTypes = {
  backgroundColor: PropTypes.string,
  textColor: PropTypes.string,
  borderColor: PropTypes.string,
  children: PropTypes.any,
};

Card.defaultProps = {
  backgroundColor: null,
  textColor: null,
  children: <p>This is a card</p>,
};
