/* eslint-disable max-len */
/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable import/prefer-default-export */
import moment from 'moment';
import { Helmet } from 'react-helmet';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { NumericFormat } from 'react-number-format';
import { ReactComponent as Bank } from '../assets/icons/bank.svg';
// import { Loader } from '../components/Loader';
import DashboardLayout from '../components/layout/dashboardLayout';
import { Back } from '../icons';

export function TransactionDetails() {
  const item = JSON.parse(localStorage.getItem('currentTransaction'));
  // const userDetails = JSON.parse(localStorage.getItem("userDetails"));
  const navigate = useNavigate();

  // if (!item) {
  //   return <Loader />;
  // }
  return (
    <DashboardLayout pageTitle="Reserved Accounts">
      <Helmet>
        <title>FundR | TransactionDetails</title>
      </Helmet>
      {/* <SubHeader>

      </SubHeader> */}
      <div
        className="mt-20 mb-8 md:-mx-8 cursor-pointer "
        onClick={() => navigate(-1)}
      >
        <Back />
      </div>
      <div className="flex items-center mb-8 md:-mx-8">
        <div className="flex items-center">
          <div className="p-3 bg-white rounded-full shadow-black drop-shadow-md">
            <Bank />
          </div>
          <div className="ml-4">
            <p>Amount Paid </p>
            <p className="text-2xl font-interBold text-brand-black">
              <NumericFormat
                value={item.amount}
                decimalSeparator="."
                thousandSeparator
                displayType="text"
                prefix="₦"
              />
            </p>
          </div>
        </div>
        <i className="py-5 mx-6 border-l border-brand-border" />
        <p
          className={[
            'border rounded-full capitalize  py-2 px-3 flex items-center border-brand-green text-brand-green-text bg-brand-badge-green',
          ].join(' ')}
        >
          <i className={['mr-1 w-2 h-2 rounded-full bg-[#92EF80]'].join(' ')} />
          {item.status}
        </p>
      </div>

      <div className="grid items-center p-9 -mx-8 bg-white border rounded-xl border-brand-border">
        <div className="">
          <p className="pb-4 border-b text-brand-black font-interBold border-brand-border">
            Transaction Breakdown
          </p>
          <p className="flex items-center justify-between pt-6 pb-4 border-b border-brand-border">
            <span>EXPECTED AMOUNT</span>
            <span className="font-interSemiBold text-brand-black">
              <NumericFormat
                value={item.amount}
                decimalSeparator="."
                thousandSeparator
                displayType="text"
                prefix="₦"
              />
            </span>
          </p>
          <p className="flex items-center justify-between pt-6 pb-4 border-b border-brand-border">
            <span>TRANSACTION Type</span>
            <span className="font-interSemiBold text-brand-black capitalize">
              {item.type}
            </span>
          </p>
          <p className="flex items-center justify-between pt-6 pb-4 border-b border-brand-border">
            <span>DATE</span>
            <span className="font-interSemiBold text-brand-black">
              {moment(item.created_at).format('MMM Do, YYYY')}
            </span>
          </p>
          <p className="flex items-center justify-between pt-6 pb-4 border-b border-brand-border">
            <span>TIME</span>
            <span className="uppercase font-interSemiBold text-brand-black">
              {moment(item.created_at).format('h:mma')}
            </span>
          </p>
          <p className="flex items-center justify-between pt-6 pb-4">
            <span>DESCRIPTION</span>
            <span className="font-interSemiBold text-brand-black w-1/2 text-right">
              {item.description}
            </span>
          </p>
        </div>
        {/* <div className="bg-[#F5F5F5] p-5 rounded-lg">
          <p className="pb-4 border-b text-brand-black font-interBold border-brand-border">
            Payment Information
          </p>
          <p className="flex items-center justify-between pt-6 pb-4 border-b border-brand-border">
            <span>PAYMENT METHOD</span>
            <span className="flex items-center font-interSemiBold text-brand-black">
              <i className="p-1 mr-2 bg-white rounded-full shadow-black drop-shadow-md">
                <Bank />
              </i>
              Bank Transfer
            </span>
          </p>
          <p className="flex items-center justify-between pt-6 pb-4 border-b border-brand-border">
            <span>SOURCE ACCOUNT INFO</span>
            <span className="font-interSemiBold text-brand-black">
              {item.meta.sourceAccountNumber}
            </span>
          </p>
          <p className="flex items-center justify-between pt-6 pb-4 border-b border-brand-border">
            <span>SOURCE ACCOUNT NAME</span>
            <span className="font-interSemiBold text-brand-black">
              {item.meta.sender}
            </span>
          </p>
          <p className="flex items-center justify-between pt-6 pb-4 border-b border-brand-border">
            <span>DESTINATION ACCOUNT INFO</span>
            <span className="font-interSemiBold text-brand-black">NIL</span>
          </p>
          <p className="flex items-center justify-between pt-6 pb-4 border-b border-brand-border">
            <span>SOURCE BANK NAME</span>
            <span className="capitalize font-interSemiBold text-brand-black">
              {item.meta.sourceBankName}
            </span>
          </p>
          <p className="flex items-center justify-between pt-6 pb-4">
            <span>CUSTOMER EMAIL</span>
            <span className="font-interSemiBold text-brand-black">{userDetails.email}</span>
          </p>
        </div> */}
      </div>
    </DashboardLayout>
  );
}
