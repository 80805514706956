/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable indent */
/* eslint-disable no-console */
/* eslint-disable wrap-iife */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable operator-linebreak */
/* eslint-disable object-curly-newline, camelcase */

import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useLocalStorage } from 'usehooks-ts';
import clsx from 'clsx';
import { IoArrowBack } from 'react-icons/io5';
import {
  every,
  includes,
  isEmpty,
  isNil,
  merge,
  omit,
  pick,
  split,
  toLower,
} from 'lodash';
import { toast } from 'react-toastify';

import OnboardLayout from '../layout/onboardingLayout';
import Success from '../../assets/images/success.png';
import { Modal } from '../Modal';
import { Close, Approve, Decline } from '../../icons';
import { Button } from '../atoms/index';
import storageName from '../../helpers/storageHelpers';
import { useAuth, useUser } from '../../context';

function Review() {
  const { kycDetails } = useAuth();
  const { handleRegisterBusiness, isLoading, handleIsLoading } = useUser();
  const [successModal, setSuccessModal] = useState(false);
  const [kycDetailsDraft, setKycDetailsDraft] = useState({});
  const [localOnboardDetails] = useLocalStorage(
    storageName.business_creation,
    null,
  );
  const navigate = useNavigate();
  const location = useLocation();

  const businessRegistrationType = useMemo(() => {
    let type = 'no_registration';
    const pathnameSplit = split(toLower(location?.pathname), '/');
    if (includes(pathnameSplit, 'business')) {
      type = 'name_registration';
    }
    if (includes(pathnameSplit, 'company')) {
      type = 'full_registration';
    }
    return type;
  }, [location]);

  const handleReviewSubmit = useCallback(async () => {
    try {
      handleIsLoading(true);

      let body = {
        business_name: kycDetailsDraft.business_name,
        business_type:
          kycDetailsDraft.business_type ?? businessRegistrationType,
        business_address: kycDetailsDraft?.business_address,
        government_issue_id: merge(
          {},
          kycDetailsDraft?.government_issue_id ?? {},
          toLower(businessRegistrationType) !== 'full_registration'
            ? {
                status: true,
                idUrl: kycDetailsDraft?.government_issue_id?.idUrl,
                verificationUrl: '',
                verificationMode: 'FUNDR',
                verificationType: 'NG-GOVT-ID',
                verificationValue: '',
              }
            : {},
        ),
        bvn_of_owner_or_directors: merge(
          {},
          kycDetailsDraft?.bvn_of_owner_or_directors ?? {},
          toLower(businessRegistrationType) === 'no_registration'
            ? {
                status: true,
                verificationMode: 'FUNDR',
                verificationType: 'BVN',
                verificationValue:
                  kycDetailsDraft?.bvn_of_owner_or_directors
                    ?.verificationValue ??
                  kycDetailsDraft?.business_account?.bvn,
              }
            : {},
        ),
        proof_of_address_url: kycDetailsDraft?.proof_of_address_url,
        business_account: merge({}, kycDetailsDraft?.business_account),
      };

      if (businessRegistrationType === 'full_registration') {
        body = merge(
          {},
          omit(body, ['government_issue_id', 'bvn_of_owner_or_directors']),
          {
            government_issue_id: {},
            bvn_of_owner_or_directors: {},
            business_rc_bn_number: kycDetailsDraft?.business_rc_bn_number,
            tax_id: kycDetailsDraft?.tax_id,
            business_number_of_directors:
              kycDetailsDraft?.business_number_of_directors,
            company_registration_documents: {
              mermat_asset_url:
                kycDetailsDraft?.company_registration_documents
                  ?.mermat_asset_url,
              cac_seven_or_cac_twothree_certificate_asset_url:
                kycDetailsDraft?.company_registration_documents
                  ?.cac_seven_or_cac_twothree_certificate_asset_url,
              cac_two_certificate_asset_url:
                kycDetailsDraft?.company_registration_documents
                  ?.cac_two_certificate_asset_url,
              cac_twoone_certificate_asset_url:
                kycDetailsDraft?.company_registration_documents
                  ?.cac_twoone_certificate_asset_url,
              license: kycDetailsDraft?.company_registration_documents?.license,
              resident_permit:
                kycDetailsDraft?.company_registration_documents
                  ?.resident_permit,
            },
          },
        );
      }

      if (businessRegistrationType === 'name_registration') {
        body = merge({}, omit(body, ['bvn_of_owner_or_directors']), {
          bvn_of_owner_or_directors: {},
          business_rc_bn_number: kycDetailsDraft?.business_rc_bn_number,
          cac_certificate_asset_url: kycDetailsDraft?.cac_certificate_asset_url,
          business_name_form_asset_url:
            kycDetailsDraft?.business_name_form_asset_url,
          tax_id: kycDetailsDraft?.tax_id,
        });
      }
      await handleRegisterBusiness(body, (error) => {
        if (error) {
          toast.error(
            error?.response?.data?.message ?? 'kyc submission failed',
          );
          return;
        }
        toast.success('kyc submission successful');
        setSuccessModal(true);
      });
    } catch (error) {
      toast.error(error?.response?.data?.message ?? 'kyc submission failed');
      if (process.env.NODE_ENV === 'development') {
        console.log(
          'handle register business submission ===========>>>>  error',
          error,
        );
      }
    } finally {
      handleIsLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [kycDetailsDraft]);

  const onSuccessClose = () => {
    setSuccessModal(false);
    localStorage.removeItem(storageName.business_creation);
    navigate('/home');
  };

  useEffect(() => {
    setKycDetailsDraft(
      (prevKyc) =>
        // eslint-disable-next-line implicit-arrow-linebreak
        merge(
          {},
          prevKyc,
          localOnboardDetails,
          pick(
            {},
            omit(kycDetails?.kyc, ['business_details']),
            kycDetails?.kyc?.business_details,
          ),
        ),
      // eslint-disable-next-line function-paren-newline
    );
  }, [kycDetails?.kyc, localOnboardDetails]);

  const completedInformationForm = useMemo(() => {
    let isCompleted = true;

    if (kycDetailsDraft?.business_type === 'no_registration') {
      const requiredField = [
        'business_name',
        'business_type',
        'business_address',
        'bank_account',
      ];
      isCompleted = every(kycDetailsDraft, (value, key) => {
        if (includes(requiredField, key)) {
          return !isNil(value) && !isEmpty(value);
        }
        return true;
      });
    }
    if (kycDetailsDraft?.business_type === 'name_registration') {
      const requiredField = [
        'business_name',
        'business_type',
        'business_address',
        'bank_account',
        'tax_id',
        'business_rc_bn_number',
        'bvn_of_owner_or_directors',
      ];
      isCompleted = every(kycDetailsDraft, (value, key) => {
        if (includes(requiredField, key)) {
          return !isNil(value) && !isEmpty(value);
        }
        return true;
      });
    }
    if (kycDetailsDraft?.business_type === 'full_registration') {
      const requiredField = [
        'business_name',
        'business_type',
        'business_address',
        'bank_account',
        'tax_id',
        'business_rc_bn_number',
        'bvn_of_owner_or_directors',
        'business_number_of_directors',
        'government_issue_id',
      ];
      isCompleted = every(kycDetailsDraft, (value, key) => {
        if (includes(requiredField, key)) {
          return !isNil(value) && !isEmpty(value);
        }
        return true;
      });
    }

    return isCompleted;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [kycDetails, kycDetailsDraft]);

  const completedDocumentForm = useMemo(() => {
    let isCompleted = true;
    if (kycDetailsDraft?.business_type === 'no_registration') {
      const requiredField = ['government_issue_id', 'proof_of_address_url'];
      isCompleted = every(
        pick(kycDetailsDraft, requiredField),
        (value, key) => {
          if (includes(requiredField, key)) {
            return !isNil(value) && !isEmpty(value);
          }
          return true;
        },
      );
    }
    if (kycDetailsDraft?.business_type === 'name_registration') {
      const requiredField = [
        'cac_certificate_asset_url',
        'business_name_form_asset_url',
        'government_issue_id',
        'proof_of_address_url',
      ];
      isCompleted = every(
        pick(kycDetailsDraft, requiredField),
        (value, key) => {
          if (includes(requiredField, key)) {
            return !isNil(value) && !isEmpty(value);
          }
          return true;
        },
      );
    }

    if (kycDetailsDraft?.business_type === 'full_registration') {
      const requiredField = [
        'cac_seven_or_cac_twothree_certificate_asset_url',
        'cac_two_certificate_asset_url',
        'cac_twoone_certificate_asset_url',
        'mermat_asset_url',
        'proof_of_address_url',
      ];
      isCompleted = every(
        pick(kycDetailsDraft?.company_registration_documents, [
          ...requiredField,
        ]),
        (value, key) => {
          if (includes(requiredField, key)) {
            return !isNil(value) && !isEmpty(value);
          }
          return true;
        },
      );
    }
    return isCompleted;
  }, [kycDetailsDraft]);

  return (
    <OnboardLayout>
      <div className="lg:px-16 pl-12 pr-8 xl:w-[65%] mx-auto">
        <div
          className="sm:mt-[5rem] mt-24 text-base text-[#292D32] font-interMedium flex items-center gap-x-2 lg:mb-5 mb-3 cursor-pointer"
          onClick={() => navigate(-1)}
          role="button"
          onKeyDown={() => {}}
          tabIndex={-1}
        >
          <IoArrowBack className={clsx('w-5 h-5 text-[#3976E8]')} />
          <span className="hidden md:block text-[#3976E8]">
            Back to Dashboard
          </span>
        </div>

        <div className="flex flex-col py-6  mx-2 xl:w[60rem]">
          <div className="mb-10">
            <p className="text-xl font-interSemiBold">
              Review your application
            </p>
            <small>
              Please check and ensure every infomation provided is correct
            </small>
          </div>
          <div className="lg:w-[30rem] gap-x-10 grid">
            <div className="">
              <div className="flex items-center">
                {completedInformationForm ? <Approve /> : <Decline />}
                <div className="bg-white ml-5 py-6 px-8 mb-5 grow shadow-md rounded">
                  <p className="text-brand-black font-medium">
                    Business Information
                  </p>
                </div>
              </div>
              <div className="flex items-center">
                {completedDocumentForm ? <Approve /> : <Decline />}

                <div className="bg-white ml-5 py-6 px-8 grow shadow-md rounded">
                  <p className="text-brand-black font-medium">
                    Business Documentation
                  </p>
                </div>
              </div>
            </div>
            <div className="mt-10">
              <Button
                loading={isLoading}
                isDisabled={
                  !(completedInformationForm && completedDocumentForm)
                }
                type="button"
                label="Submit"
                btnColor="default"
                size="block"
                onClick={handleReviewSubmit}
                className={clsx('h-[55px]')}
              />
            </div>
          </div>
        </div>
      </div>
      {successModal && (
        <Modal>
          <>
            <div
              className="flex justify-end pt-2 pr-3 cursor-pointer"
              onClick={onSuccessClose}
              role="button"
              onKeyDown={() => {}}
              tabIndex={-1}
            >
              <Close />
            </div>
            <div className="flex flex-col items-center px-10 py-14">
              <img src={Success} className="w-12 h-12" alt="success" />
              <p className="text-lg text-center text-black font-interMedium">
                Documents are being reviewed.
              </p>
              <p className="text-center text-base font-interMedium text-[#878787]">
                Please hold on while we review your documents
              </p>
            </div>
          </>
        </Modal>
      )}
    </OnboardLayout>
  );
}

export default Review;
