/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/prop-types */
/* eslint-disable react/forbid-prop-types */
/* eslint-disable react/require-default-props */
/* eslint-disable import/prefer-default-export */
import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { isEmpty, map } from 'lodash';

export const Select = forwardRef(
  (
    {
      onChange,
      onBlur,
      label,
      type,
      placeholder,
      design,
      required,
      disabled,
      borderColor,
      icon,
      iconLeft,
      name,
      error,
      options = [],
      text,
      value,
      labelClassName,
      className,
      ...props
    },
    ref,
  ) => (
    <div className="mb-4">
      <label
        htmlFor={type}
        className={clsx(
          'block mb-1.5 text-[15px] font-normal leading-[22.5px] tracking-tight font-inter text-[#344054]',
          labelClassName,
        )}
      >
        {label}
      </label>
      <div className="relative">
        <select
          name={name}
          ref={ref}
          onChange={onChange}
          onBlur={onBlur}
          disabled={disabled}
          value={value}
          {...props}
          className={clsx(
            'border border-[#E6EAEE] text-base rounded-lg focus:border-[#3976E8] focus:ring-0 focus:outline-none block w-full p-2.5 md:py-2.5 md:px-[18px] capitalize h-[44px] md:h-[55px]',
            error ? 'border-brand-red focus:border-brand-red' : borderColor,
            iconLeft ? 'pl-9 md:pl-9' : '',
            className,
          )}
        >
          {/* eslint-disable-next-line operator-linebreak */}
          {!isEmpty(options) &&
            map(
              options,
              ({
                key: optionKey,
                value: optionValue,
                disabled: optionDisabled,
                ...option
              }) => (
                <option
                  key={optionKey}
                  value={optionValue}
                  disabled={optionDisabled}
                  {...option}
                >
                  {optionKey}
                </option>
              ),
            )}
        </select>
      </div>
      {!isEmpty(error) && (
        <div>
          <p className="text-brand-red text-xs mb-2">{error}</p>
        </div>
      )}
    </div>
  ),
);

Select.propTypes = {
  label: PropTypes.string,
  type: PropTypes.any,
  placeholder: PropTypes.string,
  design: PropTypes.string,
  required: PropTypes.bool,
  disabled: PropTypes.bool,
  borderColor: PropTypes.any,
  icon: PropTypes.any,
  iconLeft: PropTypes.any,
  onChange: PropTypes.any,
  error: PropTypes.any,
  options: PropTypes.array,
};
