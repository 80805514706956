/* eslint-disable no-console */
/* eslint-disable react/jsx-props-no-spreading */
import React, { useState, useEffect } from 'react';
import * as yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { toast } from 'react-toastify';
import { defaultTo, forEach, includes } from 'lodash';
import { ReactComponent as Close } from '../assets/icons/close.svg';
import { ReactComponent as Success } from '../assets/icons/success.svg';
import { Button } from './atoms/Button';
import { Modal } from './Modal';
import { Loader } from './Loader';
import { Select } from './atoms/Select';
import { useAuth, useUser } from '../context';

const schema = yup.object().shape({
  email: yup.string().email().required('Email is required'),
  role: yup.string().required('Please select a role'),
});

// eslint-disable-next-line react/prop-types
function Invite({ isVisible, onClose }) {
  const { userDetails } = useAuth();
  const { inviteRoles, handleSendInvite } = useUser();
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({ resolver: yupResolver(schema), mode: 'onChange' });
  const [success, setSuccess] = useState(false);
  const [roles, setRoles] = useState();
  const [pageLoading, setPageLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const handleInviteMember = async (data) => {
    try {
      setIsLoading(true);
      setPageLoading(true);
      await handleSendInvite(data, (err, res) => {
        if (err) {
          return;
        }
        toast.success(res.message);
      });
    } catch (error) {
      if (process.env.NODE_ENV === 'development') {
        console.log('handle invite member ---->>> error =======>>>', error);
      }
    } finally {
      reset();
      setIsLoading(false);
      setPageLoading(false);
    }
  };

  useEffect(() => {
    // eslint-disable-next-line wrap-iife
    if (includes(userDetails?.roles, 'owner')) {
      (async () => {
        try {
          const results = [];

          forEach(inviteRoles, (value) => {
            results.push({
              key: defaultTo(value?.name, value.role.split('_').join(' ')),
              value: value.id,
            });
          });
          setRoles([{ key: 'Select Roles', value: '' }, ...results]);
          setPageLoading(false);
        } catch (error) {
          if (process.env.NODE_ENV === 'development') {
            console.log('handle set business members roles ---> error', error);
          }
        } finally {
          setPageLoading(false);
        }
      })();
    }
  }, [inviteRoles, userDetails]);

  const closeUp = () => {
    reset();
    onClose();
    setSuccess(false);
  };
  return (
    <>
      <Loader isActive={pageLoading} />
      {isVisible ? (
        <Modal size="lg">
          {success ? (
            <div className="p-10 flex items-center flex-col">
              <Success className="mb-10" />
              <p className="text-2xl text-brand-black font-interBold">
                Invite Sent
              </p>
              <p className="mb-10 text-center">
                You have sucessfully sent an invite to your team member
              </p>
              <Button
                btnColor="default"
                label="Okay"
                size="medium"
                custom
                onClick={() => {
                  closeUp();
                }}
              />
            </div>
          ) : (
            <>
              <div className="flex justify-between items-center top-0 sticky z-40 bg-white border text-base-b  px-10 py-6 border-brand-border">
                <p className="text-2xl text-brand-black font-interBold">
                  Invite A Member
                </p>
                <Close className="cursor-pointer" onClick={onClose} />
              </div>
              <div className="p-10">
                <form onSubmit={handleSubmit(handleInviteMember)}>
                  <div className="mb-7">
                    <input
                      type="email"
                      id="email"
                      className="bg-white border text-base border-brand-border placeholder:text-[#535379] placeholder:text-base rounded-lg focus:ring-0 focus:border-brand-border focus:outline-none block w-full p-4"
                      placeholder="Email Address"
                      required
                      {...register('email')}
                    />
                    <p className="text-brand-red">{errors.email?.message}</p>
                  </div>
                  <div className="mb-7">
                    <Select
                      {...register('role')}
                      placeholder="Select Role"
                      options={roles}
                      required
                      error={errors.role?.message}
                    />
                  </div>

                  <div className="md:flex md:space-x-4 md:space-y-0 space-y-3">
                    <Button
                      loading={isLoading}
                      type="submit"
                      btnColor="default"
                      label="Send Invite"
                      size="block"
                      custom
                    />

                    <Button
                      type="button"
                      btnColor="outline"
                      label="Cancel"
                      size="block"
                      custom
                      onClick={() => {
                        closeUp();
                      }}
                    />
                  </div>
                </form>
              </div>
            </>
          )}
        </Modal>
      ) : (
        ''
      )}
    </>
  );
}

export default Invite;
