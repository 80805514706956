/* eslint-disable react/button-has-type */
/* eslint-disable operator-linebreak */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable react/no-array-index-key */
/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable wrap-iife */
/* eslint-disable no-console */
/* eslint-disable no-alert */
/* eslint-disable import/prefer-default-export */
/* eslint-disable implicit-arrow-linebreak */
import React from 'react';
import { Helmet } from 'react-helmet';
import { isEmpty } from 'lodash';
import DashboardLayout from '../components/layout/dashboardLayout';
import { useUser } from '../context';
import { GenerateAccount } from '../components/Dashboard/Generate';
import { DashboardView } from '../components/Dashboard/DashboardView';
import { Loader } from '../components/Loader';

export function Dashboard() {
  const { virtualAccounts: acccountDetails, isLoading: pageLoading } =
    useUser();

  return (
    <DashboardLayout>
      <Helmet>
        <title>FundR | Dashboard</title>
      </Helmet>
      <Loader isActive={pageLoading} />
      <div className="w-full md:px-8 pb-20 lg:pb-8 flex flex-col">
        <div className="flex flex-col mt-8 max-md:mt-10 max-md:max-w-full" />
        <div className="flex flex-col ml-9 max-w-full text-base font-semibold leading-6 whitespace-nowrap text-zinc-800 w-[161px] max-md:ml-2.5">
          <div className="self-center">Online Payments</div>
          <div className="shrink-0 mt-5 h-0.5 bg-blue-500" />
        </div>
        <div className="z-10 shrink-0 h-0.5 bg-gray-100 max-md:max-w-full" />
        {isEmpty(acccountDetails) && pageLoading ? (
          <GenerateAccount />
        ) : (
          <DashboardView />
        )}
      </div>
    </DashboardLayout>
  );
}
