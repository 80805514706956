/* eslint-disable no-nested-ternary */
/* eslint-disable react/no-unescaped-entities */
/* eslint-disable operator-linebreak */
/* eslint-disable react/no-array-index-key */
/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable no-console */
/* eslint-disable wrap-iife */
/* eslint-disable import/prefer-default-export */
import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { useNavigate } from 'react-router-dom';
import { toLower } from 'lodash';
import { useLocalStorage } from 'usehooks-ts';
import DashboardLayout from '../components/layout/dashboardLayout';
import { Button, Card, SelectableCard } from '../components/atoms/index';
import { useAuth, useUser } from '../context';
import { Support, Checkbox, ArrowRight } from '../icons';
import storageName from '../helpers/storageHelpers';

function Registered() {
  const navigate = useNavigate();
  return (
    <div className="bg-[#0B182E] p-6 mt-6 rounded-lg w-full">
      <p className="text-white">Registered Business Name</p>

      <ul className="max-w-md space-y-6 text-white list-inside mt-4">
        <li className="flex items-center">
          <Checkbox className="mr-2" />
          Business Name Form
        </li>
        <li className="flex items-center">
          <Checkbox className="mr-2" />
          Certificate of Incorporation
        </li>
        <li className="flex items-center">
          <Checkbox className="mr-2" />
          Proof of Address
        </li>
        <li className="flex items-center">
          <Checkbox className="mr-2" />
          Government Issued ID
        </li>
      </ul>
      <div className="mt-8">
        <Button
          type="button"
          label="Procced"
          btnColor="white"
          size="block"
          icon
          iconRight={<ArrowRight />}
          custom
          onClick={() => {
            navigate('/onboard/business/information');
          }}
        />
      </div>
    </div>
  );
}
function Company() {
  const navigate = useNavigate();
  return (
    <div className="bg-[#0B182E] p-6 mt-6 rounded-lg w-full">
      <p className="text-white">Registered Company</p>

      <ul className="max-w-md space-y-6 text-white list-inside mt-4">
        <li className="flex items-center">
          <Checkbox className="mr-2" />
          Business Information
        </li>
        <li className="flex items-center">
          <Checkbox className="mr-2" />
          Certificate of Incorporation
        </li>
        <li className="flex items-center">
          <Checkbox className="mr-2" />
          Shareholder Documents
        </li>
        <li className="flex items-center">
          <Checkbox className="mr-2" />
          Director Documents
        </li>
      </ul>
      <div className="mt-8">
        <Button
          type="button"
          label="Procced"
          btnColor="white"
          size="block"
          icon
          iconRight={<ArrowRight />}
          custom
          onClick={() => {
            navigate('/onboard/company/information');
          }}
        />
      </div>
    </div>
  );
}
function Unregistered() {
  const navigate = useNavigate();
  return (
    <div className="bg-[#0B182E] p-6 mt-6 rounded-lg w-full">
      <p className="text-white">Unregistered Business</p>

      <ul className="max-w-md space-y-6 text-white list-inside mt-4">
        <li className="flex items-center">
          <Checkbox className="mr-2" />
          Business Information
        </li>
        <li className="flex items-center">
          <Checkbox className="mr-2" />
          Business Documentation
        </li>
      </ul>
      <div className="mt-8">
        <Button
          type="button"
          label="Procced"
          btnColor="white"
          size="block"
          icon
          iconRight={<ArrowRight />}
          custom
          onClick={() => {
            navigate('/onboard/unregistered/information');
          }}
        />
      </div>
    </div>
  );
}

export function Home() {
  const { userDetails, kycDetails, handleAuthenticateUser } = useAuth();
  const { businessTypes } = useUser();
  const navigate = useNavigate();
  const [_, setLocalKycsDetails] = useLocalStorage(
    storageName.business_creation,
    null,
  );
  const [docDetails, setDocDetails] = useState(0);

  const handleChange = (event) => {
    setLocalKycsDetails({ business_type: event.target.value });
    if (event.target.value === 'name_registration') {
      setDocDetails(1);
    }
    if (event.target.value === 'full_registration') {
      setDocDetails(2);
    }
    if (event.target.value === 'no_registration') {
      setDocDetails(3);
    }
  };

  useEffect(() => {
    (async function checkUserValidity() {
      try {
        if (userDetails?.business) {
          navigate('/dashboard');
        }
      } catch (error) {
        if (process.env.NODE_ENV === 'development') {
          console.log('checkUserValidity ====>>> error ---->>>', error);
        }
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userDetails, kycDetails]);

  useEffect(() => {
    handleAuthenticateUser(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <DashboardLayout>
      <Helmet>
        <title>FundR | Home</title>
      </Helmet>
      {/* <Loader isActive={pageLoading} /> */}
      <div className="">
        <div className="mb-4 mt-8 lg:mt-14 lg:mb-10">
          <p className="text-[26px] text-[#1A1C1F] font-interMedium">
            Hey {userDetails?.firstName}, tell us about your business
          </p>
          <p className="text-[#51555E] text-xl">
            What type of business do you own?
          </p>
        </div>
        {toLower(kycDetails?.kyc?.status) === 'pending' ? (
          <>
            <div className="mb-6">
              <Card backgroundColor="#5bc0de" borderColor="#E4E4E7">
                <div className="px8 py7">
                  <p className="mb-4 text-xl text-black font-interBold">
                    Activation In Progress
                  </p>
                  <p className="text-base text-black mb6">
                    Your details are currently being verified, you'll have
                    access to your account as soon as it is done.
                  </p>

                  {/* <Button label="Contact Support" /> */}
                </div>
              </Card>
            </div>
            <div className="flex xl:w-[30%]">
              <Card backgroundColor="white" borderColor="#E4E4E7">
                <Support />
                <p className="text-[#57574F] font-interSemiBold text-base mb-4 mt-5">
                  Support
                </p>
                <p className="text-[#585951] text-sm mb-6">
                  Get in touch with us and get answers to your questions about
                  Fundr
                </p>

                <Button label="Contact Support" />
              </Card>
            </div>
          </>
        ) : (
          <div className="">
            <div className="grid lg:grid-cols-2 lg:gap-10 gap-5 capitalize-first">
              {businessTypes?.map((item, index) => (
                <SelectableCard
                  key={index}
                  onChange={handleChange}
                  value={item.type}
                  id={item.id}
                  title={item.name}
                  details={item.description}
                />
              ))}
            </div>

            <div className="mt-10 w-full">
              {docDetails !== 0 ? (
                <p className="text-base text-black">
                  Based on your business type you selected, you will be required
                  to submit the following documents
                </p>
              ) : (
                ''
              )}

              <div className="w-[95%] md:w-96 flex flex-row justify-center mx-auto md:mx-0">
                {docDetails === 1 ? (
                  <Registered />
                ) : docDetails === 2 ? (
                  <Company />
                ) : docDetails === 3 ? (
                  <Unregistered />
                ) : (
                  ''
                )}
              </div>
            </div>
          </div>
        )}
      </div>
    </DashboardLayout>
  );
}
