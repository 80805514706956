/* eslint-disable react/no-array-index-key */
/* eslint-disable react/jsx-curly-newline */
/* eslint-disable implicit-arrow-linebreak */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable no-console */
/* eslint-disable no-inner-declarations */
/* eslint-disable import/prefer-default-export */
import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import { isEmpty, isNil } from 'lodash';

import clsx from 'clsx';
import { useUser } from '../../context';
import { Button } from '../atoms/Button';

export function Business() {
  const { business, handleUpdateBusinessDetails } = useUser();

  const [businessDetails, setBusinessDetails] = useState(null);

  // Category dropdown
  const [businessCategory] = useState([
    'Agriculture',
    'Aviation',
    'Commercial/ Retail',
    'Construction',
    'Education and Training',
    'Energy and Power Generation',
    'FMCG',
    'Fashion',
    'Financial Services',
    'Haulage/ Logistics',
    'Healthcare',
    'ICT',
    'Manufacturing',
    'Media & Entertainment',
    'Oil & Gas',
    'Professional Services',
    'Telecommunication',
    'Tourism/ Hospitality',
    'Transportation',
    'Waste Management',
  ]);
  // Get Business details

  useEffect(() => {
    if (!isNil(business) && !isEmpty(business)) {
      setBusinessDetails(business);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [business]);

  // Update business details

  const handleUpdateBusiness = async () => {
    try {
      await handleUpdateBusinessDetails(businessDetails, (err, result) => {
        if (err) {
          return;
        }
        toast.success(result?.message);
      });
    } catch (err) {
      console.log(err.message);
    }
  };

  return (
    <div
      className={clsx(
        'h-[85dvh] md:h-[80dvh] overflow-y-auto w-full scrollbar-hide',
      )}
    >
      {/* <div className="scrollbar-hid md:mt-[60px]"> */}
      <div className="md:mt-[60px] mt-10">
        <div className="mb-[35px]">
          <p className="font-interBold text-xl">Business details</p>
          <p className="text-base text-[#535379] ">
            Please give us more information about your business
          </p>
          <hr className="my-6 md:w-[65%]" />
          <div className="mb-4 flex flex-col gap-y-2 md:block">
            <label
              htmlFor="business_name"
              className="md:w-[310px] inline-flex text-[#000505] font-interBold text-base"
            >
              Business Name
            </label>
            <input
              disabled
              type="text"
              className="p-4 text-base bg-white border rounded-lg border-brand-border text-brand-black focus:ring-0 focus:outline-0 placeholder:text-base placeholder:text-brand-ghostwhite md:w-2/5"
              placeholder="Enter business name"
              required
              onChange={(e) =>
                setBusinessDetails({
                  ...businessDetails,
                  name: e.target.value,
                })
              }
              value={businessDetails?.name}
            />
          </div>
          <div className="mb-4">
            <label
              htmlFor="business_description"
              className="md:w-[310px] inline-flex text-[#000505] font-interBold text-base md:float-left md:mt-2"
            >
              Business Description
            </label>
            <textarea
              id="message"
              rows="4"
              className="p-4 text-base bg-white border rounded-lg text-brand-black border-brand-border
            focus:ring-0 focus:outline-0 placeholder:text-base placeholder:text-brand-ghostwhite md:w-2/5"
              placeholder="Enter business description"
              onChange={(e) =>
                setBusinessDetails({
                  ...businessDetails,
                  description: e.target.value,
                })
              }
              value={businessDetails?.description}
            />
          </div>
          <div className="mb-4">
            <label
              htmlFor="business_description"
              className="md:w-[310px] inline-flex text-[#000505] font-interBold text-base"
            >
              Business Category
            </label>
            <select
              id="category"
              className="p-4 text-base bg-white border rounded-lg text-brand-black border-brand-border
              focus:ring-0 focus:outline-0 placeholder:text-base placeholder:text-brand-ghostwhite md:w-2/5"
              onChange={(e) =>
                setBusinessDetails({
                  ...businessDetails,
                  category: {
                    ...businessDetails.category.category,
                    name: e.target.value,
                  },
                })
              }
              value={businessDetails?.category?.category?.name}
            >
              <option defaultValue>Select Category</option>
              {businessCategory.map((item, index) => (
                <option key={index} value={item}>
                  {item}
                </option>
              ))}
            </select>
          </div>
        </div>

        <div className="mb-[35px]">
          <p className="font-interBold text-xl">Business Contact Information</p>
          <p className="text-base text-[#535379] ">
            Please provide us with the contact information for your Business
          </p>
          <hr className="my-6 md:w-[65%]" />
          <div className="mb-4">
            <label
              htmlFor="contact_email"
              className="md:w-[310px] inline-flex text-[#000505] font-interBold text-base"
            >
              Contact Email
            </label>
            <input
              type="email"
              className="p-4 text-base bg-white border rounded-lg text-brand-black border-brand-border
            focus:ring-0 focus:outline-0 placeholder:text-base placeholder:text-brand-ghostwhite md:w-2/5"
              placeholder="Enter contact email"
              required
              onChange={(e) =>
                setBusinessDetails({
                  ...businessDetails,
                  contact_email: e.target.value,
                })
              }
              value={businessDetails?.contact_email}
            />
          </div>
          <div className="mb-4">
            <label
              htmlFor="support_email"
              className="md:w-[310px] inline-flex text-[#000505] font-interBold text-base"
            >
              Suppot Email
            </label>
            <input
              type="email"
              className="p-4 text-base bg-white border rounded-lg text-brand-black border-brand-border
            focus:ring-0 focus:outline-0 placeholder:text-base placeholder:text-brand-ghostwhite md:w-2/5"
              placeholder="Enter support email"
              required
              onChange={(e) =>
                setBusinessDetails({
                  ...businessDetails,
                  support_email: e.target.value,
                })
              }
              value={businessDetails?.support_email}
            />
          </div>
          <div className="mb-4">
            <label
              htmlFor="chargeback_email"
              className="md:w-[310px] inline-flex text-[#000505] font-interBold text-base"
            >
              Chargeback Email
            </label>
            <input
              type="email"
              className="p-4 text-base bg-white border rounded-lg text-brand-black border-brand-border
            focus:ring-0 focus:outline-0 placeholder:text-base placeholder:text-brand-ghostwhite md:w-2/5"
              placeholder="Enter chargeback email"
              required
              onChange={(e) =>
                setBusinessDetails({
                  ...businessDetails,
                  chargeback_email: e.target.value,
                })
              }
              value={businessDetails?.chargeback_email}
            />
          </div>
        </div>

        <div className="mb-[35px]">
          <p className="font-interBold text-xl">Business Contact Information</p>
          <p className="text-base text-[#535379] ">
            Please provide us with the contact information for your Business
          </p>
          <hr className="my-6 md:w-[65%]" />
          <div className="mb-2">
            <label
              htmlFor="country"
              className="md:w-[310px] inline-flex text-[#000505] font-interBold text-base"
            >
              Country
            </label>
            <input
              type="text"
              className="p-4 text-base bg-white border rounded-lg text-brand-black border-brand-border
            focus:ring-0 focus:outline-0 placeholder:text-base placeholder:text-brand-ghostwhite md:w-2/5"
              placeholder="Enter business country"
              required
              onChange={(e) =>
                setBusinessDetails({
                  ...businessDetails,
                  country: {
                    ...businessDetails.country,
                    name: e.target.value,
                  },
                })
              }
              value={businessDetails?.country.name}
            />
          </div>

          <div className="mb-2">
            <label
              htmlFor="state"
              className="md:w-[310px] inline-flex text-[#000505] font-interBold text-base"
            >
              State
            </label>
            <input
              type="text"
              className="p-4 text-base bg-white border rounded-lg text-brand-black border-brand-border
              focus:ring-0 focus:outline-0 placeholder:text-base placeholder:text-brand-ghostwhite md:w-2/5"
              placeholder="Enter business city"
              required
              onChange={(e) =>
                setBusinessDetails({
                  ...businessDetails,
                  location: {
                    ...businessDetails.location,
                    state: e.target.value,
                  },
                })
              }
              value={businessDetails?.location.state}
            />
          </div>

          <div className="mb-2">
            <label
              htmlFor="lga"
              className="md:w-[310px] inline-flex text-[#000505] font-interBold text-base"
            >
              LGA
            </label>
            <input
              type="text"
              className="p-4 text-base bg-white border rounded-lg text-brand-black border-brand-border
              focus:ring-0 focus:outline-0 placeholder:text-base placeholder:text-brand-ghostwhite md:w-2/5"
              placeholder="Enter business city"
              required
              onChange={(e) =>
                setBusinessDetails({
                  ...businessDetails,
                  location: {
                    ...businessDetails.location,
                    city: e.target.value,
                  },
                })
              }
              value={businessDetails?.location.city}
            />
          </div>

          <div className="mb-6">
            <label
              htmlFor="address"
              className="md:w-[310px] inline-flex text-[#000505] font-interBold text-base"
            >
              Address
            </label>

            <input
              type="text"
              className="p-4 text-base bg-white border rounded-lg text-brand-black border-brand-border
              focus:ring-0 focus:outline-0 placeholder:text-base placeholder:text-brand-ghostwhite md:w-2/5"
              placeholder="Enter business address"
              required
              onChange={(e) =>
                setBusinessDetails({
                  ...businessDetails,
                  location: {
                    ...businessDetails.location,
                    street: e.target.value,
                  },
                })
              }
              value={businessDetails?.location.street}
            />
          </div>
        </div>
      </div>

      <div className="flex items-center md:w-[65%]">
        <Button
          label="Save Changes"
          btnColor="default"
          size="block"
          custom
          onClick={() => {
            handleUpdateBusiness();
          }}
        />
      </div>
      {/* </div> */}
    </div>
  );
}
