/* eslint-disable object-curly-newline */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable no-nested-ternary */
/* eslint-disable operator-linebreak */
/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable import/prefer-default-export */
/* eslint-disable no-confusing-arrow */
/* eslint-disable  function-paren-newline */
/* eslint-disable implicit-arrow-linebreak */
import React, { useState, useEffect } from 'react';
import clsx from 'clsx';
import { isArray, isEmpty } from 'lodash';
import { useUser } from '../../context';
import {
  setBusinessTransactionAlert,
  fetchBusinessTransactionAlert,
} from '../../context/Actions/user';

export function Alert() {
  const { transactionAlert, businessTransactionAlert } = useUser();
  const [alerts, setAlerts] = useState([]);

  // const handleChange = (id) => async (event) => {
  //   const isChecked = event.target.checked;
  //   setAlerts((prevAlerts) => ({
  //     ...prevAlerts,
  //     transactionAlertId: id,
  //     isActive: isChecked,
  //   }));
  //   const data = {
  //     transactionAlertId: id,
  //     isActive: isChecked,
  //   };
  //   await setBusinessTransactionAlert(data);
  // };

  // useEffect(() => {
  //   if (businessTransactionAlert) {
  //     setAlerts({
  //       transactionAlertId: businessTransactionAlert.transaction_alert_id,
  //       isActive: businessTransactionAlert.is_active,
  //     });
  //   }
  // }, [businessTransactionAlert]);

  const handleChange = (id) => async (event) => {
    const isChecked = event.target.checked;
    setAlerts((prevAlerts) => {
      const updatedAlerts = prevAlerts.map((alert) =>
        alert.transactionAlertId === id
          ? { ...alert, isActive: isChecked }
          : alert,
      );

      // Check if alert already exists
      const existingAlert = updatedAlerts.find(
        (alert) => alert.transactionAlertId === id,
      );

      if (!existingAlert) {
        updatedAlerts.push({ transactionAlertId: id, isActive: isChecked });
      }

      return updatedAlerts;
    });

    const data = {
      transactionAlertId: id,
      isActive: isChecked,
    };

    await setBusinessTransactionAlert(data);
  };
  useEffect(() => {
    if (isArray(businessTransactionAlert)) {
      setAlerts(
        businessTransactionAlert.map((alert) => ({
          transactionAlertId: alert.transaction_alert_id,
          isActive: alert.is_active,
        })),
      );
    }
    fetchBusinessTransactionAlert();
  }, [businessTransactionAlert]);

  const isChecked = (id) => {
    const alert = alerts.find((alert) => alert.transactionAlertId === id);
    return alert ? alert.isActive : false;
  };

  return (
    <div className={clsx('h-[85dvh] md:h-[80dvh] overflow-y-auto w-full')}>
      <div
        className={clsx(
          'mt-[60px] md:mb-6 mb-10 lg:px-7 px-4 pt-4 pb-6 lg:py-6 bg-white rounded-[14px]',
          {
            'shadow-[0px_3px_8px_-1px_rgba(50,50,71,0.05)_0px_0px_1px_0px_rgba(12,26,75,0.24)]': false,
            'shadow-md': true,
          },
        )}
      >
        <p className="text-[#16192C] font-interBold text-xl">
          Transaction Alerts
        </p>
        <hr className="mt-10 mb-4" />
        <div className="space-y-6">
          {isArray(transactionAlert) &&
            !isEmpty(transactionAlert) &&
            transactionAlert.map((item, index) => (
              <div className="flex justify-between items-center" key={index}>
                <p className="capitalize">
                  {item.alert_type.split('_').join(' ')}
                </p>
                <label className="inline-flex items-center cursor-pointer">
                  <input
                    type="checkbox"
                    className="sr-only peer"
                    checked={isChecked(item.id)}
                    onChange={handleChange(item.id)}
                  />
                  <div className="relative w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600" />
                </label>
              </div>
            ))}
        </div>
      </div>
    </div>
  );
}
