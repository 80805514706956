/* eslint-disable indent */
/* eslint-disable max-len */
/* eslint-disable react/button-has-type */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable object-curly-newline */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable no-nested-ternary */
/* eslint-disable operator-linebreak */
/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable import/prefer-default-export */
import React, { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import * as yup from 'yup';
import clsx from 'clsx';
import { yupResolver } from '@hookform/resolvers/yup';
import { isEmpty, isNil } from 'lodash';
import { resetPassword } from '../../context/Actions/auth';
import { Close, Show, Hide } from '../../icons';
import { ReactComponent as Success } from '../../assets/icons/success.svg';
import { Input, Button } from '../atoms';
import { Modal } from '../Modal';
// import { Business } from './Business';
import { useAuth } from '../../context';

const schema = yup.object().shape({
  old_password: yup.string().required("Old Password can't be empty"),
  new_password: yup
    .string()
    .required("New Password can't be empty")
    .min(8, 'Password too short')
    .test(
      'isValidPass',
      ' Password must have: Uppercase, Lowercase, Number and Symbol',
      (value) => {
        const hasUpperCase = /[A-Z]/.test(value);
        const hasLowerCase = /[a-z]/.test(value);
        const hasNumber = /[0-9]/.test(value);
        const hasSymbole = /[!@#%&?*$]/.test(value);
        let validConditions = 0;
        const numberOfMustBeValidConditions = 4;
        const conditions = [hasLowerCase, hasUpperCase, hasNumber, hasSymbole];
        conditions.forEach((condition) => {
          if (condition) {
            validConditions += 1;
            return validConditions;
          }
          return null;
        });
        if (validConditions >= numberOfMustBeValidConditions) {
          return true;
        }
        return false;
      },
    ),
  confirm_password: yup
    .string()
    .oneOf([yup.ref('new_password'), null], 'Passwords must match'),
});

export function Profile() {
  const { userDetails, signOut } = useAuth();
  const [PasswordModal, setPasswordModal] = useState(false);
  const [showPassword, setShowpassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [success, setSuccess] = useState(false);
  const [isDisabled, setIsDisabled] = useState(true);
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    getValues,
    formState: { errors },
    reset,
  } = useForm({ resolver: yupResolver(schema), mode: 'onChange' });

  const handlePasswordUpdate = async (data) => {
    try {
      const res = await resetPassword(data);
      if (res.data.response === true) setSuccess(true);
    } catch (err) {
      reset();
    }
  };

  const handleLogout = () => {
    signOut();
    navigate('/');
  };

  useEffect(() => {
    if (
      !isNil(getValues('old_password')) ||
      !isNil(getValues('new_password')) ||
      !isNil(getValues('confirm_password'))
    ) {
      setIsDisabled(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDisabled]);

  return (
    <div className={clsx('h-[85dvh] md:h-[80dvh] overflow-y-auto w-full')}>
      <div className="space-y-4 md:mt-[60px]">
        <div>
          <label
            className="md:w-[310px] inline-flex text-[#000505] font-interBold text-base"
            htmlFor="name"
          >
            Full Name
          </label>
          <input
            id="name"
            className="border border-[#DADAE7] rounded-lg text-black p-3 md:w-2/5"
            name="username"
            type="text"
            disabled
            value={[userDetails?.firstName, userDetails?.lastName].join(' ')}
          />
        </div>
        <div>
          <label
            className="md:w-[310px] inline-flex text-[#000505] font-interBold text-base"
            htmlFor="name"
          >
            Email Address
          </label>
          <input
            id="name"
            className="border border-[#DADAE7] rounded-lg text-black p-3 md:w-2/5"
            name="username"
            type="text"
            disabled
            value={userDetails?.email}
          />
        </div>
        <div>
          <label
            className="md:w-[310px] inline-flex text-[#000505] font-interBold text-base"
            htmlFor="name"
          >
            Phone Number
          </label>
          <input
            id="name"
            className="border border-[#DADAE7] rounded-lg text-black p-3 md:w-2/5"
            name="username"
            type="text"
            disabled
            value={
              !isEmpty(userDetails?.country?.countryCode)
                ? [
                    `+(${userDetails?.country?.countryCode})`,
                    userDetails?.mobile,
                  ].join(' ')
                : 'NIL'
            }
          />
        </div>
        <div>
          <label
            className="md:w-[310px] inline-flex text-[#000505] font-interBold text-base"
            htmlFor="name"
          >
            Password
          </label>
          <button
            id="password"
            name="password"
            className="border border-[#F5F5F5] px-4 py-2.5 bg-[#F5F5F5] rounded-lg text-base text-[#000505] cursor-pointer"
            onClick={() => {
              setPasswordModal(true);
            }}
          >
            Change Password
          </button>
        </div>
      </div>

      {/* <div
        className={clsx(
          'lg:mb-4 mb-6 lg:px-7 px-4 pt-4 pb-6 lg:py-6 bg-white rounded-[14px]',
          {
            'shadow-[0px_3px_8px_-1px_rgba(50,50,71,0.05)_0px_0px_1px_0px_rgba(12,26,75,0.24)]': false,
            'shadow-md': true,
          },
        )}
      >
        <div>
          <p className="text-[#16192C] font-interBold text-xl mb-[30px]">
            Password
          </p>
        </div>

        <form onSubmit={handleSubmit(handlePasswordUpdate)}>
          <div className="grid lg:grid-cols-3 gap-x-4">
            <Input
              {...register('old_password')}
              label="Current Password"
              labelClassName={clsx('text-sm')}
              type="password"
              borderColor="border-[#E6EAEE]"
              required
              error={errors.old_password?.message}
            />

            <Input
              {...register('new_password')}
              label="New Password"
              labelClassName={clsx('text-sm')}
              type={showPassword ? 'text' : 'password'}
              borderColor="border-[#E6EAEE]"
              required
              error={errors.new_password?.message}
              icon={
                !showPassword ? (
                  <Show
                    className="mr-2"
                    onClick={() => {
                      setShowpassword(true);
                    }}
                  />
                ) : (
                  <Hide
                    className="mr-2"
                    onClick={() => {
                      setShowpassword(false);
                    }}
                  />
                )
              }
            />

            <Input
              {...register('confirm_password')}
              label="Confirm Password"
              labelClassName={clsx('text-sm')}
              type={showConfirmPassword ? 'text' : 'password'}
              borderColor="border-[#E6EAEE]"
              required
              error={errors.confirm_password?.message}
              icon={
                !showConfirmPassword ? (
                  <Show
                    className="mr-2"
                    onClick={() => {
                      setShowConfirmPassword(true);
                    }}
                  />
                ) : (
                  <Hide
                    className="mr-2"
                    onClick={() => {
                      setShowConfirmPassword(false);
                    }}
                  />
                )
              }
            />
          </div>
          <div className="mt-4">
            <Button
              isDisabled={isDisabled}
              type="submit"
              btnColor="outline"
              label="Change Password"
              className={clsx('w-[145px] h-[38px] text-sm')}
            />
          </div>
        </form>
      </div> */}

      {/* {!isNil(userDetails?.business) && (
        <div
          className={clsx(
            'mt-[60px] md:mb-6 mb-10 lg:px-7 px-4 pb-6 lg:py-6 rounded-[14px] bg-white',
            {
              'shadow-[0px_3px_8px_-1px_rgba(50,50,71,0.05)_0px_0px_1px_0px_rgba(12,26,75,0.24)]': false,
              'shadow-md': true,
            },
          )}
        >
          <div
            className={clsx(
              'flex justify-between items-center w-full lg:pb-[30px] py-6 mb-4',
            )}
          >
            <div>
              <p className="text-[#16192C] font-interBold text-xl">
                Business Profile
              </p>
            </div>

            <div className="">
              <Button
                isDisabled={isDisabled}
                type="submit"
                btnColor="outline"
                label="Edit"
                iconLeftComponent={<Edit className={clsx('text-white mr-2')} />}
                labelClassName={clsx('text-[#252C32]')}
                className={clsx(
                  'w-[72px] h-[40px] text-sm border-gray-300 text-[#252C32] flex items-center hover:bg-gray-100',
                )}
              />
            </div>
          </div>
          <div className="grid lg:grid-cols-3 gap-3.5">
            <div className="flex flex-col mb-6">
              <small className="text-[#64748B] font-interMedium text-sm mb-2">
                Business Name
              </small>{' '}
              <p className="text-base text-black font-interMedium capitalize">
                {userDetails?.business?.name}
              </p>
            </div>
            <div className="flex flex-col mb-6">
              <small className="text-[#64748B] font-interMedium text-sm mb-2">
                Contact email
              </small>{' '}
              <p className="text-base text-black font-interMedium">
                {userDetails?.business?.contact?.email ?? 'n/a'}
              </p>
            </div>
            <div className="flex flex-col mb-6">
              <small className="text-[#64748B] font-interMedium text-sm mb-2">
                Support email
              </small>{' '}
              <p className="text-base text-black font-interMedium">
                {userDetails?.business?.contact?.support_email ?? 'n/a'}
              </p>
            </div>

            <div className="flex flex-col mb-6">
              <small className="text-[#64748B] font-interMedium text-sm mb-2">
                Address
              </small>{' '}
              <p className="text-base text-black font-interMedium">
                {userDetails?.business?.location?.street ?? 'n/a'}
              </p>
            </div>
          </div>
        </div>
      )} */}

      {success ? (
        <Modal>
          <div className="flex flex-col items-center p-10">
            <Success className="mb-10" />
            <p className="text-2xl text-brand-black font-interBold">
              Password Change Succesful
            </p>
            <p className="mb-10">You have sucessfully changed your password</p>
            <Button
              btnColor="default"
              label="Okay"
              size="medium"
              custom
              onClick={() => {
                setSuccess(false);
                handleLogout();
              }}
            />
          </div>
        </Modal>
      ) : PasswordModal ? (
        <Modal size="lg">
          <div className="flex justify-between items-center top-0 sticky z-40 bg-white border text-base-b  px-10 py-6 border-brand-border">
            <p className="text-2xl text-brand-black font-interBold">
              Change Password
            </p>
            <Close
              className="cursor-pointer"
              onClick={() => {
                setPasswordModal(false);
              }}
            />
          </div>
          <div className="p-10">
            <form onSubmit={handleSubmit(handlePasswordUpdate)}>
              <div className="grid lg:grid-cols3 gap-x-4">
                <Input
                  {...register('old_password')}
                  placeholder="Enter current password"
                  labelClassName={clsx('text-sm')}
                  type="password"
                  borderColor="border-[#E6EAEE]"
                  required
                  error={errors.old_password?.message}
                />

                <Input
                  {...register('new_password')}
                  labelClassName={clsx('text-sm')}
                  type={showPassword ? 'text' : 'password'}
                  borderColor="border-[#E6EAEE]"
                  required
                  error={errors.new_password?.message}
                  placeholder="Enter new password"
                  icon={
                    !showPassword ? (
                      <Show
                        className="mr-2"
                        onClick={() => {
                          setShowpassword(true);
                        }}
                      />
                    ) : (
                      <Hide
                        className="mr-2"
                        onClick={() => {
                          setShowpassword(false);
                        }}
                      />
                    )
                  }
                />

                <Input
                  {...register('confirm_password')}
                  labelClassName={clsx('text-sm')}
                  type={showConfirmPassword ? 'text' : 'password'}
                  borderColor="border-[#E6EAEE]"
                  required
                  error={errors.confirm_password?.message}
                  placeholder="Confirm new password"
                  icon={
                    !showConfirmPassword ? (
                      <Show
                        className="mr-2"
                        onClick={() => {
                          setShowConfirmPassword(true);
                        }}
                      />
                    ) : (
                      <Hide
                        className="mr-2"
                        onClick={() => {
                          setShowConfirmPassword(false);
                        }}
                      />
                    )
                  }
                />
              </div>
              <div className="mt-4">
                <Button
                  type="submit"
                  btnColor="default"
                  label="Change Password"
                  size="block"
                  className={clsx('w-[145px] h-[38px] text-sm')}
                />
              </div>
            </form>
          </div>
        </Modal>
      ) : (
        ''
      )}
    </div>
  );
}

// : businessModal ? (
//   <Modal>
//     <div className="flex items-center justify-between p-3">
//       <p className="text-[#04004D] font-interSemiBold text-xl">
//         Edit Profile
//       </p>
//       <div
//         className="cursor-pointer "
//         onClick={() => {
//           setBusinessModal(false);
//         }}
//       >
//         <Close />
//       </div>
//     </div>
//     <div className="p-6">
//       <Business />
//     </div>
//   </Modal>
// )
