/* eslint-disable no-console */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState } from 'react';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import { NumericFormat } from 'react-number-format';
import clsx from 'clsx';
import { Button } from '../atoms/index';
import { Close, Naira } from '../../icons';
import { Modal } from '../Modal';
import { useUser } from '../../context';

const schema = yup.object().shape({
  amount: yup.number().required('Please enter amount'),
});

// eslint-disable-next-line react/prop-types
function WithdrawModal({ onClose }) {
  const { handleWithdrawalRequest } = useUser();
  const {
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm({ resolver: yupResolver(schema) });
  const [isLoading, setIsLoading] = useState(false);

  const handlePaymentRequest = async (data) => {
    try {
      setIsLoading(true);
      await handleWithdrawalRequest(data, (err, res) => {
        if (err) {
          return;
        }
        toast.success(res.message);
      });
    } catch (error) {
      if (process.env.NODE_ENV === 'development') {
        console.log('handlePaymentRequest --> error ===>', error);
      }
    } finally {
      onClose();
      setIsLoading(false);
    }
  };

  const handleAmountChange = (values) => {
    const { value } = values;
    setValue('amount', value);
  };

  return (
    <Modal size="lg">
      <>
        <div className="sticky top-0 z-40 flex items-center justify-between px-3 py-6 bg-white border sm:px-5 border-brand-border">
          <p className="text-xl text-[#04004D] font-interMedium">
            Withdraw to Bank
          </p>
          <Close className="cursor-pointer" onClick={onClose} />
        </div>
        <div className="h-full px-3 py-8 sm:p-8 md:h-fit">
          <form onSubmit={handleSubmit(handlePaymentRequest)}>
            <div className="mb-14">
              <label
                htmlFor="businessName"
                className="block mb-1.5 text-base tracking-tight font-inter text-[#344054] leading-5"
              >
                Amount
              </label>
              <div className="relative">
                <div className="absolute inset-y-0 left-0 flex items-center justify-center pl-3 pr-4 cursor-pointer">
                  <Naira />
                </div>

                <NumericFormat
                  thousandSeparator
                  decimalScale={2}
                  fixedDecimalScale
                  onValueChange={handleAmountChange}
                  prefix=""
                  placeholder="Enter amount to transfer"
                  className="border border-[#E5E7EB] text-base rounded-lg placeholder:text-[#B7BABF] placeholder:text-sm focus:border-[#3976E8] focus:ring-0 focus:outline-none block w-full md:px-[18px] pl-9 md:pl-9"
                />
              </div>
              <p className="text-brand-red">{errors.amount?.message}</p>
            </div>
            <div className="flex space-x-4">
              <Button
                loading={isLoading}
                type="submit"
                btnColor="default"
                label="Continue"
                size="block"
                // custom
                className={clsx('h-[40px] rounded-[2px]')}
              />
            </div>
          </form>
        </div>
      </>
    </Modal>
  );
}

export default WithdrawModal;
