/* eslint-disable import/prefer-default-export */
import React from 'react';
import clsx from 'clsx';

import Logo from '../../assets/images/logo.png';

// eslint-disable-next-line react/prop-types
export function AuthLayout({ children }) {
  return (
    <main className={clsx('h-full max-h-screen overflow-y-hidden')}>
      <div className="auth-bg  overflow-y-auto pb-10">
        <img
          src={Logo}
          alt="logo"
          className="object-cover lg:w-[250px] w-[120px] md:pt-16 pt-[60px] mx-auto md:pb-11 pb-[40px] md:mb-0"
        />
        <div className="mx-auto md:w-[480px] px-[24px] lg:px-0">
          <div>{children}</div>
        </div>
      </div>
    </main>
  );
}
