/* eslint-disable object-curly-newline */
/* eslint-disable operator-linebreak */
import axios from 'axios';
import { isEmpty, isNil, merge, trim } from 'lodash';
import { toast } from 'react-toastify';
import storageName from '../../helpers/storageHelpers';

export const API = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL,
});

export const uploadInstance = axios.create({
  baseURL: process.env.REACT_APP_ASSET_UPLOAD_SERVICE_URL,
});

export const dojahInstance = axios.create({
  baseURL: process.env.REACT_APP_DOJAH_BASE_URL,
});

API.interceptors.request.use((config) => {
  // check url and then add key
  const token = localStorage.getItem(storageName.userToken);

  // const key = localStorage.getItem('apiKey');
  const customHeaders = config.headers;
  if (!isNil(token) && !isEmpty(token)) {
    customHeaders.Authorization = `Bearer ${trim(token, '"')}`;
  }

  if (
    // eslint-disable-next-line operator-linebreak
    config.url === 'api/v1/business/webhook' ||
    config.url === 'api/v1/business/api-keys'
  ) {
    // customHeaders['x-api-key'] = key;
  }
  return {
    ...config,
    headers: merge({}, customHeaders, config.headers),
  };
});

API.interceptors.response.use(
  (response) => response,
  (err) => {
    if (err?.response?.status === 401 && err.config) {
      localStorage.clear();
      toast.info('Session expired, Please login');
      window.location.replace('/');
    }

    if (err?.response?.status >= 399 && err.config) {
      const msg = `TITLE: ${
        err?.response?.data?.message ||
        err?.response?.data?.error ||
        'oops, something went wrong.'
      }\n MESSAGE: ${err?.response?.data?.errorMessage || ''}`;
      toast.error(msg);
    }

    if (process.env.NODE_ENV === 'development') {
      // eslint-disable-next-line no-console
      console.log('err.response =============>>>', err?.response);
    }

    return Promise.reject(err);
  },
);
