import { useLocation } from 'react-router-dom';
import {
  includes,
  isNil,
  map,
  some,
  values,
  concat,
  join,
  isArray,
} from 'lodash';
import {
  Bell,
  Logout,
  Profile,
  MiniSupport,
  Home,
  ActiveHome,
  ActiveDashboard,
  Dashboard,
  ActiveAccount,
  Account,
  ActiveTransaction,
  Transaction,
  ActiveTransfer as ActiveWallet,
  Wallet,
  ActiveSetting,
  Setting,
  Refer,
  ActiveRefer,
} from '../../icons';
import { useUserRoles } from '../../helpers/useUserRole';
// import { useAuth } from '../../context';

// const { userDetails } = useAuth();
// const roles = useUserRoles();
// const pathname = useLocation();

export const sidebarRoutes = {
  home: {
    route: '/home',
    title: 'get started',
    // disabled: false,
    // hidden: !isNil(userDetails?.business),
    activeIcon: ActiveHome,
    icon: Home,
    isActive: includes(
      [
        '/home',
        '/onboard/business',
        '/onboard/company',
        '/onboard/active',
        '/onboard/unregistered',
      ],
      // pathname.pathname,
    ),
    allowed: true,
  },
  dashboard: {
    route: '/dashboard',
    title: 'dashboard',
    // hidden: false,
    // disabled: kycStatus !== 'completed' && some(roles, ['owner']),
    icon: Dashboard,
    activeIcon: ActiveDashboard,
    isActive: includes(['/dashboard']),
    allowed: [
      'owner',
      'finance_admin',
      'finance_initiator',
      'finance_authorizer',
      'operations',
    ],
  },
  accounts: {
    route: '/accounts',
    title: 'accounts',
    // hidden: false,
    // disabled: kycStatus !== 'completed' && some(roles, ['owner']),
    icon: Account,
    activeIcon: ActiveAccount,
    isActive: includes(['/accounts']),
    allowed: [
      'owner',
      'finance_admin',
      'finance_initiator',
      'finance_authorizer',
      'operations',
    ],
  },
  wallet: {
    route: '/wallet',
    title: 'wallet',
    // hidden: false,
    // disabled: kycStatus !== 'completed' && some(roles, ['owner']),
    icon: Wallet,
    activeIcon: ActiveWallet,
    isActive: includes(['/wallet']),
    allowed: [
      'owner',
      'finance_admin',
      'finance_initiator',
      'finance_authorizer',
    ],
  },
  transactions: {
    route: '/transactions',
    title: 'transactions',
    // hidden: false,
    // disabled: kycStatus !== 'completed' && some(roles, ['owner']),
    icon: Transaction,
    activeIcon: ActiveTransaction,
    isActive: includes(['/transactions']),
    allowed: [
      'owner',
      'finance_admin',
      'finance_initiator',
      'finance_authorizer',
      'operations',
    ],
  },
  refer: {
    route: '/refer',
    title: 'refer & earn',
    // hidden: false,
    // disabled: kycStatus !== 'completed' && some(roles, ['owner']),
    icon: Refer,
    activeIcon: ActiveRefer,
    isActive: includes(['/refer']),
    allowed: [
      'owner',
      'finance_admin',
      'finance_initiator',
      'finance_authorizer',
      'operations',
    ],
  },
  settings: {
    route: '/settings',
    title: 'settings',
    // hidden: false,
    // disabled: false,
    icon: Setting,
    activeIcon: ActiveSetting,
    isActive: includes(['/settings']),
    allowed: [
      'customer',
      'owner',
      'finance_admin',
      'finance_initiator',
      'finance_authorizer',
      'operations',
      'developer',
    ],
  },
};

export default sidebarRoutes;
