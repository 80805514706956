/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable no-console */
/* eslint-disable import/prefer-default-export */
import React, { useEffect, useState } from 'react';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import { NumericFormat } from 'react-number-format';
import { Button, Input, Select } from '../atoms';
import { Close, Naira } from '../../icons';
import { Modal } from '../Modal';
import { getBanks, transferMoney } from '../../context/Actions/user';

const schema = yup.object().shape({
  amount: yup.number().required('Please enter amount'),
  bank_code: yup.string(),
  bank_name: yup.string(),
  account_number: yup.string().required('Account number is required'),
  purpose: yup.string(),
});

// eslint-disable-next-line react/prop-types
function TransferModal({ onClose }) {
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm({ resolver: yupResolver(schema) });
  const [banks, setBank] = useState([]);

  useEffect(() => {
    try {
      // eslint-disable-next-line no-inner-declarations
      async function fetchData() {
        const res = await getBanks();
        const results = [];
        res.data.banks.forEach((value) => {
          results.push({
            key: value.bankName,
            value: value.bankCode,
          });
        });
        setBank([{ key: 'Select Bank', value: '' }, ...results]);
      }
      // Trigger the fetch
      fetchData();
    } catch (err) {
      toast.error('Something went wrong');
    }
  }, []);

  const handlePaymentRequest = async (data) => {
    try {
      const res = await transferMoney(data);
      toast.success(res.message);
      onClose();
    } catch (error) {
      onClose();
    }
  };

  const setBankValue = (event) => {
    setValue('bank_name', event.target.selectedOptions[0].label);
    setValue('bank_code', event.target.selectedOptions[0].value);
  };

  const handleAmountChange = (values) => {
    const { value } = values;
    setValue('amount', value);
  };

  return (
    <Modal size="lg">
      <>
        <div className="sticky top-0 z-40 flex items-center justify-between px-3 py-6 bg-white border sm:px-5 border-brand-border">
          <p className="text-xl text-[#04004D] font-interMedium">Transfer</p>
          <Close className="cursor-pointer" onClick={onClose} />
        </div>
        <div className="h-full px-3 py-8 sm:p-8 md:h-fit">
          <form onSubmit={handleSubmit(handlePaymentRequest)}>
            <div className="mb-6">
              <Select
                {...register('bank_code')}
                type="text"
                label="Bank Name"
                options={banks}
                borderColor="border-[#D0D5DD]"
                required
                onChange={setBankValue}
                placeholder="Select Bank"
                errors={errors.bank_code?.message}
              />
            </div>
            <div className="mb-6">
              <Input
                {...register('account_number')}
                label="Account Number"
                type="text"
                length="10"
                placeholder="Enter account number"
                borderColor="border-[#D0D5DD]"
                required
                errors={errors.account_number?.message}
              />
              <p className="text-xs text-black uppercase font-interMedium">
                JOHN LEGEND
              </p>
            </div>
            <div className="mb-6">
              <label
                htmlFor="businessName"
                className="block mb-1.5 text-base tracking-tight font-inter text-[#344054] leading-5"
              >
                Amount
              </label>
              <div className="relative">
                <div className="absolute inset-y-0 left-0 flex items-center justify-center pl-3 pr-4 cursor-pointer">
                  <Naira />
                </div>

                <NumericFormat
                  thousandSeparator
                  decimalScale={2}
                  fixedDecimalScale
                  onValueChange={handleAmountChange}
                  prefix=""
                  placeholder="Enter amount to transfer"
                  className="border border-[#E5E7EB] text-base rounded-lg placeholder:text-[#B7BABF] placeholder:text-sm focus:border-[#3976E8] focus:ring-0 focus:outline-none block w-full md:px-[18px] pl-9 md:pl-9"
                />
              </div>
              <p className="text-brand-red">{errors.amount?.message}</p>
            </div>
            <div className="mb-14">
              <Input
                {...register('purpose')}
                label="Purpose for sending (Optional)"
                type="text"
                placeholder="Enter description"
                borderColor="border-[#D0D5DD]"
                required
                errors={errors.purpose?.message}
              />
            </div>
            <div className="flex space-x-4">
              <Button
                type="submit"
                btnColor="default"
                label="Continue"
                size="block"
                custom
              />
            </div>
          </form>
        </div>
      </>
    </Modal>
  );
}

export default TransferModal;
