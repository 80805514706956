/* eslint-disable camelcase, no-console */
import { yupResolver } from '@hookform/resolvers/yup';
import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import useFormPersist from 'react-hook-form-persist';
import { useNavigate } from 'react-router-dom';
import * as yup from 'yup';
import clsx from 'clsx';
import { IoArrowBack } from 'react-icons/io5';
import { toast } from 'react-toastify';
import { isEmpty, isNil } from 'lodash';

import { Button, FileUploadForm } from '../../atoms/index';

import storageName from '../../../helpers/storageHelpers';
import OnboardLayout from '../../layout/onboardingLayout';
import { useAuth, useUser } from '../../../context';

const schema = yup.object().shape({
  company_registration_documents: yup.object({
    cac_seven_or_cac_twothree_certificate_asset_url: yup
      .string()
      .url()
      .required('please upload file'),
    cac_two_certificate_asset_url: yup
      .string()
      .url()
      .required('please upload file'),
    cac_twoone_certificate_asset_url: yup
      .string()
      .url()
      .required('please upload file'),
    mermat_asset_url: yup.string().url().required('please upload file'),
    license: yup.string().url(),
    resident_permit: yup.string().url(),
  }),
});

function CompanyDoc() {
  const { kycDetails, handleAuthenticateUser } = useAuth();
  // eslint-disable-next-line operator-linebreak
  const { isLoading, handleIsLoading, handleCreateBusinesskycDraft } =
    useUser();
  const navigate = useNavigate();
  const {
    handleSubmit,
    setValue,
    getValues,
    watch,
    formState: { errors },
  } = useForm({ resolver: yupResolver(schema) });
  useFormPersist(storageName.business_creation, {
    watch,
    setValue,
    storage: window.localStorage,
  });

  const setCac7 = (res) => {
    setValue(
      'company_registration_documents.cac_seven_or_cac_twothree_certificate_asset_url',
      res,
    );
  };
  const setCac2 = (res) => {
    setValue(
      'company_registration_documents.cac_two_certificate_asset_url',
      res,
    );
  };
  const setCac2_1 = (res) => {
    setValue(
      'company_registration_documents.cac_twoone_certificate_asset_url',
      res,
    );
  };
  const setMemo = (res) => {
    setValue('company_registration_documents.mermat_asset_url', res);
  };
  const setLic = (res) => {
    setValue('company_registration_documents.license', res);
  };
  const setRes = (res) => {
    setValue('company_registration_documents.resident_permit', res);
  };

  useEffect(() => {
    if (isNil(kycDetails?.kyc) || isEmpty(kycDetails?.kyc)) {
      return;
    }

    setValue(
      'company_registration_documents.mermat_asset_url',
      kycDetails?.kyc?.business_details?.company_registration_documents
        ?.mermat_asset_url,
    );

    setValue(
      'company_registration_documents.cac_seven_or_cac_twothree_certificate_asset_url',
      kycDetails?.kyc?.business_details?.company_registration_documents
        ?.cac_seven_or_cac_twothree_certificate_asset_url,
    );

    setValue(
      'company_registration_documents.cac_two_certificate_asset_url',
      kycDetails?.kyc?.business_details?.company_registration_documents
        ?.cac_two_certificate_asset_url,
    );

    setValue(
      'company_registration_documents.cac_twoone_certificate_asset_url',
      kycDetails?.kyc?.business_details?.company_registration_documents
        ?.cac_twoone_certificate_asset_url,
    );

    setValue(
      'company_registration_documents.license',
      kycDetails?.kyc?.business_details?.company_registration_documents
        ?.license,
    );

    setValue(
      'company_registration_documents.resident_permit',
      kycDetails?.kyc?.business_details?.company_registration_documents
        ?.resident_permit,
    );

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleBusinessSubmit = async () => {
    try {
      handleIsLoading(true);
      const details = getValues('company_registration_documents');

      await handleCreateBusinesskycDraft(
        { company_registration_documents: details },
        (error) => {
          if (error) {
            toast.error(
              error?.response?.data?.message ?? 'kyc documents upload failed',
            );
            return;
          }
          toast.success('kyc documents saved successfully');
          navigate('/onboard/company/review');
        },
      );
    } catch (error) {
      toast.error(
        error?.response?.data?.message ?? 'kyc document upload failed',
      );
      if (process.env.NODE_ENV === 'development') {
        console.log('handle company document ======>>> error', error);
      }
    } finally {
      handleIsLoading(false);
      await handleAuthenticateUser();
    }
  };

  return (
    <OnboardLayout>
      <div className="lg:px-16 pl-12 pr-8 xl:w-[65%] mx-auto">
        <div
          className="sm:mt-[5rem] mt-24 text-base text-[#292D32] font-interMedium flex items-center gap-x-2 lg:mb-5 mb-3 cursor-pointer"
          onClick={() => navigate(-1)}
          role="button"
          onKeyDown={() => {}}
          tabIndex={-1}
        >
          <IoArrowBack className={clsx('w-5 h-5 text-[#3976E8]')} />
          <span className="hidden md:block text-[#3976E8]">
            Back to Dashboard
          </span>
        </div>

        <div className="flex flex-col py-6 mx-2 xl:w[60rem]">
          <p className="mb-10 text-xl font-interSemiBold">Register Company</p>
          <form
            className="lg:w-[30rem] gap-x-10"
            onSubmit={handleSubmit(handleBusinessSubmit)}
          >
            <FileUploadForm
              label="Form CAC 7/ CAC 2.3s"
              response={setCac7}
              persisted={getValues(
                'company_registration_documents.cac_seven_or_cac_twothree_certificate_asset_url',
              )}
              error={
                errors.company_registration_documents
                  ?.cac_seven_or_cac_twothree_certificate_asset_url?.message
              }
            />
            <FileUploadForm
              label="Form CAC 2"
              response={setCac2}
              persisted={getValues(
                'company_registration_documents.cac_two_certificate_asset_url',
              )}
              error={
                errors.company_registration_documents
                  ?.cac_two_certificate_asset_url?.message
              }
            />
            <FileUploadForm
              label="Form CAC 2.1"
              response={setCac2_1}
              persisted={getValues(
                'company_registration_documents.cac_twoone_certificate_asset_url',
              )}
              error={
                errors.company_registration_documents
                  ?.cac_twoone_certificate_asset_url?.message
              }
            />
            <FileUploadForm
              label="Memorandum of Association"
              response={setMemo}
              persisted={getValues(
                'company_registration_documents.mermat_asset_url',
              )}
              error={
                errors.company_registration_documents?.mermat_asset_url?.message
              }
            />
            <FileUploadForm
              label="License (Optional)"
              response={setLic}
              persisted={getValues('company_registration_documents.license')}
              error={errors.company_registration_documents?.license?.message}
            />
            <FileUploadForm
              label="Resident Permit (Optional) "
              response={setRes}
              persisted={getValues(
                'company_registration_documents.resident_permit',
              )}
              error={
                errors.company_registration_documents?.resident_permit?.message
              }
            />
            <div className="mt-10">
              <Button
                loading={isLoading}
                // isDisabled={isLoading}
                type="submit"
                label="Save and Continue"
                btnColor="default"
                size="block"
                className={clsx('h-[55px]')}
              />
            </div>
          </form>
        </div>
      </div>
    </OnboardLayout>
  );
}

export default CompanyDoc;
