/* eslint-disable operator-linebreak */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/react-in-jsx-scope */

import { isEmpty, size } from 'lodash';
import clsx from 'clsx';
import { useFormContext, useFieldArray, useFormState } from 'react-hook-form';
import { IoAdd } from 'react-icons/io5';

import { InvoiceItem } from './InvoiceItem';

export function InvoiceForm() {
  const { control } = useFormContext();
  const { errors } = useFormState({
    control,
  });
  const { fields, append, remove } = useFieldArray({
    control, // control props comes from useForm (optional: if you are using FormProvider)
    name: 'items', // unique name for your Field Array
  });

  const itemsLength = size(fields);

  const addInvoiceItem = () =>
    // eslint-disable-next-line implicit-arrow-linebreak
    append({
      item: '',
      price: 0,
      quantity: 0,
    });

  const deleteInvoiceItem = (id) => remove(id);

  return (
    <main>
      <div className="relative overflow-x-auto shadowmd sm:rounded-lg">
        <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
          <thead className="text-xs text-[#667085] capitalize bg-[#F9FAFB]">
            <tr>
              <th scope="col" className="px-2 py-3">
                ID
              </th>
              <th scope="col" className="px-2 py-3">
                Items
              </th>
              <th scope="col" className="px-2 py-3">
                Quantity
              </th>
              <th scope="col" className="px-2 py-3">
                Price
              </th>
              <th scope="col" className="px-2 py-3">
                Amount
              </th>
              <th scope="col" className="px-2 py-3">
                <span className="sr-only">Edit</span>
              </th>
            </tr>
          </thead>
          <tbody>
            {fields.map((item, index) => (
              <InvoiceItem
                key={index}
                {...item}
                id={index}
                deleteInvoiceItem={deleteInvoiceItem}
                totalItems={itemsLength}
              />
            ))}
          </tbody>
        </table>
        {isEmpty(errors?.items) && itemsLength < 10 && (
          <div className={clsx('flex justify-start items-center mt-4 mb-2')}>
            <div className={clsx('mx-2')}>
              <IoAdd className={clsx('text-blue-500 h-4 w-4 font-bold')} />
            </div>
            <div>
              <p
                className="text-[#3976E8] font-normal text-xs cursor-pointer w-44"
                onClick={addInvoiceItem}
              >
                Add a new invoice item
              </p>
            </div>
          </div>
        )}
      </div>
    </main>
  );
}

export default InvoiceForm;
