/* eslint-disable implicit-arrow-linebreak */
/* eslint-disable no-console */
import React, { useState, useEffect } from 'react';
import ios from '../assets/icons/share-ios.svg';

export function InstallPopup() {
  const [promptEvent, setPromptEvent] = useState(null);
  const [showPopup, setShowPopup] = useState(false);
  const [showPopupIos, setShowPopupIos] = useState(false);

  useEffect(() => {
    const handleBeforeInstallPrompt = (event) => {
      event.preventDefault();
      setPromptEvent(event);
      setShowPopup(true);
    };

    window.addEventListener('beforeinstallprompt', handleBeforeInstallPrompt);

    return () => {
      window.removeEventListener(
        'beforeinstallprompt',
        handleBeforeInstallPrompt,
      );
    };
  }, []);

  const handleInstall = () => {
    if (promptEvent) {
      promptEvent.prompt();
      promptEvent.userChoice.then((choiceResult) => {
        if (choiceResult.outcome === 'accepted') {
          console.log('User accepted the A2HS prompt');
        } else {
          console.log('User dismissed the A2HS prompt');
        }
        setShowPopup(false);
      });
    }
  };

  const handleCancel = () => {
    setShowPopup(false);
    setShowPopupIos(false);
  };

  // Detects if device is on iOS
  const isIos = () => {
    const userAgent = window.navigator.userAgent.toLowerCase();
    return /iphone|ipad|ipod/.test(userAgent);
  };
  // Detects if device is in standalone mode
  const isInStandaloneMode = () => {
    if (window.navigator && window.navigator.standalone) {
      return window.navigator.standalone;
    }
    return false;
  };

  // Checks if should display install popup notification:
  useEffect(() => {
    // Checks if should display install popup notification:
    if (isIos() && !isInStandaloneMode()) {
      setShowPopupIos(true);
    }
  }, []);

  return (
    <>
      {showPopup && (
        <div className="bg-white bottom-0 w-full rounded p-5 z-50 fixed inset0 bg-[#54545440] backdrop-blur-sm shadow-lg">
          <div>
            <p className="text-lg font-interBold text-brand-black mb-2">
              Install FundR
            </p>
            <p>This site can be installed as an application.</p>
            <button
              type="button"
              onClick={handleInstall}
              className="rounded-full px-4 py-2 font-interMedium mt-3 bg-brand-blue text-white float-right"
            >
              Install App
            </button>
          </div>
          <button
            type="button"
            onClick={handleCancel}
            className="rounded-full px-4 py-2 font-interMedium mt-3 bg-white hover:bg-brand-blue text-brand-blue hover:text-white float-right mr-3 border"
          >
            Cancel
          </button>
        </div>
      )}
      {showPopupIos && (
        <div className="bg-white bottom-0 w-full rounded p-5 z-50 fixed inset0 bg-[#54545440] backdrop-blur-sm shadow-lg">
          <div>
            <p className="text-lg font-interBold text-brand-black mb-2">
              Install FundR
            </p>
            <div className="flex items-center flex-wrap">
              <p>To install FundR on your phone click on </p>

              <img src={ios} alt="ios" />

              <p>icon on your browser, then Add to home screen.</p>
            </div>
          </div>
          <button
            type="button"
            onClick={handleCancel}
            className="rounded-full px-4 py-2 font-interMedium mt-3 bg-white hover:bg-brand-blue text-brand-blue hover:text-white float-right mr-3 border"
          >
            Cancel
          </button>
        </div>
      )}
    </>
  );
}

export default InstallPopup;
