import { API } from '../../services/api';

export const getProfile = async () => {
  try {
    const response = await API.get('/auth/user');
    return response;
  } catch (error) {
    return Promise.reject(error);
  }
};

export const login = async (payload) => {
  try {
    const res = await API.post('auth/login', payload);
    return res;
  } catch (error) {
    return Promise.reject(error);
  }
};
export const signUpOtp = async (payload) => {
  try {
    const res = await API.post('auth/signup/otp', payload);
    return res;
  } catch (error) {
    return Promise.reject(error);
  }
};

export const registerUser = async (payload) => {
  try {
    const res = await API.post('auth/signup', payload);
    return res;
  } catch (error) {
    return Promise.reject(error);
  }
};
export const resetPassword = async (payload) => {
  try {
    const res = await API.post('auth/change-password', payload);
    return res;
  } catch (error) {
    return Promise.reject(error);
  }
};

export const forgetPasswordOtp = async (payload) => {
  try {
    const res = await API.post('auth/reset-password/otp', payload);
    return res;
  } catch (error) {
    return Promise.reject(error);
  }
};

export const verifyForgetPassword = async (payload) => {
  try {
    const res = await API.post('auth/reset-password', payload);
    return res;
  } catch (error) {
    return Promise.reject(error);
  }
};

export const verifyInvite = async (inviteToken, requestConfig = {}) => {
  try {
    const res = await API.get(
      `api/v1/business/invite/verify?invite_token=${inviteToken}`,
      requestConfig,
    );
    return res;
  } catch (error) {
    return Promise.reject(error);
  }
};

export const logout = async () => {
  try {
    const response = await API.delete('/auth/logout');

    return response;
  } catch (error) {
    return Promise.reject(error);
  }
};
