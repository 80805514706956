/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable operator-linebreak */
/* eslint-disable no-confusing-arrow */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events, implicit-arrow-linebreak */
import React, { useEffect, useMemo, useState } from 'react';
// import {
//   Chart as ChartJS,
//   BarElement,
//   CategoryScale,
//   LinearScale,
//   Tooltip,
//   Legend,
// } from 'chart.js';
// import { Bar } from 'react-chartjs-2';
import { useNumericFormat } from 'react-number-format';
import moment from 'moment';
import {
  find,
  forEach,
  isEmpty,
  isNil,
  map,
  sortBy,
  toNumber,
  values,
} from 'lodash';
import clsx from 'clsx';
import { useElementSize } from 'usehooks-ts';
import {
  differenceInDays,
  differenceInMonths,
  format,
  subMonths,
  subWeeks,
  subYears,
} from 'date-fns';
import { useAuth, useUser } from '../context';
import { Loader } from './Loader';
import { Select } from './atoms/index';
import { MainChart } from './mainChart';

export function Chart() {
  const {
    balance: walletBalance,
    deposits,
    isLoading,
    handleFetchTransactionDeposit,
  } = useUser();
  const { userDetails } = useAuth();
  const { value } = useNumericFormat({
    value: walletBalance,
    prefix: userDetails?.country?.currencySymbol,
    decimalScale: 2,
    thousandSeparator: ',',
  });

  const [lastRevenue, setLastRevenue] = useState(0);

  const isNegative = useMemo(() => lastRevenue < 0, [lastRevenue]);

  const { value: revenueValue } = useNumericFormat({
    value: lastRevenue,
    prefix: !isNegative ? '+' : '',
    decimalScale: 2,
    thousandSeparator: ',',
    suffix: '%',
    allowLeadingZeros: true,
  });
  const [chartWrapperRef, size] = useElementSize();
  const [dateRange, setDaterange] = useState('last7days');

  const today = new Date();

  const domainPadding = useMemo(() => {
    if (dateRange === 'last30days') {
      return 10;
    }
    if (dateRange === 'last1Year') {
      return 30;
    }
    return 60;
  }, [dateRange]);

  const dateRangeArray = [
    { key: 'Last 7 Days', value: 'last7days' },
    { key: 'Last 30 Days', value: 'last30days' },
    { key: 'Last One Year', value: 'last1Year' },
  ];

  const dateRangeLabels = {
    last7days: 'ddd',
    last30days: 'MMM-DD',
    last1Year: 'MMM',
  };

  const currentDateRangeValue = useMemo(
    () => find(dateRangeArray, (date) => date.value === dateRange).key,
    [dateRange],
  );

  // Get Bar Chart Details
  const chartData = useMemo(() => {
    const chartDateData = {};
    // GENERATION LABEL
    if (dateRange === 'last7days') {
      let numberOfDays = 0;
      const diffInDays = Math.abs(differenceInDays(subWeeks(today, 1), today));

      while (numberOfDays <= diffInDays - 1) {
        const label = moment()
          .subtract(diffInDays - numberOfDays, 'days')
          .format(dateRangeLabels[dateRange]);

        chartDateData[label] = 0;
        numberOfDays += 1;
      }
    }

    if (dateRange === 'last30days') {
      let numberOfDay = 1;
      const diffInDays = Math.abs(differenceInDays(subMonths(today, 1), today));
      while (numberOfDay <= diffInDays) {
        const label = moment()
          .subtract(diffInDays - numberOfDay, 'days')
          .format(dateRangeLabels[dateRange]);

        chartDateData[label] = 0;
        numberOfDay += 1;
      }
    }

    if (dateRange === 'last1Year') {
      let numberOfMonth = 1;
      const pastYear = subYears(today, 1);

      const diffInMonths = Math.abs(differenceInMonths(pastYear, today));
      while (numberOfMonth <= diffInMonths) {
        const label = moment()
          .startOf('month')
          .subtract(diffInMonths - numberOfMonth, 'month')
          .format(dateRangeLabels[dateRange]);
        chartDateData[label] = 0;
        numberOfMonth += 1;
      }
    }

    const sortedData = values(
      sortBy(
        map(deposits, (deposit) => ({
          ...deposit,
          created_at: new Date(deposit.created_at),
        })),
        (dateObj) => dateObj.created_at,
      ),
    );

    forEach(sortedData, (deposit) => {
      const label = moment(deposit.created_at).format(
        dateRangeLabels[dateRange],
      );

      if (isNil(chartDateData[label])) return;
      chartDateData[label] =
        toNumber(chartDateData[label]) + toNumber(deposit.amount);
    });
    const data = Object.entries(chartDateData).map((obj) => ({
      date: obj[0],
      amount: obj[1],
    }));

    return data;
  }, [deposits]);

  useEffect(() => {
    (async () => {
      const formatString = 'yyyy-MM-dd';
      const endDate = format(today, formatString);
      const emptyDeposits = isEmpty(deposits);
      if (dateRange === 'last30days') {
        await handleFetchTransactionDeposit(
          userDetails?.business.id,
          {
            params: {
              end_date: endDate,
              start_date: format(subMonths(today, 1), formatString),
            },
          },
          emptyDeposits,
        );
        return;
      }
      if (dateRange === 'last1Year') {
        await handleFetchTransactionDeposit(
          userDetails?.business.id,
          {
            params: {
              end_date: endDate,
              start_date: format(subYears(today, 1), formatString),
            },
          },
          emptyDeposits,
        );
        return;
      }
      await handleFetchTransactionDeposit(
        userDetails?.business.id,
        {},
        emptyDeposits,
      );
    })();
  }, [dateRange]);

  useEffect(() => {
    if (!isNil(walletBalance) && toNumber(walletBalance) < 1) {
      return;
    }
    let cumulativeDepositAmount = 0;
    forEach(deposits, (deposit) => {
      if (!isNil(deposit) && !isNil(deposit?.amount)) {
        cumulativeDepositAmount += toNumber(deposit.amount);
      }
    });

    const result =
      (toNumber(walletBalance) - toNumber(cumulativeDepositAmount)) / 100;

    setLastRevenue(result);
  }, [deposits]);

  const handleDateChange = (event) => {
    setDaterange(event.target.selectedOptions[0].value);
  };

  return (
    <div className="flex flex-col lg:px-8 py-6 mt-10 rounded-xl border border-solid bg-neutral-50 border-[color:var(--gray-200,#E4E4E7)] max-md:px-5 max-md:max-w-full">
      <Loader isActive={isLoading} />
      <div className="flex gap-5 justify-between w-full text-sm max-md:flex-wrap max-md:pr-5 max-md:max-w-full">
        <div className="flex gap-5 justify-between font-semibold text-zinc-500">
          <div className="grow my-auto whitespace-nowrap">Showing data for</div>
          <Select
            onChange={handleDateChange}
            placeholder="Select Date"
            options={dateRangeArray}
            value={dateRange}
            className={clsx(
              'lg:w-[165px] sm:h-[42px] md:h-[42px] p-0 rounded-lg',
            )}
          />
        </div>
        <div className="flex gap-5 items-center text-black whitespace-nowrap leading-[138%]">
          <div
            className={`grow justify-center self-stretch  my-auto ${
              dateRange === 'last7days'
                ? 'px-5 py-3 rounded-md  bg-cyan-400 bg-opacity-10'
                : ''
            } `}
            onClick={() => {
              setDaterange('last7days');
            }}
          >
            Last 7 days
          </div>
          <div
            className={`grow justify-center self-stretch  my-auto ${
              dateRange === 'last30days'
                ? 'px-5 py-3 rounded-md  bg-cyan-400 bg-opacity-10'
                : ''
            } `}
            onClick={() => {
              setDaterange('last30days');
            }}
          >
            Last 30 days
          </div>
          <div
            className={`grow justify-center self-stretch  my-auto ${
              dateRange === 'last1Year'
                ? 'px-5 py-3 rounded-md  bg-cyan-400 bg-opacity-10'
                : ''
            } `}
            onClick={() => {
              setDaterange('last1Year');
            }}
          >
            One Year
          </div>
        </div>
      </div>

      <div
        className={clsx(
          'pt-6 pb-11 mt-6 bg-white rounded-md border border-solid border-zinc-200 lg:px-5 max-md:max-w-full',
        )}
      >
        <div className="flex flex-col px-7  pb-11 mt-8  max-md:px-5 max-md:max-w-full">
          <div className="flex gap-3 self-start text-sm whitespace-nowrap">
            <div className="font-bold leading-9 text-neutral-700">Revenue</div>
            <div className="grow my-auto leading-5 text-black">
              <span
                className={`font-light ${isNegative ? 'text-red-500' : 'text-green-400'}`}
              >
                {revenueValue}
              </span>
              {/* eslint-disable-next-line react/jsx-one-expression-per-line */}
              <span className="font-light"> vs {currentDateRangeValue}</span>
            </div>
          </div>
          <div className="flex gap-3 self-start whitespace-nowrap">
            <div className="grow text-3xl font-bold text-neutral-700">
              {value}
            </div>
            <div className="grow my-auto text-sm leading-5 text-black">
              in total value
            </div>
          </div>
        </div>

        {!isEmpty(chartData) ? (
          dateRange === 'last30days' ? (
            <MainChart data={chartData} />
          ) : dateRange === 'last7days' ? (
            <MainChart data={chartData} />
          ) : dateRange === 'last1Year' ? (
            <MainChart data={chartData} />
          ) : (
            <></>
          )
        ) : (
          <div
            className={clsx(
              'w-full h-full bg-blue-100 flex justify-center items-center',
            )}
          >
            <p>No record to display</p>
          </div>
        )}
      </div>
    </div>
  );
}

export default Chart;
