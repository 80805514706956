/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable implicit-arrow-linebreak */
/* eslint-disable operator-linebreak */
/* eslint-disable indent */
/* eslint-disable max-len */
/* eslint-disable no-nested-ternary */
/* eslint-disable react/button-has-type */
/* eslint-disable react/prop-types */
/* eslint-disable import/prefer-default-export */
/* eslint-disable function-paren-newline */
/* eslint-disable function-paren-newline,  object-curly-newline */

import React, { useState, useEffect } from 'react';
// import { Loader } from '../Loader';
import { Sidebar } from '../sidebar/index';
import { useMediaQuery } from '../../helpers/useMediaQuery';
import { Header } from '../Header/index';

function DashboardLayout({ children }) {
  const isMobileView = useMediaQuery('(max-width: 640px)');
  const isTabletView = useMediaQuery('(max-width: 840px)');
  const [isSideNavVisible, setSideNavVisible] = useState(true);

  useEffect(() => {
    if (isMobileView || isTabletView) {
      setSideNavVisible(false);
    } else {
      setSideNavVisible(true);
    }
  }, [isMobileView]);
  return (
    <>
      {/* <Loader isActive={isLoading} /> */}
      <div className="h-screen w-full overflow-hidden lg:flex">
        {isMobileView || isTabletView ? (
          <div
            className={`fixed bottom-0 left-0 top-0 z-20 w-full bg-gray-800/60 ${
              isSideNavVisible ? '' : 'hidden'
            }`}
            onClick={() => setSideNavVisible(false)}
          />
        ) : null}

        <Sidebar
          {...{
            open: isSideNavVisible,
            onClose: () => setSideNavVisible(false),
          }}
        />

        <div className="flex-1 overflow-auto [@media(max-width:767px)]:scrollbar-hide h-full">
          <Header onClick={() => setSideNavVisible(true)} />

          {/* <div className="lg:pb-20 "> */}
          <div className="px-4 lg:px-12 bg-[#FBFBFB]">{children}</div>
        </div>
      </div>
    </>
  );
}

export default DashboardLayout;
