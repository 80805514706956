import { API, uploadInstance } from '../../services/api';

export const saveFcmToken = async (token, requestConfig = {}) => {
  try {
    const result = await API.put('/fcm-token', { token }, requestConfig);
    return result;
  } catch (error) {
    return Promise.reject(error);
  }
};

export const getBalance = async (businessId) => {
  try {
    const response = await API.get(`/api/v1/accounts/${businessId}/balance`);
    return response;
  } catch (error) {
    return Promise.reject(error);
  }
};

export const getVirtualAccounts = async (businessId) => {
  try {
    const response = await API.get(
      `/api/v1/business/virtual/${businessId}/account`,
    );

    return response;
  } catch (error) {
    return Promise.reject(error);
  }
};

export const getUserDetails = async () => {
  try {
    const res = await API.get('auth/user');
    return res;
  } catch (error) {
    return Promise.reject(error);
  }
};

export const getKycStatus = async (id = '') => {
  try {
    const param = id !== '' ? `/${id}` : '';
    const res = await API.get(`api/v1/kyc${param}`);
    return res;
  } catch (error) {
    return Promise.reject(error);
  }
};

export const fetchBusinessTypes = async () => {
  try {
    const res = await API.get('api/v1/kyc/business/types');
    return res;
  } catch (error) {
    return Promise.reject(error);
  }
};

export const getBanks = async (provider = 'dojah') => {
  try {
    const res = await API.get(`api/v1/banks/${provider}`);
    return res;
  } catch (error) {
    return Promise.reject(error);
  }
};

export const validateBankAccount = async (payload) => {
  try {
    const res = await API.post('api/v1/kyc/bank/account', payload);
    return res;
  } catch (error) {
    return Promise.reject(error);
  }
};

export const validateBvn = async (payload, requestConfig = {}) => {
  try {
    const res = await API.post(
      'api/v1/kyc/bank/account/bvn',
      payload,
      requestConfig,
    );
    return res;
  } catch (error) {
    return Promise.reject(error);
  }
};

export const getBusinessDetails = async (businessId) => {
  try {
    const res = await API.get(`api/v1/business/${businessId}`);
    return res;
  } catch (error) {
    return Promise.reject(error);
  }
};

export const updateBusinessDetails = async (id, payload) => {
  try {
    const res = await API.patch(`api/v1/business/${id}/details`, payload);
    return res;
  } catch (error) {
    return Promise.reject(error);
  }
};

export const address = async (id, payload) => {
  try {
    const res = await API.post(`api/v1/user/${id}/kyc/address`, payload);
    return res;
  } catch (error) {
    return Promise.reject(error);
  }
};

export const registerBusiness = async (payload) => {
  try {
    const res = await API.post('api/v1/kyc/business', payload);
    return res;
  } catch (error) {
    return Promise.reject(error);
  }
};

export const bankAccount = async (id, payload) => {
  try {
    const res = await API.post(`api/v1/user/${id}/kyc/bank-account`, payload);
    return res;
  } catch (error) {
    return Promise.reject(error);
  }
};

export const getInviteRoles = async () => {
  try {
    const res = await API.get('api/v1/business/invite/roles');
    return res;
  } catch (error) {
    return Promise.reject(error);
  }
};

export const sendInvite = async (payload) => {
  try {
    const res = await API.post('api/v1/business/invite', payload);
    return res;
  } catch (error) {
    return Promise.reject(error);
  }
};

export const getActiveInvites = async () => {
  try {
    const res = await API.get('api/v1/user/invites');
    return res;
  } catch (error) {
    return Promise.reject(error);
  }
};
export const acceptInvite = async (id) => {
  try {
    const res = await API.put(`api/v1/user/invite/${id}`);
    return res;
  } catch (error) {
    return Promise.reject(error);
  }
};
export const rejectInvite = async (id) => {
  try {
    const res = await API.delete(`api/v1/user/invite/${id}`);
    return res;
  } catch (error) {
    return Promise.reject(error);
  }
};
export const getTeamMembers = async (teamId, businessId) => {
  try {
    const res = await API.get(
      `api/v1/business/${businessId}/team/${teamId}/members`,
    );
    return res;
  } catch (error) {
    return Promise.reject(error);
  }
};

export const disableBusinessTeamMember = async (memberId, businessId) => {
  try {
    const res = await API.patch(
      `api/v1/business/${businessId}/team/member/${memberId}`,
    );
    return res;
  } catch (error) {
    return Promise.reject(error);
  }
};

export const upload = async (payload, config = {}) => {
  try {
    const res = await uploadInstance.post('/upload/business', payload, config);
    return res;
  } catch (error) {
    return Promise.reject(error);
  }
};

export const getTransactions = async (businessId, requestConfig = {}) => {
  try {
    const res = await API.get(`api/v1/transactions/${businessId}/all`, {
      params: {
        page: 1,
        per_page: 20,
      },
      ...requestConfig,
    });
    return res;
  } catch (error) {
    return Promise.reject(error);
  }
};

export const getTransactionExportCsv = async (
  businessId,
  requestConfig = {},
) => {
  try {
    const res = await API.get(
      `api/v1/transactions/csv/${businessId}/all`,
      requestConfig,
    );
    return res;
  } catch (error) {
    return Promise.reject(error);
  }
};

export const getSettlements = async (id) => {
  try {
    const res = await API.get(`api/v1/user/${id}/settlements`);
    return res;
  } catch (error) {
    return Promise.reject(error);
  }
};

export const getWalletBalance = async (id) => {
  try {
    const res = await API.get(`api/v1/accounts/${id}/balance`);
    return res;
  } catch (error) {
    return Promise.reject(error);
  }
};

export const getBusinessCollectionAccounts = async (id) => {
  try {
    const res = await API.get(`api/v1/accounts/${id}/collection-accounts`);
    return res;
  } catch (error) {
    return Promise.reject(error);
  }
};

export const getVirtualAccountTransactions = async (
  id,
  methodId,
  requestConfig = {},
) => {
  try {
    const method = `/${methodId}`;

    const res = await API.get(`api/v1/accounts/${id}/collections${method}`, {
      params: {
        page: 1,
        per_page: 20,
      },
      ...requestConfig,
    });

    return res;
  } catch (error) {
    return Promise.reject(error);
  }
};

export const withdrawalRequest = async (businessId, payload) => {
  try {
    const res = await API.post(`api/v1/money/${businessId}/withdraw`, payload);
    return res;
  } catch (error) {
    return Promise.reject(error);
  }
};

export const requestMoney = async (payload) => {
  try {
    const res = await API.post('api/v1/transfer/request', payload);
    return res;
  } catch (error) {
    return Promise.reject(error);
  }
};

export const transferMoney = async (payload) => {
  try {
    const res = await API.post('api/v1/transfer/bank', payload);
    return res;
  } catch (error) {
    return Promise.reject(error);
  }
};

export const fetchRequests = async () => {
  try {
    const res = await API.get('api/v1/auth/transfer/request');
    return res;
  } catch (error) {
    return Promise.reject(error);
  }
};

export const fetchCollections = async (businessId) => {
  try {
    const res = await API.get(`api/v1/user/${businessId}/collections`);
    return res;
  } catch (error) {
    return Promise.reject(error);
  }
};

export const fetchWithdrawals = async (businessId, requestConfig = {}) => {
  try {
    const res = await API.get(`api/v1/transactions/${businessId}/settlements`, {
      params: {
        page: 1,
        per_page: 20,
      },
      ...requestConfig,
    });
    return res;
  } catch (error) {
    return Promise.reject(error);
  }
};

export const createBusinessSectionVirtualAccount = async (
  businessId,
  payload,
) => {
  try {
    const res = await API.post(
      `api/v1/business/virtual/${businessId}/account/section`,
      payload,
    );
    return res;
  } catch (error) {
    return Promise.reject(error);
  }
};

export const fetchApiKey = async (businessId) => {
  try {
    const res = await API.get(`api/v1/business/api-keys/${businessId}`);
    return res;
  } catch (error) {
    return Promise.reject(error);
  }
};

export const saveWebhook = async (payload) => {
  try {
    const res = await API.post('api/v1/business/webhook', payload);
    return res;
  } catch (error) {
    return Promise.reject(error);
  }
};

export const fetchWebhook = async (businessId) => {
  try {
    const res = await API.get(`api/v1/business/webhook/${businessId}`);
    return res;
  } catch (error) {
    return Promise.reject(error);
  }
};

export const fetchTransactionDeposits = async (id, requestConfig = {}) => {
  try {
    const res = await API.get(`api/v1/transactions/${id}/deposits`, {
      params: {
        page: 1,
        per_page: 1000,
      },
      ...requestConfig,
    });

    return res;
  } catch (error) {
    return Promise.reject(error);
  }
};

export const createBusinessKycDraft = async (payload) => {
  try {
    const res = await API.post('api/v1/kyc/business/draft', payload);
    return res.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

export const fetchBusinessTeams = async (businessId) => {
  try {
    const response = await API.get(`/api/v1/business/${businessId}/team/all`);
    return response;
  } catch (error) {
    return Promise.reject(error);
  }
};

export const fetchBusinessTeam = async (businessTeamId, businessId) => {
  try {
    const response = await API.get(
      `/api/v1/business/${businessId}/team/${businessTeamId}/members`,
    );
    return response;
  } catch (error) {
    return Promise.reject(error);
  }
};

export const fetchReferAndEarn = async () => {
  try {
    const response = await API.get('/api/v1/user/referrals');

    return response;
  } catch (error) {
    return Promise.reject(error);
  }
};
export const generateVirtualAccount = async (businessId) => {
  try {
    const response = await API.get(
      `/api/v1/business/virtual/${businessId}/account/main`,
    );
    return response;
  } catch (error) {
    return Promise.reject(error);
  }
};

export const fetchAllTransactionAlert = async () => {
  try {
    const res = await API.get('api/v1/business/transaction-alerts/all');
    return res;
  } catch (error) {
    return Promise.reject(error);
  }
};

export const fetchBusinessTransactionAlert = async () => {
  try {
    const res = await API.get('api/v1/business/transaction-alerts');
    return res;
  } catch (error) {
    return Promise.reject(error);
  }
};

export const setBusinessTransactionAlert = async (payload) => {
  try {
    const res = await API.post('api/v1/business/transaction-alerts', payload);
    return res;
  } catch (error) {
    return Promise.reject(error);
  }
};
