import React from 'react';
import useAuth, { AuthProvider } from './Auth';
import useUser, { UserProvider } from './User';
import useInvoice, { InvoiceProvider } from './Invoice';

export { useAuth, useUser, useInvoice };

// eslint-disable-next-line react/prop-types
function AppContext({ children }) {
  return (
    <AuthProvider>
      <UserProvider>
        <InvoiceProvider>{children}</InvoiceProvider>
      </UserProvider>
    </AuthProvider>
  );
}

export default AppContext;
