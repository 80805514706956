/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable import/prefer-default-export */
import React from 'react';
import { NumericFormat } from 'react-number-format';
import * as yup from 'yup';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import { yupResolver } from '@hookform/resolvers/yup';

import { Button, Input } from '../atoms';
import { Modal } from '../Modal';
import { requestMoney } from '../../context/Actions/user';

import { Close, Naira } from '../../icons';

const schema = yup.object().shape({
  amount: yup.number().required('Please enter amount'),
  destination: yup.string().required('Please enter destination'),
});

// eslint-disable-next-line react/prop-types
function RequestModal({ onClose }) {
  const {
    register,
    handleSubmit,
    setValue,
    // getValues,
    formState: { errors },
  } = useForm({ resolver: yupResolver(schema) });

  const handlePaymentRequest = async (data) => {
    try {
      const res = await requestMoney(data);
      toast.success(res.message);
      onClose();
    } catch (error) {
      toast.error('Something went wrong');
    }
  };
  const handleAmountChange = (values) => {
    const { value } = values;
    setValue('amount', value);
  };

  return (
    <Modal size="lg">
      <>
        <div className="sticky top-0 z-40 flex items-center justify-between px-3 py-6 bg-white border sm:px-5 border-brand-border">
          <p className="text-xl text-[#04004D] font-interMedium">
            Request Payment
          </p>
          <Close className="cursor-pointer" onClick={onClose} />
        </div>

        <div className="h-full px-3 py-8 sm:p-8 md:h-fit">
          <form onSubmit={handleSubmit(handlePaymentRequest)}>
            <div className="mb-6">
              <Input
                {...register('destination')}
                label="Source account"
                type="text"
                placeholder="Enter the user's number or squareme tag"
                borderColor="border-[#D0D5DD]"
                required
              />
            </div>
            <div className="mb-14">
              <label
                htmlFor="businessName"
                className="block mb-1.5 text-base tracking-tight font-inter text-[#344054] leading-5"
              >
                Amount
              </label>
              <div className="relative">
                <div className="absolute inset-y-0 left-0 flex items-center justify-center pl-3 pr-4 cursor-pointer">
                  <Naira />
                </div>

                <NumericFormat
                  thousandSeparator
                  decimalScale={2}
                  fixedDecimalScale
                  onValueChange={handleAmountChange}
                  prefix=""
                  className="border border-[#E5E7EB] text-base rounded-lg placeholder:text-[#B7BABF] placeholder:text-sm focus:border-[#3976E8] focus:ring-0 focus:outline-none block w-full md:px-[18px] pl-9 md:pl-9"
                />
              </div>
              <p className="text-brand-red">{errors.amount?.message}</p>
            </div>
            <div className="flex space-x-4">
              <Button
                type="submit"
                btnColor="default"
                label="Continue"
                size="block"
                custom
              />
            </div>
          </form>
        </div>
      </>
    </Modal>
  );
}

export default RequestModal;
