/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/forbid-prop-types */
/* eslint-disable react/require-default-props */
/* eslint-disable no-nested-ternary */
import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { isEmpty, isNil } from 'lodash';

export const Input = forwardRef(
  (
    {
      onChange,
      onBlur,
      label,
      type,
      placeholder,
      design,
      required,
      disabled,
      borderColor,
      icon,
      iconLeft,
      name,
      error,
      length,
      custom,
      truncate,
      inputWrapperClassName,
      labelClassName,
      className,
      iconLeftClassName,
      iconRightClassName,
      ...props
    },
    ref,
  ) => (
    <div className={clsx('mb-4', inputWrapperClassName)}>
      {!isNil(label) && !isEmpty(label) && (
        <label
          htmlFor={type}
          className={clsx(
            'block mb-4 text-[15px] tracking-tight font-inter text-[#344054] leading-[22.5px]',
            labelClassName,
          )}
        >
          {label}
        </label>
      )}
      <div className="relative">
        {icon ? (
          <div
            className={clsx(
              'absolute inset-y-0 right-0 flex items-center justify-center pr-3 cursor-pointer bg-transparent',
              iconRightClassName,
            )}
          >
            {icon}
          </div>
        ) : iconLeft ? (
          <div
            className={clsx(
              'absolute inset-y-0 left-0 flex items-center justify-center pl-3 pr-4 cursor-pointer',
              iconLeftClassName,
            )}
          >
            {iconLeft}
          </div>
        ) : (
          ''
        )}

        <input
          maxLength={length}
          name={name}
          ref={ref}
          onChange={onChange}
          onBlur={onBlur}
          type={type}
          id={type}
          className={clsx(
            'border text-[15px] leading-[22.5px] rounded-lg placeholder:text-[#B7BABF] placeholder:text-[15px]',
            'focus:border-[#3976E8] focus:ring-0 focus:outline-none block w-full md:px-[18px] h-[44px] md:h-[55px]',
            error ? 'border-brand-red focus:border-brand-red' : borderColor,
            iconLeft ? 'pl-9 md:pl-9' : '',
            custom ? 'py-4' : 'p-2.5 md:py-2.5',
            truncate ? 'truncate overflow-hidden' : '',
            disabled ? 'bg-gray-200 cursor-not-allowed' : '',
            className,
          )}
          placeholder={placeholder}
          required={required}
          disabled={disabled}
          {...props}
        />
      </div>

      {!isEmpty(error) && (
        <div>
          <p className="text-brand-red text-xs mb-2">{error}</p>
        </div>
      )}
    </div>
  ),
);

Input.propTypes = {
  className: PropTypes.string,
  label: PropTypes.string,
  labelClassName: PropTypes.string,
  type: PropTypes.any,
  placeholder: PropTypes.string,
  design: PropTypes.string,
  required: PropTypes.bool,
  disabled: PropTypes.bool,
  borderColor: PropTypes.any,
  icon: PropTypes.any,
  iconLeft: PropTypes.any,
  onChange: PropTypes.any,
  length: PropTypes.any,
  error: PropTypes.any,
  custom: PropTypes.bool,
  name: PropTypes.string,
  truncate: PropTypes.bool,
};

export default Input;
