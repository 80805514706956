/* eslint-disable operator-linebreak */
/* eslint-disable react-hooks/exhaustive-deps */
import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useLocalStorage } from 'usehooks-ts';
import { isEmpty, isEqual, isNil } from 'lodash';
import { toast } from 'react-toastify';

import storageName from '../../helpers/storageHelpers';
import { getUserDetails, getKycStatus } from '../Actions/user';
import { logout, verifyInvite } from '../Actions/auth';

const AuthContext = createContext();

function useAuth() {
  const [localUserDetails, setLocalUserDetails] = useLocalStorage(
    storageName.userDetails,
    JSON.stringify({}),
  );
  const [localKycDetails, setLocalKycsDetails] = useLocalStorage(
    storageName.kycDetails,
    JSON.stringify({}),
  );
  const [localAuthToken, setLocalAuthToken] = useLocalStorage(
    storageName.userToken,
    null,
  );
  // const [_, setLocalKycDraft] = useLocalStorage(
  //   storageName.business_creation,
  //   null,
  // );

  const [authToken, setAuthToken] = useState('');
  const [kycDetails, setKycDetails] = useState({});
  const [userDetails, setUserDetails] = useState({});
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const handleAuthenticateUser = async (showIsLoading = true) => {
    try {
      if (showIsLoading) {
        setIsLoading(true);
      }
      const [userDetailsRes, kycDetailsRes] = await Promise.all([
        getUserDetails(),
        getKycStatus(),
      ]);

      if (userDetailsRes?.data?.response === true) {
        if (!isEqual(userDetails, userDetailsRes?.data?.data?.user)) {
          setUserDetails(userDetailsRes?.data.data?.user);
          setLocalUserDetails(JSON.stringify(userDetailsRes?.data.data?.user));
        }
      }

      if (kycDetailsRes?.data?.response === true) {
        if (!isEqual(kycDetails, kycDetailsRes?.data?.data)) {
          setKycDetails(kycDetailsRes?.data?.data);
          setLocalKycsDetails(JSON.stringify(kycDetailsRes?.data?.data));
        }
      }

      setIsAuthenticated(true);
    } catch (error) {
      if (process.env.NODE_ENV !== 'production') {
        // eslint-disable-next-line no-console
        console.log('handleAuthenticateUser ======>>> error ', error);
      }
    } finally {
      setIsLoading(false);
    }
  };

  const handleSetIsLoading = (loadingValue) => setIsLoading(loadingValue);

  const handleRouteToLogin = () => window.location.replace('/');

  const signIn = async (res, callback = () => {}) => {
    try {
      if (res?.data?.response === true) {
        setAuthToken(res?.data?.data?.user?.token);
        setLocalAuthToken(res?.data?.data?.user?.token);

        if (typeof callback === 'function') {
          setTimeout(() => {
            callback();
          }, 500);
        }
      }
    } catch (e) {
      if (process.env.NODE_ENV === 'development') {
        // eslint-disable-next-line no-console
        console.log('signIn ====>> ', e);
      }
    }
  };

  const signUp = async (res, callback = () => {}) => {
    try {
      if (res?.data?.response === true) {
        setAuthToken(res?.data?.data?.user?.token);
        setLocalAuthToken(res?.data?.data?.user?.token);

        if (typeof callback === 'function') {
          setTimeout(() => {
            callback();
          }, 500);
        }
      }
    } catch (e) {
      if (process.env.NODE_ENV === 'development') {
        // eslint-disable-next-line no-console
        console.log('signIn ====>> ', e);
      }
    }
  };

  const handleVerifyInvite = async (
    payload,
    callback = () => {},
    showIsLoading = false,
  ) => {
    try {
      if (showIsLoading) {
        setIsLoading(true);
      }
      const res = await verifyInvite(payload);

      const { data } = res;

      if (typeof callback === 'function') {
        callback(null, data);
      }
    } catch (error) {
      if (process.env.NODE_ENV === 'development') {
        console.log('handleVerifyInvite ------->>> error =======>>>', error);
      }
      callback(error);
      // reset();
    } finally {
      setIsLoading(false);
    }
  };

  // const signOut = useCallback(() => {
  //   setAuthToken(null);
  //   setUserDetails(null);
  //   setKycDetails(null);
  //   localStorage.clear();
  // }, [authToken, userDetails]);

  const handleLogout = async (callback = () => {}, showIsLoading = false) => {
    try {
      if (showIsLoading) {
        setIsLoading(true);
      }

      const resp = await logout();

      const { data } = resp.data;
      callback?.(null, data);

      return resp;
    } catch (error) {
      callback?.(error);
      const { message } = error.response.data;
      if (process.env.NODE_ENV === 'development') {
        console.log('handleLogout ------->>> error =======>>>', message);
      }
      return Promise.reject(error);
    }
  };

  const clearUserSession = useCallback(
    async (callback, clearStorage = true) => {
      try {
        setIsLoading(true);
        if (clearStorage) {
          await localStorage.clear();
        } else {
        }
      } catch (e) {
        // const { message } = error?.response?.data;
        if (process.env.NODE_ENV === 'development') {
          console.log('handleLogout ------->>> error =======>>>', e);
        }
      } finally {
        setIsLoading(false);
        setAuthToken(null);
        setUserDetails(null);
        setKycDetails(null);

        if (typeof callback === 'function') {
          callback();
        }
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [authToken, kycDetails, userDetails],
  );

  const signOut = useCallback(
    async (action = 'logout', clearStorage = true, showAlertPrompt = true) => {
      if (action === 'logout') {
        await handleLogout(async () => {
          await clearUserSession(() => {
            setTimeout(() => {
              if (showAlertPrompt) {
                toast.info('session expired, Please login');
              }
              handleRouteToLogin();
            });
          }, clearStorage);
        });

        return;
      }

      if (action === 'signout') {
        if (
          showAlertPrompt &&
          // eslint-disable-next-line no-alert
          window.confirm(
            clearStorage ? 'Sign out' : 'Logout',
            clearStorage
              ? "You're signing out, are you sure?"
              : "You're leaving, are you sure?",
          )
        ) {
          if (clearStorage) {
            await handleLogout(async () => {
              await clearUserSession(() => {
                handleRouteToLogin();
              }, true);
            }, true);
            return;
          }
          await handleLogout(async () => {
            await clearUserSession(() => {
              handleRouteToLogin();
            }, false);
          }, true);
          return;
        }
        await handleLogout(async () => {
          await clearUserSession(() => {
            handleRouteToLogin();
          }, true);
        }, true);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [clearUserSession],
  );

  useEffect(() => {
    if (!isNil(authToken) && !isEmpty(authToken)) {
      (async () => {
        try {
          setIsLoading(true);
          await handleAuthenticateUser();
        } catch (error) {
          if (
            error?.response?.data?.status === 401 ||
            error?.response?.status === 401
          ) {
            await signOut();
          }
        } finally {
          setIsLoading(false);
        }
      })();
    }
  }, [authToken]);

  useEffect(() => {
    setUserDetails(JSON.parse(localUserDetails));
    setKycDetails(JSON.parse(localKycDetails));
    setAuthToken(localAuthToken);
  }, []);

  return useMemo(
    () => ({
      isLoading,
      signIn,
      signUp,
      authToken,
      isAuthenticated,
      userDetails,
      kycDetails,
      signOut,
      handleSetIsLoading,
      kycStatus: kycDetails?.kyc?.status,
      handleAuthenticateUser,
      handleVerifyInvite,
      // handleDraft,
    }),
    [
      authToken,
      isAuthenticated,
      kycDetails,
      signIn,
      signUp,
      userDetails,
      // handleDraft,
    ],
  );
}

// eslint-disable-next-line react/prop-types
export function AuthProvider({ children }) {
  const auth = useAuth();

  // eslint-disable-next-line react/jsx-filename-extension
  return <AuthContext.Provider value={auth}>{children}</AuthContext.Provider>;
}

export default function AuthConsumer() {
  return useContext(AuthContext);
}
