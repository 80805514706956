/* eslint-disable import/prefer-default-export */
import React from 'react';

// eslint-disable-next-line react/prop-types
export function SubHeader({ children }) {
  return (
    <div className="border-b-2 border-[#EDEDF2] lg:px-16 px-4 lg:mt-24 md:mt-10 md:-mx-16 -mx-4 sticky lg:top-20 bg-[#fbfbfb] z-10">
      {children}
    </div>
  );
}
