/* eslint-disable operator-linebreak */
import React, {
  createContext,
  useCallback,
  useContext,
  useMemo,
  useState,
} from 'react';
import { last, merge, toNumber, isEmpty, isNumber, some, isNil } from 'lodash';

function useProviderInvoice() {
  const [invoiceTitle, setInvoiceTitle] = useState('');
  const [invoiceDescription, setInvoiceDescription] = useState('');
  const [invoiceLogo, setInvoiceLogo] = useState('');
  const [recipientName, setRecipientName] = useState('');
  const [recipientEmail, setRecipientEmail] = useState('');
  const [invoiceNumber, setInvoiceNumber] = useState('');
  const [invoiceDate, setInvoiceDate] = useState('');
  const [invoiceDueDate, setInvoiceDueDate] = useState('');
  const [businessAddress, setBusinessAddress] = useState('');
  const [invoiceNotes, setInvoiceNotes] = useState('');
  const [invoicePaymentAccount, setInvoicePaymentAccount] = useState('');

  const [invoiceItems, setInvoiceItems] = useState([
    { item: null, quantity: 0, price: 0 },
  ]);

  const handleInvoiceItemsIsValid = some(
    invoiceItems,
    ({ item, quantity, price }) => {
      if (isNil(item) || isEmpty(item)) {
        return true;
      }
      if (isNil(quantity) || !isNumber(quantity) || toNumber(quantity) < 1) {
        return true;
      }
      if (isNil(price) || !isNumber(price) || toNumber(price) < 1) {
        return true;
      }
      return false;
    },
  );

  const disableInvoiceAction = useMemo(
    () => handleInvoiceItemsIsValid,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [invoiceItems],
  );

  const inVoiceSubTotal = useMemo(() => {
    const total = invoiceItems.reduce(
      (acc, curItem) =>
        // eslint-disable-next-line implicit-arrow-linebreak
        acc + toNumber(curItem.price) * toNumber(curItem.quantity),
      0,
    );
    return total;
  }, [invoiceItems]);

  function addInvoiceItem() {
    const lastItem = last(invoiceItems);
    setInvoiceItems((prevItems) => [
      ...prevItems,
      {
        ...lastItem,
      },
    ]);
  }

  const updateInvoiceItem = useCallback(
    (payload, id) => {
      const prevItems = [...invoiceItems];

      const currentInvoiceItem = prevItems[id];

      prevItems[id] = merge({}, currentInvoiceItem, payload);
      setInvoiceItems(prevItems);
    },
    [invoiceItems],
  );

  function deleteInvoiceItem(id = 2) {
    const newItems = [...invoiceItems];
    newItems.splice(id, 1);
    setInvoiceItems(newItems);
  }

  return useMemo(
    () => ({
      inVoiceSubTotal,
      invoiceItems,
      addInvoiceItem,
      updateInvoiceItem,
      deleteInvoiceItem,
      disableInvoiceAction,
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [invoiceItems, inVoiceSubTotal, disableInvoiceAction],
  );
}

const InvoiceContext = createContext({});

export function InvoiceProvider({ children }) {
  const invoice = useProviderInvoice();
  return (
    <InvoiceContext.Provider value={invoice}>
      {children}
    </InvoiceContext.Provider>
  );
}

export default function useInvoice() {
  return useContext(InvoiceContext);
}
