/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable react/button-has-type */
/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable import/prefer-default-export */
import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { useNavigate, useLocation } from 'react-router-dom';

export function PageNotFound() {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const path = useLocation();

  useEffect(() => {
    if (path.pathname === '/404') {
      setIsLoading(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Helmet>
        <title>FundR | PageNotFound</title>
      </Helmet>
      {isLoading ? (
        <>
          <div className="about">
            <a
              className="bg_links social portfolio"
              href="https://www.rafaelalucas.com"
              target="_blank"
              rel="noreferrer"
            >
              <span className="icon" />
            </a>
            <a
              className="bg_links social dribbble"
              href="https://dribbble.com/rafaelalucas"
              target="_blank"
              rel="noreferrer"
            >
              <span className="icon" />
            </a>
            <a
              className="bg_links social linkedin"
              href="https://www.linkedin.com/in/rafaelalucas/"
              target="_blank"
              rel="noreferrer"
            >
              <span className="icon" />
            </a>
            {/* eslint-disable-next-line jsx-a11y/anchor-has-content, jsx-a11y/anchor-is-valid */}
            <a
              aria-label="foo"
              className="bg_links logo"
              onClick={(e) => e.preventDefault()}
              href="#"
            />
          </div>

          <section className="wrapper">
            <div className="container">
              <div id="scene" className="scene" data-hover-only="false">
                <div className="circle" data-depth="1.2" />

                <div className="one" data-depth="0.9">
                  <div className="content">
                    <span className="piece" />
                    <span className="piece" />
                    <span className="piece" />
                  </div>
                </div>

                <div className="two" data-depth="0.60">
                  <div className="content">
                    <span className="piece" />
                    <span className="piece" />
                    <span className="piece" />
                  </div>
                </div>

                <div className="three" data-depth="0.40">
                  <div className="content">
                    <span className="piece" />
                    <span className="piece" />
                    <span className="piece" />
                  </div>
                </div>

                <p className="p404" data-depth="0.50">
                  404
                </p>
                <p className="p404" data-depth="0.10">
                  404
                </p>
              </div>

              <div className="text">
                <article>
                  <p>
                    Uh oh! Looks like you got lost.
                    <br />
                    Go back to the homepage if you dare!
                  </p>
                  <button
                    onClick={() => {
                      navigate('/dashboard');
                    }}
                  >
                    i dare!
                  </button>
                </article>
              </div>
            </div>
          </section>
        </>
      ) : (
        ''
      )}
    </>
  );
}
