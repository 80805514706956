/* eslint-disable react/prop-types */
// eslint is here check
import React, { useEffect } from 'react';
import { includes } from 'lodash';
import { Navigate, useLocation } from 'react-router-dom';
import { useAuth } from '../context';
import { Loader } from '../components';

export function RequireAuth({ children }) {
  const location = useLocation();
  const { isAuthenticated, isLoading, handleAuthenticateUser } = useAuth();

  useEffect(() => {
    (async () => {
      await handleAuthenticateUser();
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (isLoading === false) {
    if (!isAuthenticated) {
      return <Navigate to="/login" state={{ from: location.pathname }} />;
    }
    return children;
  }

  return <Loader isActive />;
}

export function RequireKYCAuth({ children }) {
  const { userDetails, kycDetails } = useAuth();

  const location = useLocation();

  if (!includes(location.pathname, ['settings'])) {
    if (userDetails?.business === null && kycDetails?.kyc === null) {
      return <Navigate to="/home" replace />;
    }

    if (userDetails?.business === null && kycDetails?.kyc) {
      if (kycDetails?.kyc?.status !== 'completed') {
        return <Navigate to="/home" replace />;
      }
    }

    if (userDetails?.business && kycDetails?.kyc?.status === 'pending') {
      if (includes(userDetails?.roles, 'owner')) {
        return <Navigate to="/home" replace />;
      }
    }
  }

  return children;
}

export function VerifyRole({ children }) {
  const { authToken } = useAuth();
  return !authToken ? <Navigate to="/login" replace /> : children;
}
