/* eslint-disable no-console */
/* eslint-disable wrap-iife */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable operator-linebreak */
/* eslint-disable object-curly-newline */

import { yupResolver } from '@hookform/resolvers/yup';
import { first, forEach, isEmpty, isNil, join, split } from 'lodash';
import React, { useEffect, useMemo, useState } from 'react';
import { useForm, useWatch } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import useFormPersist from 'react-hook-form-persist';
import * as yup from 'yup';
import { useDebounce } from 'usehooks-ts';
import clsx from 'clsx';
import { IoArrowBack } from 'react-icons/io5';
import { toast } from 'react-toastify';

import OnboardLayout from '../../layout/onboardingLayout';
import { Button, Input, Select } from '../../atoms/index';
import storageName from '../../../helpers/storageHelpers';
import { useAuth, useUser } from '../../../context';

const bvnRegex = new RegExp(process.env.REACT_APP_BVN_VALIDATION, 'g');
const nubanRegex = new RegExp(
  process.env.REACT_APP_BANK_ACCOUNT_VALIDATION,
  'g',
);

const schema = yup.object().shape({
  business_name: yup
    .string()
    .min(3, 'business name too short')
    .max(100, 'business name too long'),
  business_type: yup.string().oneOf(['no_registration']),
  business_address: yup.string(),
  // bvn: yup
  //   .string()
  //   .min(11, 'bvn too short')
  //   .max(11, 'bvn too long')
  //   .matches(bvnRegex, 'bvn is invalid'),
  business_account: yup.object({
    bank_code: yup
      .string()
      .min(3, 'bank code is too short')
      .max(6, 'bank code is too long'),
    bank_name: yup
      .string()
      .min(2, 'bank name is too short')
      .max(150, 'bank name is too long'),
    nuban: yup
      .string()
      .min(10, 'bank account too short')
      .max(10, 'bank account too long')
      .matches(nubanRegex, 'bank account is invalid'),
    account_name: yup
      .string()
      .min(2, 'bank account name is too short')
      .max(150, 'bank account name is too long'),
    has_account: yup.string(),
    bvn: yup
      .string()
      .min(11, 'bvn too short')
      .max(11, 'bvn too long')
      .matches(bvnRegex, 'bvn is invalid')
      .required('please enter bvn'),
  }),
});

function UnregisteredInfo() {
  const navigate = useNavigate();
  const { kycDetails } = useAuth();
  const {
    handleIsLoading,
    handleCreateBusinesskycDraft,
    isLoading,
    banks,
    handleValidateBankAccount,
    handleValidateBvn,
  } = useUser();
  const {
    control,
    register,
    handleSubmit,
    getValues,
    setValue,
    watch,
    setError,
    clearErrors,
    formState: { errors },
  } = useForm({ resolver: yupResolver(schema) });
  useFormPersist(storageName.business_creation, {
    watch,
    setValue,
    storage: window.localStorage,
  });

  const nuban = useWatch({
    control,
    name: 'business_account.nuban',
  });

  const bvn = useWatch({
    control,
    name: 'business_account.bvn',
  });

  const debouncedAccountNumberValue = useDebounce(nuban, 500);
  const [bankCodeValue, setBankCodeValue] = useState('');
  const debouncedBankCodeValue = useDebounce(bankCodeValue, 500);
  const debouncedBvnValue = useDebounce(bvn, 500);

  const handleBankCodeChange = (event) => {
    const { label, value } = first(event.target.selectedOptions);
    setBankCodeValue(value);
    setValue('business_account.bank_name', label);
    setValue('business_account.bank_code', value);
  };

  const handleBusinessSubmit = async (values) => {
    try {
      handleIsLoading(true);
      await handleCreateBusinesskycDraft(values, (error) => {
        if (error) {
          toast.error(
            error?.response?.data?.message ?? 'kyc information save failed',
          );
          return;
        }
        toast.success('kyc information saved successfully');
        navigate('/onboard/unregistered/documentation');
      });
    } catch (error) {
      toast.error(
        error?.response?.data?.message ?? 'kyc information save failed',
      );
      if (process.env.NODE_ENV === 'development') {
        console.log('handle unregistered information ======>>>  error', error);
      }
    } finally {
      handleIsLoading(false);
    }
  };

  const parsedBanks = useMemo(() => {
    const results = [];

    forEach(banks, (value) => {
      results.push({
        key: value.name,
        value: value.code,
      });
    });
    results.unshift({ key: 'Select Bank', value: '' });
    return results;
  }, [banks]);

  useEffect(() => {
    (async () => {
      const handleError = () => {
        setError('business_account.nuban', {
          message: 'bank account entered is invalid',
          type: 'manual',
        });
        setValue('business_account.account_name', null);
        // setIsDisabled(true);
      };
      try {
        if (
          !isEmpty(debouncedBankCodeValue) &&
          !isEmpty(debouncedAccountNumberValue) &&
          debouncedAccountNumberValue.length === 10
        ) {
          const data = {
            bank_code: debouncedBankCodeValue,
            nuban: debouncedAccountNumberValue,
          };
          handleIsLoading(true);
          await handleValidateBankAccount(data, (error, result) => {
            if (error) {
              handleError();
              return;
            }
            setValue(
              'business_account.account_name',
              result.data.account.account_name,
            );
            setValue('business_account.has_account', true);
            clearErrors('business_account.nuban');
          });
        }
      } catch (error) {
        handleError();
        if (process.env.NODE_ENV !== 'production') {
          console.log(
            'validate bank account ------->>> error ======>>> ',
            error,
          );
        }
      } finally {
        handleIsLoading(false);
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedBankCodeValue, debouncedAccountNumberValue]);

  // Validating bvn details
  useEffect(() => {
    (async () => {
      const handleError = () => {
        setError('bvn', { message: 'bvn entered is invalid', type: 'manual' });
        setValue('business_account.bvn', null);
        // setIsDisabled(true);
      };
      try {
        if (debouncedBvnValue && debouncedBvnValue.length === 11) {
          handleIsLoading(true);
          const data = {
            bvn: debouncedBvnValue,
          };

          await handleValidateBvn(data, (error, result) => {
            if (error) {
              handleError();
              return;
            }

            clearErrors('bvn');
          });
        }
      } catch (error) {
        handleError();
        if (process.env.NODE_ENV !== 'production') {
          console.log('validate bvn ------->>> error ======>>> ', error);
        }
      } finally {
        handleIsLoading(false);
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedBvnValue]);

  useEffect(() => {
    if (isNil(kycDetails?.kyc) || isEmpty(kycDetails?.kyc)) {
      return;
    }

    setValue('business_name', kycDetails?.kyc?.business_details?.business_name);
    setValue(
      'business_type',
      getValues('business_type') ??
        kycDetails?.kyc?.business_details?.business_type,
    );

    setValue('business_address', kycDetails?.kyc?.business_address);

    setValue(
      'business_account.account_name',
      kycDetails?.kyc?.bank_account?.account_name,
    );

    setValue('business_account.nuban', kycDetails?.kyc?.bank_account?.nuban);
    setValue(
      'business_account.bank_name',
      kycDetails?.kyc?.bank_account?.bank_name,
    );

    setValue(
      'business_account.bvn',
      kycDetails?.kyc?.business_details?.bvn_of_owner_or_directors
        ?.verificationValue ?? kycDetails?.kyc?.bank_account?.bvn,
    );

    setValue(
      'business_account.bank_code',
      kycDetails?.kyc?.bank_account?.bank_code,
    );
    setBankCodeValue(
      getValues('business_account.bank_code') ??
        kycDetails?.kyc?.bank_account?.bank_code,
    );

    setValue(
      'business_account.has_account',
      kycDetails?.kyc?.bank_account?.has_account,
    );

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <OnboardLayout>
      <div className="lg:px-16 pl-12 pr-8 xl:w-[65%] mx-auto">
        <div
          className="sm:mt-[5rem] mt-24 text-base text-[#292D32] font-interMedium flex items-center gap-x-2 lg:mb-5 mb-3 cursor-pointer"
          onClick={() => navigate('/home')}
          role="button"
          onKeyDown={() => {}}
          tabIndex={-1}
        >
          <IoArrowBack className={clsx('w-5 h-5 text-[#3976E8]')} />
          <span className="hidden md:block text-[#3976E8]">
            Back to Dashboard
          </span>
        </div>

        <div className="flex flex-col py-6 mx-2 xl:w[60rem]">
          <p className="lg:mb-10 mb-4 text-xl font-interSemiBold">
            Unregistered Business
          </p>
          <form onSubmit={handleSubmit(handleBusinessSubmit)}>
            <div className="lg:gap-y-2 grid">
              <Input
                {...register('business_name')}
                label="Name of Business"
                type="text"
                placeholder="Enter Your Business Name"
                borderColor="border-[#E6EAEE]"
                required
                error={errors.business_name?.message}
                custom
              />
              <Input
                {...register('business_address')}
                label="Business Address"
                type="text"
                placeholder="Enter Your Business Address"
                borderColor="border-[#E6EAEE]"
                required
                error={errors.business_address?.message}
                custom
              />
              <Input
                {...register('business_account.bvn')}
                length={11}
                label="Bank Verification Number (BVN)"
                type="text"
                placeholder="Enter Your Bvn"
                borderColor="border-[#E6EAEE]"
                required
                error={errors?.bvn?.message}
                custom
              />
              <Select
                onChange={handleBankCodeChange}
                value={bankCodeValue}
                placeholder="Select Bank"
                options={parsedBanks}
                required
                error={join(
                  split(errors.business_account?.bank_code?.message, '_'),
                  '',
                )}
              />
              <Input
                {...register('business_account.nuban')}
                type="text"
                placeholder="Account Number"
                borderColor="border-[#E6EAEE]"
                required
                length={10}
                autoComplete="off"
                error={join(
                  split(errors.business_account?.nuban?.message, '_'),
                  '',
                )}
                custom
              />
              {getValues('business_account.account_name') && (
                <Input
                  {...register('business_account.account_name')}
                  type="text"
                  placeholder="Account Name"
                  borderColor="border-[#E6EAEE]"
                  required
                  disabled
                  error={errors.business_account?.account_name?.message}
                />
              )}
            </div>
            <div className="mt-10">
              <Button
                loading={isLoading}
                // isDisabled={isDisabled}
                type="submit"
                label="Save and Continue"
                btnColor="default"
                size="block"
                className={clsx('h-[55px]')}
              />
            </div>
          </form>
        </div>
      </div>
    </OnboardLayout>
  );
}

export default UnregisteredInfo;
