/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable react/button-has-type */
/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable operator-linebreak */
/* eslint-disable react/no-array-index-key */
/* eslint-disable indent */
/* eslint-disable no-nested-ternary */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable no-console */
/* eslint-disable import/prefer-default-export */
/* eslint-disable object-curly-newline */
/*  eslint-disable react/no-unstable-nested-components */
/* eslint-disable implicit-arrow-linebreak */
/* eslint-disable function-paren-newline */
/* eslint-disable prefer-template */
import React, { useState } from 'react';
import { Helmet } from 'react-helmet';
// import { useLocation, useNavigate } from 'react-router-dom';
import moment from 'moment';
import { NumericFormat } from 'react-number-format';
import { Fade, Zoom } from 'react-reveal';
import { DateRangePicker } from 'react-dates';
import { isArray, isEmpty, isNil, omitBy } from 'lodash';
import clsx from 'clsx';
import { PiCloudArrowUpLight, PiCalendarDots } from 'react-icons/pi';
import { BsCloudCheck } from 'react-icons/bs';
import { useToggle } from 'usehooks-ts';
import { Rings } from 'react-loader-spinner';

// import DatePicker from 'react-datepicker';
// import DataTable from 'react-data-table-component';

import DashboardLayout from '../components/layout/dashboardLayout';
import { useUser, useAuth } from '../context';
import { Close } from '../icons';

export function Transactions() {
  const { transactions: transactionDetails, handleGetTransactionExportCsv } =
    useUser();
  const { userDetails } = useAuth();

  const [isExported, toggleIsExported, setIsExported] = useToggle(false);
  const [isUploading, toggleIsUploading, setIsUploading] = useToggle(false);

  const [filter, setFilter] = useState({
    // page should be 1, per_page should be 15
    page: '',
    start_date: '',
    end_date: '',
    per_page: '',
  });
  // const navigate = useNavigate();
  // const location = useLocation();
  const [page, setPage] = useState(0);
  const rowsPerPage = 10; // Change this value as per your requirement
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [focusedInput, setFocusedInput] = useState(null);

  const isOutsideRange = (...args) => {
    const momentz = args[0];
    return momentz.isAfter(new Date());
  };

  const columns = [
    {
      name: 'Amount',
      field: 'amount',
    },
    {
      name: 'Description',
      field: 'description',
    },
    {
      name: 'Transaction Type',
      field: 'type',
    },
    {
      name: 'Date',
      field: 'created_at',
    },
    {
      name: 'Time',
      field: 'created_at',
    },
    {
      name: 'Status',
      field: 'status',
    },
    // Add more columns here
  ];

  const getTdContent = (columnField, row, rowIndex, colIndex) => {
    switch (columnField) {
      case 'amount':
        return (
          <td
            key={`${rowIndex}-${colIndex}`}
            className="px-6 py-4 whitespace-nowrap text-sm text-black capitalize"
          >
            <NumericFormat
              value={row[columnField]}
              decimalSeparator="."
              thousandSeparator
              displayType="text"
              prefix="₦"
            />
          </td>
        );
      case 'created_at':
        if (colIndex === 3) {
          // assuming date column is the first column
          return (
            <td
              key={`${rowIndex}-${colIndex}`}
              className="px-6 py-4 whitespace-nowrap text-sm text-gray-500"
            >
              {moment(row[columnField]).format('MMM Do YYYY')}
            </td>
          );
        }
        if (colIndex === 4) {
          // assuming time column is the second column
          return (
            <td
              key={`${rowIndex}-${colIndex}`}
              className="px-6 py-4 whitespace-nowrap text-sm text-gray-500"
            >
              {moment(row[columnField]).format('h:mm a')}
            </td>
          );
        }
        break;
      case 'status':
        return (
          <td
            key={`${rowIndex}-${colIndex}`}
            className="px-6 py-4 whitespace-nowrap text-sm text-green-500"
          >
            {row[columnField] === 'completed' ? (
              <div className="flex gap-1 justify-between px-3 py-1 whitespace-nowrap bg-[#EFFDED] border border-solid border-[color:var(--Side-colors-Green,#5DC090)] rounded-[100px] text-[#144909] max-w-[120px]">
                <div className="my-auto mr-2 w-2 h-2 bg-[#92EF80] rounded-full" />
                <div className="capitalize">{row[columnField]}</div>
              </div>
            ) : row[columnField] === 'failed' ? (
              <div className="flex gap-1 justify-between px-3 py-1 whitespace-nowrap bg-[#FEECEE] border border-solid border-[color:var(--Side-colors-Red,#F14156)] rounded-[100px] text-[#740613] max-w-[120px]">
                <div className="my-auto mr-2 w-2 h-2 bg-[#F14156] rounded-full" />
                <div className="capitalize">{row[columnField]}</div>
              </div>
            ) : (
              <div className="flex gap-1 justify-between px-3 py-1 whitespace-nowrap bg-yellow-50 border border-solid border-[color:var(--Side-colors-Orange,#F79009)] rounded-[100px] text-[#DC6803] max-w-[120px]">
                <div className="my-auto mr-2 w-2 h-2 bg-[#F79009] rounded-full" />
                <div className="capitalize">{row[columnField]}</div>
              </div>
            )}
          </td>
        );
      default:
        return (
          <td
            key={`${rowIndex}-${colIndex}`}
            className="px-6 py-4 whitespace-nowrap text-sm text-[#535379] capitalize"
          >
            {row[columnField]}
          </td>
        );
    }
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  function handleGenerateCsvFile(csvData) {
    const blob = new Blob([csvData], { type: 'text/csv' });
    return blob;
  }

  function downloadCsvFile(blob, filename) {
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.style.display = 'none';
    a.href = url;
    a.download = filename;
    document.body.appendChild(a);
    a.click();
    window.URL.revokeObjectURL(url);
    document.body.removeChild(a);
  }

  const handleExportTransactionsHistory = async () => {
    try {
      const params = omitBy(filter, (value) => isNil(value) || isEmpty(value));

      toggleIsUploading();
      await handleGetTransactionExportCsv(
        userDetails.business?.id,
        {
          params,
        },
        (error, result) => {
          if (error) {
            return;
          }
          // Step 2: Generate the CSV file
          const csvFile = handleGenerateCsvFile(result);

          // Step 3: Serve the CSV file for download
          downloadCsvFile(csvFile, 'fundr_transaction.csv');

          setIsExported(true);
        },
      );
    } catch (error) {
      if (process.env.NODE_ENV === 'development') {
        console.log(
          'handleExportTransactionsHistory ------->>> error =======>>>',
          error,
        );
      }
    } finally {
      setIsUploading(false);
      setTimeout(() => {
        toggleIsExported();
      }, 5000);
    }
  };

  return (
    <DashboardLayout>
      <Helmet>
        <title>FundR | Transactions</title>
      </Helmet>
      <div className="w-full px-4 lg:px-0 lg:pl-8 lg:pr-20  pt-4 pb-20 lg:pb-8">
        <div className="flex flex-col mt-9 max-md:max-w-full">
          <div className="flex gap-5 justify-between self-center w-full font-medium whitespace-nowrap  max-md:flex-wrap mb-3 lg:mb-0">
            <div className="flex gap-1.5 px-5 my-auto text-base leading-6 text-zinc-800">
              <div className="grow">All Accounts</div>
              <img
                alt=""
                loading="lazy"
                src="https://cdn.builder.io/api/v1/image/assets/TEMP/3274cef3a335e38accf099a186681e3911264f5cfd6ccdb61dfcdf0b76f74d9c?apiKey=bb278f95022541509fa4b766a9320e21&"
                className="my-auto aspect-square w-[18px]"
              />
            </div>

            {!isExported && (
              <div
                className={clsx(
                  ' lg:hidden gap-2 text-sm leading-5 bg-white rounded-lg border border-solid shadow-sm border-[color:var(--Gray-300,#D0D5DD)] text-slate-700  hover:text-white',
                  {
                    'cursor-pointer hover:bg-blue-500': !isUploading,
                    'cursor-progress': isUploading,
                  },
                )}
                onClick={handleExportTransactionsHistory}
              >
                {!isUploading && (
                  <div className="flex justify-around items-center h-10 w-28 px-2">
                    <div className="w-6 h-6 flex justify-center items-center">
                      <PiCloudArrowUpLight
                        loading="lazy"
                        className="self-start w-full h-full aspect-square"
                      />
                    </div>
                    <div className="flex justify-center items-center">
                      <p>Export</p>
                    </div>
                  </div>
                )}

                {isUploading && (
                  <div className="flex justify-around items-center h-10 w-28 px-2">
                    <Rings
                      visible
                      height="40"
                      width="40"
                      color="#9ca3af"
                      ariaLabel="rings-loading"
                      wrapperStyle={{}}
                      wrapperClass=""
                    />
                  </div>
                )}
              </div>
            )}
            {isExported && (
              <div className="cursor-not-allowed  lg:hidden gap-2 justify-between items-center  text-sm leading-5 bg-white rounded-lg border border-solid shadow-sm border-[color:var(--Gray-300,#D0D5DD)] text-slate-700 text-white bg-green-500 w-28">
                <div className="flex justify-around items-center h-10 w-28 px-2">
                  <div className="w-6 h-6 flex justify-center items-center">
                    <BsCloudCheck
                      loading="lazy"
                      className="self-start w-full h-full aspect-square"
                    />
                  </div>
                  <div className="flex justify-center items-center">
                    <p>Exported</p>
                  </div>
                </div>
              </div>
            )}
            <div className="flex gap-4 justify-between max-md:flex-wrap max-md:max-w-full">
              <div className="flex flex-col lg:flex-row gap-1 lg:gap-3 justify-between items-center px-5 text-zinc-500 w-full">
                <div className="grow self-start text-sm flex justify-center items-center h-full">
                  <p>Select Date Range:</p>
                </div>
                <div className="flex gap-1 justify-between items-center p-1 text-sm leading-5 bg-white rounded-lg border border-solid border-[color:var(--Border-bd-dark,#DADAE7)]">
                  <div
                    className={clsx(
                      'h-full flex justify-center items-center px-2',
                    )}
                  >
                    <div className="w-6 h-6 flex justify-center items-center">
                      <PiCalendarDots
                        loading="lazy"
                        className="self-start w-full h-full aspect-square"
                      />
                    </div>
                  </div>
                  <div className="lg:text-base text-xs">
                    <div className="h-full w-full">
                      <DateRangePicker
                        startDate={startDate}
                        endDate={endDate}
                        startDateId="transaction_history_start_date_id"
                        endDateId="transaction_history_end_date_id"
                        onDatesChange={({ startDate, endDate }) => {
                          setStartDate(startDate);
                          setEndDate(endDate);
                          setFilter({
                            ...filter,
                            ...(startDate && {
                              start_date:
                                moment(startDate).format('YYYY-MM-DD'),
                            }),
                            ...(endDate && {
                              end_date: moment(endDate).format('YYYY-MM-DD'),
                            }),
                          });
                        }}
                        focusedInput={focusedInput}
                        onFocusChange={(focusedInput) =>
                          setFocusedInput(focusedInput)
                        }
                        isOutsideRange={isOutsideRange}
                        displayFormat="MMM D, YYYY"
                        numberOfMonths={1}
                        noBorder
                        small
                        showClearDates
                        customCloseIcon={<Close className="w-4 m-0" />}
                        enableOutsideDays
                      />
                    </div>
                  </div>
                </div>
              </div>
              {!isExported && (
                <div
                  className={clsx(
                    'hidden lg:flex gap-2 text-sm leading-5 bg-white rounded-lg border border-solid shadow-sm border-[color:var(--Gray-300,#D0D5DD)] text-slate-700  hover:text-white',
                    {
                      'cursor-pointer hover:bg-blue-500': !isUploading,
                      'cursor-progress': isUploading,
                    },
                  )}
                  onClick={handleExportTransactionsHistory}
                >
                  {!isUploading && (
                    <div className="flex justify-around items-center h-full w-28 px-2">
                      <div className="w-6 h-6 flex justify-center items-center">
                        <PiCloudArrowUpLight
                          loading="lazy"
                          className="self-start w-full h-full aspect-square"
                        />
                      </div>
                      <div className="flex justify-center items-center">
                        <p>Export</p>
                      </div>
                    </div>
                  )}

                  {isUploading && (
                    <div className="flex justify-around items-center h-full w-28 px-2">
                      <Rings
                        visible
                        height="40"
                        width="40"
                        color="#9ca3af"
                        ariaLabel="rings-loading"
                        wrapperStyle={{}}
                        wrapperClass=""
                      />
                    </div>
                  )}
                </div>
              )}
              {isExported && (
                <div className="cursor-not-allowed hidden lg:flex gap-2 justify-between items-center  text-sm leading-5 bg-white rounded-lg border border-solid shadow-sm border-[color:var(--Gray-300,#D0D5DD)] text-slate-700 text-white bg-green-500 w-28">
                  <div className="flex justify-around items-center h-full w-28 px-2">
                    <div className="w-6 h-6 flex justify-center items-center">
                      <BsCloudCheck
                        loading="lazy"
                        className="self-start w-full h-full aspect-square"
                      />
                    </div>
                    <div className="flex justify-center items-center">
                      <p>Exported</p>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
          <Zoom>
            <div className="overflow-x-auto hidden lg:block border  border-gray-200 rounded-lg lg:mt-14 mt-5">
              <table className="min-w-full divide-y divide-gray-200 ">
                <thead className="bg-gray-50 h-16">
                  <tr>
                    {/* Render checkbox in the first column of the table head */}
                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      <input type="checkbox" className="form-checkbox" />
                    </th>

                    {columns.map((column) => (
                      <th
                        key={column.field}
                        className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                      >
                        {column.name}
                      </th>
                    ))}
                  </tr>
                </thead>
                <tbody className="bg-white divide-y divide-gray-200 ">
                  {isArray(transactionDetails) &&
                    !isEmpty(transactionDetails) &&
                    transactionDetails
                      .slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage,
                      )
                      .map((row, rowIndex) => (
                        <tr
                          key={rowIndex}
                          className={
                            rowIndex % 2 === 0
                              ? ' bg-white cursor-pointer'
                              : 'bg-white/70 cursor-pointer'
                          }
                          // onClick={openTransactionDetails(row)}
                        >
                          {/* Render checkbox in the first column of each table row */}
                          <td className="px-6 py-4 whitespace-nowrap text-sm text-red-500">
                            <input
                              type="checkbox"
                              className="form-checkbox rounded-sm"
                            />
                          </td>
                          {columns.map((column, colIndex) =>
                            getTdContent(column.field, row, rowIndex, colIndex),
                          )}
                        </tr>
                      ))}
                </tbody>
              </table>
            </div>
          </Zoom>
          <Zoom>
            <div className="lg:hidden">
              {isArray(transactionDetails) &&
                !isEmpty(transactionDetails) &&
                transactionDetails
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row, rowIndex) => (
                    <div className="mb-4 border border-[#BEBEBE33] rounded flex gap-5 justify-between flex-wrap py-4 pr-14 pl-6 bg-white  max-md:flex-wrap max-md:px-5 max-md:max-w-full">
                      <div className="border-b border-[#BEBEBE33] flex justify-between items-center w-full">
                        <p className="grow self-start  text-black ">
                          AMOUNT :{' '}
                        </p>
                        <p className="text-right">
                          {' '}
                          <NumericFormat
                            value={row.amount}
                            decimalSeparator="."
                            thousandSeparator
                            displayType="text"
                            prefix="₦"
                          />
                        </p>
                      </div>
                      <div className="border-b border-[#BEBEBE33] flex justify-between items-center w-full capitalize">
                        <p className="grow self-start  text-black ">
                          TRANSACTION TYPE :{' '}
                        </p>
                        <p className="text-right">{row.type}</p>
                      </div>
                      <div className="border-b border-[#BEBEBE33] flex justify-between items-center w-full">
                        <p className="grow self-start  text-black ">DATE : </p>
                        <p className="text-right">
                          {moment(row.created_at).format('MMM Do YYYY')}
                        </p>
                      </div>
                      <div className="border-b border-[#BEBEBE33] flex justify-between items-center w-full">
                        <p className="grow self-start  text-black ">TIME : </p>
                        <p className="text-right">
                          {moment(row.created_at).format('h:mm a')}
                        </p>
                      </div>
                      <div className=" flex justify-between items-center w-full">
                        <div className="grow self-start  text-black ">
                          STATUS :{' '}
                        </div>
                        <div className="flex gap-1 justify-between px-3 py-1 whitespace-nowrap bg-green-50 border border-solid border-[color:var(--Side-colors-Green,#5DC090)] rounded-[100px] text-lime-950">
                          <div className="my-auto w-2 h-2 bg-green-300 rounded-full" />
                          <div className="grow">{row.status}</div>
                        </div>
                      </div>
                    </div>
                  ))}
            </div>
          </Zoom>

          <Fade right>
            <div className="flex gap-5 justify-between self-center mt-3 w-full max-w-[1053px] max-md:flex-wrap max-md:max-w-full mb-6">
              {/* Show number of results */}
              <div className="flex-auto my-auto text-slate-500 text-sm">
                Showing{' '}
                {Math.min(
                  (page + 1) * rowsPerPage,
                  transactionDetails?.length ?? 0,
                )}{' '}
                of {transactionDetails?.length ?? 0} results
              </div>

              <div className="flex gap-2 text-center whitespace-nowrap text-slate-600">
                <button
                  onClick={() => handleChangePage(null, page - 1)}
                  disabled={page === 0}
                >
                  <img
                    loading="lazy"
                    src="https://cdn.builder.io/api/v1/image/assets/TEMP/4a89813b313caef866b316d5943f6760d234b7e20e9b9becd54879ffd7ef2bc6?apiKey=bb278f95022541509fa4b766a9320e21&"
                    className="w-8 aspect-square"
                    alt="left"
                  />
                </button>
                {Array.from(
                  Array(
                    Math.ceil((transactionDetails?.length ?? 0) / rowsPerPage),
                  ).keys(),
                ).map((pageNumber) => (
                  <button
                    className={`justify-center items-center h-8 ${
                      page === pageNumber
                        ? 'text-blue-600 border-[color:var(--Main-blue,#1F62FF)]'
                        : 'border-[color:var(--Border-bd-dark,#DADAE7)]'
                    }  bg-white rounded border border-solid aspect-square `}
                    key={pageNumber}
                    onClick={() => setPage(pageNumber)}
                    disabled={page === pageNumber}
                  >
                    {pageNumber + 1}
                  </button>
                ))}

                <button
                  onClick={() => handleChangePage(null, page + 1)}
                  disabled={
                    page ===
                    Math.ceil((transactionDetails?.length ?? 0) / rowsPerPage) -
                      1
                  }
                >
                  <img
                    loading="lazy"
                    src="https://cdn.builder.io/api/v1/image/assets/TEMP/5865f0336c20abccc3587c9ba3d59bd337ea221a3d15684a7ea27b73d438917e?apiKey=bb278f95022541509fa4b766a9320e21&"
                    className="w-8 aspect-square"
                    alt="right"
                  />
                </button>
              </div>
            </div>
          </Fade>
        </div>
      </div>
    </DashboardLayout>
  );
}
