/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable consistent-return */
/* eslint-disable no-shadow */
/* eslint-disable object-curly-newline */
/* eslint-disable implicit-arrow-linebreak */
/* eslint-disable react/jsx-curly-newline */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable no-inner-declarations */
/* eslint-disable no-unused-vars */
/* eslint-disable no-console */
/* eslint-disable no-confusing-arrow */
/* eslint-disable import/prefer-default-export */
import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { useForm } from 'react-hook-form';
import useFormPersist from 'react-hook-form-persist';
import { Link, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

import isEmail from 'validator/lib/isEmail';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import OtpInput from 'react-otp-input';

import { Show, Hide, Close } from '../icons';
import { Button, Card, Text, Input } from '../components/atoms';
import { useAuth } from '../context';
import { signUpOtp, registerUser } from '../context/Actions/auth';
// import { verifyInvite } from '../context/Actions/user';

import { Modal } from '../components/Modal';
import { AuthLayout } from '../components/layout/authLayout';

// eslint-disable-next-line operator-linebreak
const phoneRegExp = new RegExp(process.env.REACT_APP_PHONE_VALIDATION, 'g');
const symbols = new RegExp(process.env.REACT_APP_PASSWORD_VALIDATION, 'g');

const schema = yup.object().shape({
  firstName: yup
    .string()
    .min(2, 'First Name must be at least 2 character long')
    .required('First Name is required'),
  lastName: yup
    .string()
    .min(2, 'Last Name must be at least 2 character long')
    .required('Last Name is required'),
  phone: yup
    .string()
    .matches(phoneRegExp, process.env.REACT_APP_PHONE_VALIDATION_MESSAGE)
    .required('Phone number is required'),
  referralId: yup.string(),
  role: yup.string(),
  agreeToc: yup
    .bool()
    .oneOf([true], 'You must accept the terms and conditions')
    .required(),
  // business_id: yup.string(),
  email: yup
    .string()
    .email('Email must be a valid email')
    .required('Email is required')
    .test(
      'is-valid',
      (message) => `${message.path} is invalid`,
      (value) =>
        value ? isEmail(value) : new yup.ValidationError('Invalid value'),
    ),
  password: yup
    .string()
    .required('Please enter a password')
    .min(8, 'Password too short')
    .max(24, 'Password too long')
    .matches(symbols, process.env.REACT_APP_PASSWORD_VALIDATION_MESSAGE),
  // otp: yup.string().required("OTP is required"),
});

export function Register() {
  const queryParams = new URLSearchParams(window.location.search);
  const [inviteToken, setInviteToken] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [showPassword, setShowpassword] = useState(false);
  const [enabled, setEnableValue] = useState(false);
  const [validate, setValidate] = useState(false);
  const [otpCode, setOtpCode] = useState('');
  const [userDetails, setUserDetails] = useState('');
  // const [invite_token, setInviteToken]
  const { signUp, handleVerifyInvite } = useAuth();
  const navigate = useNavigate();

  const {
    register,
    handleSubmit,
    setValue,
    watch,
    formState: { errors },
  } = useForm({ resolver: yupResolver(schema), mode: 'onChange' });
  useFormPersist('registerValue', {
    watch,
    setValue,
    storage: window.localStorage,
  });

  // send otp
  const setInfo = async (data) => {
    try {
      setIsLoading(true);
      setUserDetails(data);
      const res = await signUpOtp({ email: data.email });
      if (res.data.response === true) {
        toast.success(res.data.message);
      }
      setValidate(true);
    } catch (error) {
      if (process.env.NODE_ENV === 'development') {
        console.log('login--> error', error);
      }
    } finally {
      setIsLoading(false);
    }
  };

  // Registration
  const handleRegistration = async (code) => {
    setOtpCode(code);
    const redirect = queryParams.get('redirect_url');
    if (code.length === 5) {
      try {
        const userInfo = {
          ...userDetails,
          otp: code,
        };
        const res = await registerUser(userInfo);

        await signUp(res, () => {
          toast.success(res?.data?.message);
          localStorage.removeItem('registerValue');
          if (res.data?.response === true) {
            if (redirect === 'invite') return navigate('/settings?tab=2');
            navigate('/home');
          }
        });
      } catch (error) {
        if (process.env.NODE_ENV !== 'production') {
          console.log('sign up--> error', error);
        }
      } finally {
        setIsLoading(false);
      }
    }
  };

  // resend otp
  const handleOtpResend = async (data) => {
    try {
      const res = await signUpOtp({ email: userDetails.email });
      if (res.data.response === true) {
        toast.success(res.data.message);
      }
    } catch (error) {
      if (process.env.NODE_ENV === 'development') {
        console.log('login--> error', error);
      }
    }
  };

  // TOC
  const setEnabled = () => {
    setValue('agreeToc', !enabled);
  };
  const onClose = () => {
    setValidate(false);
  };

  useEffect(() => {
    setInviteToken(queryParams.get('invite'));
  }, []);

  // verify invite token
  useEffect(() => {
    try {
      // setInviteToken(queryParams.get('invite'));

      if (inviteToken) {
        // async function verifyUserInvite() {
        //   const res = await verifyInvite({ invite_token: inviteToken });
        //   setValue('email', res.data.user.email);
        // }
        // verifyUserInvite();

        (async () => {
          await handleVerifyInvite(inviteToken, (error, result) => {
            if (error) {
              return;
            }
            setValue('email', result.data.user.email);
          });
        })();
      }
    } catch (err) {
      toast.error('Something went wrong');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inviteToken, setValue]);

  return (
    <AuthLayout>
      <Helmet>
        <title>FundR | Register</title>
      </Helmet>
      <Card backgroundColor="white">
        <Text
          textColor="#04004D"
          design="flex justify-center md:text-xl text-lg font-interMedium md:font-interSemiBold md:mb-6 mb-2"
        >
          Create an account
        </Text>
        <form onSubmit={handleSubmit(setInfo)}>
          <Input
            {...register('firstName')}
            label="First Name"
            type="text"
            placeholder="Jane"
            borderColor="border-[#E6EAEE]"
            required
            error={errors.firstName?.message}
          />

          <Input
            {...register('lastName')}
            label="Last Name"
            type="text"
            placeholder="Doe"
            borderColor="border-[#E6EAEE]"
            required
            error={errors.lastName?.message}
          />

          <Input
            {...register('email')}
            label="Work Email"
            type="email"
            placeholder="Janedoe@gmail.com"
            borderColor="border-[#E6EAEE]"
            required
            error={errors.email?.message}
          />

          <Input
            {...register('phone')}
            label="Phone Number"
            type="phone"
            placeholder="Phone Number"
            borderColor="border-[#E6EAEE]"
            required
            error={errors.phone?.message}
          />

          <Input
            {...register('password')}
            label="Password"
            type={showPassword ? 'text' : 'password'}
            placeholder="Enter password"
            borderColor="border-[#E6EAEE]"
            required
            error={errors.password?.message}
            icon={
              !showPassword ? (
                <Show
                  className="mr-2"
                  onClick={() => {
                    setShowpassword(true);
                  }}
                />
              ) : (
                <Hide
                  className="mr-2"
                  onClick={() => {
                    setShowpassword(false);
                  }}
                />
              )
            }
          />

          <Input
            {...register('referralId')}
            label="Referral ID (Optional)"
            type="text"
            placeholder="Enter referral ID"
            borderColor="border-[#E6EAEE]"
          />
          <div
            onClick={() => {
              setEnableValue(!enabled);
              setEnabled();
            }}
            className="flex items-center gap-x-1"
          >
            <input
              type="checkbox"
              checked={enabled}
              readOnly
              className="border-[#A8A8A8] rounded-sm checked:border-[#3976E8] checked:bg-[#3976E8]"
            />
            <p
              className={[
                errors.agreeToc?.message ? 'text-brand-red' : 'text-[#70747E]',
                'text-sm lg:text-base',
              ].join(' ')}
            >
              I have agreed to Terms of Service and Conditions
            </p>
          </div>
          <div className="mt-6 mb-3">
            <Button
              loading={isLoading}
              isDisabled={isLoading}
              type="submit"
              label="Sign Up"
              btnColor="default"
              size="block"
              custom
            />
          </div>
        </form>
        <div>
          <Text
            textColor="#04004D"
            design="flex justify-center text-xs font-inter whitespace-nowrap"
          >
            Already have an account?
            <Link to="/login">
              <span className="text-[#3976E8] ml-1">Login</span>
            </Link>
          </Text>
        </div>
      </Card>

      {validate ? (
        <Modal size="md">
          <div className="p-5">
            <div
              className="flex justify-end pt-2 pr-3 cursor-pointer"
              onClick={onClose}
              role="button"
              tabIndex={-1}
              onKeyDown={() => {}}
            >
              <Close />
            </div>
            <Text
              textColor="#04004D"
              design="flex justify-center md:text-xl text-lg font-interMedium md:font-interSemiBold md:mb-5 mb-4"
            >
              Enter OTP
            </Text>
            <Text
              textColor="#70747E"
              design="flex justify-center md:text-[15px] text-xs font-inter md:mb-8 mb-4 leading-5"
            >
              Please input the five digit code that was sent to your provided
              email: {userDetails.email}
            </Text>
            <div className="flex justify-center">
              <OtpInput
                value={otpCode}
                onChange={handleRegistration}
                numInputs={5}
                renderSeparator={<span style={{ width: '13px' }} />}
                renderInput={(props) => <input {...props} />}
                shouldAutoFocus
                inputStyle={{
                  border: '1px solid #E6EAEE',
                  borderRadius: '8px',
                  width: '50px',
                  height: '48px',
                  fontSize: '16px',
                  color: '#000',
                  fontWeight: '400',
                  caretColor: 'black',
                  boxShadow: '0px 1px 2px 0px rgba(16, 24, 40, 0.05)',
                }}
                focusStyle={{
                  border: '1px solid #CFD3DB',
                  outline: 'none',
                }}
              />
            </div>
            <Text
              textColor="#04004D"
              design="flex justify-center text-sm font-inter mb-2 mt-7"
            >
              Didn’t receive code?{' '}
              <span
                className="text-[#3976E8] cursor-pointer ml-1"
                onClick={() =>
                  // eslint-disable-next-line implicit-arrow-linebreak
                  handleOtpResend(JSON.parse(localStorage.getItem('email')))
                }
              >
                Resend
              </span>
            </Text>
            {/* <div className="mt-5">
            <Button
              loading={isLoading}
              isDisabled={isLoading}
              type="submit"
              label="Confirm OTP"
              btnColor="default"
              size="block"
              custom
            />
          </div> */}
          </div>
        </Modal>
      ) : (
        ''
      )}
    </AuthLayout>
  );
}
