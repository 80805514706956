/* eslint-disable react/no-array-index-key */
/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable operator-linebreak */
/* eslint-disable no-console */
/* eslint-disable no-inner-declarations */
/* eslint-disable import/prefer-default-export */
import React, { useState } from 'react';
// import { first } from 'lodash';
import DataTable from 'react-data-table-component';
// import { getTeamMembers } from '../../context/Actions/user';
import { Loader } from '../Loader';
import { useUser } from '../../context';

export function Team() {
  const { businessTeamMembers: members, isLoading: pageLoading } = useUser();

  const columns = [
    { name: 'ID', selector: (row) => row.id, sortable: true },
    {
      name: 'Name',
      selector: (row) => row.member.user.first_name,
      sortable: true,
    },
    {
      name: 'Role',
      selector: (row) => row.member.user.roles,
      cell: (row) =>
        // eslint-disable-next-line implicit-arrow-linebreak
        row.member.roles.map((item, i) => (
          <p key={i} className="capitalize justify-center mx-1">
            {item.role.role.split('_').join(' ')}
          </p>
        )),
      style: {},
      sortable: true,
    },
    {
      name: 'Email Address',
      selector: (row) => row.member.user.email,
      sortable: true,
    },
    // { name: 'Last Login', selector: 'age', sortable: true },
    // { name: 'Action', selector: '', sortable: true },
    // Add more columns here
  ];

  return (
    <>
      <Loader isActive={pageLoading} />
      <div className="mt-[60px]">
        <DataTable columns={columns} data={members} pagination />
      </div>
    </>
  );
}
