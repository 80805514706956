/* eslint-disable operator-linebreak */
/* eslint-disable react/prop-types */
/* eslint-disable react/require-default-props */
/* eslint-disable react/forbid-prop-types */
/* eslint-disable react/button-has-type */
/* eslint-disable react/default-props-match-prop-types */
/* eslint-disable import/prefer-default-export */
import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { isArray, isEmpty, isNil, isNumber, toNumber } from 'lodash';

export function Tab({
  customClass,
  numberOfTabs,
  currentTab,
  hidden,
  borderColor,
  children,
  className,
  titleClassName,
  activeTitleClassName,
  counts = [],
  activeClassName,
  countClassName,
  countActiveClassName,
  ...props
}) {
  const toggleTabs = (tabNumber) => {
    props.changeTab(tabNumber);
  };

  return (
    <main className="flex flex-col h-full fixe top-[42px]">
      <div
        className={[
          hidden ? 'hidden md:block' : 'md:block',
          'mb-4 border-b border-gray-200',
        ]}
      >
        <ul
          className="flex items-center overflow-x-scroll text-sm font-medium whitespace-nowrap scrollbar-hide snap-proximity snap-center md:px-10"
          id="myTab"
          data-tabs-toggle="#myTabContent"
          role="tablist"
        >
          {numberOfTabs.map((tab, index) => (
            <li key={Math.random() * Math.random()} className="">
              <button
                className={clsx(
                  'text-sm text-brand-black',
                  currentTab !== index + 1
                    ? 'font-inter text-[#667185] text-base'
                    : 'text-brand-black font-InterMedium border-b text-base',
                  'inline-block text-base p-4 rounded-t-lg',
                  borderColor,
                  currentTab === index + 1 ? activeClassName : null,
                  className,
                )}
                type="button"
                onClick={() => {
                  toggleTabs(index + 1);
                }}
              >
                <div
                  className={clsx('flex justify-center items-center gap-x-2')}
                >
                  <div
                    className={clsx(
                      titleClassName,
                      currentTab === index + 1 && activeTitleClassName,
                    )}
                  >
                    <p>{tab.name}</p>
                  </div>
                  {!isNil(counts) &&
                    isArray(counts) &&
                    isNumber(toNumber(counts[index])) &&
                    toNumber(counts[index]) > 0 && (
                      <div
                        className={clsx(
                          'h-[18px] w-[28px] rounded-lg flex justify-center items-center bg-gray-200',
                          currentTab === index + 1 && 'bg-blue-500',
                          currentTab === index + 1 && countActiveClassName,
                          countClassName,
                        )}
                      >
                        <p className={clsx('text-white font-normal text-xs')}>
                          {toNumber(counts[index]) > 99 ? 99 : counts[index]}
                        </p>
                      </div>
                      // eslint-disable-next-line indent
                    )}
                </div>
              </button>
            </li>
          ))}
        </ul>
      </div>
      <div id="myTabContent" className={[customClass, 'grow overflow-y-auto']}>
        {children}
      </div>
    </main>
  );
}

Tab.propTypes = {
  customClass: PropTypes.string,
  numberOfTabs: PropTypes.array.isRequired,
  currentTab: PropTypes.number,
  borderColor: PropTypes.string,
};

Tab.defaultProps = {
  numberOfTabs: null,
  customClass: null,
};
