/* eslint-disable object-curly-newline */
/* eslint-disable max-len */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable import/prefer-default-export */
import { yupResolver } from '@hookform/resolvers/yup';
import { Helmet } from 'react-helmet';
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import * as yup from 'yup';
import { forgetPasswordOtp } from '../context/Actions/auth';
import { Button, Card, Text, Input } from '../components/atoms/index';
import { AuthLayout } from '../components/layout/authLayout';

const schema = yup.object().shape({
  email: yup.string().email().required('OTP is required'),
});

export function ForgetPassword() {
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({ resolver: yupResolver(schema), mode: 'onChange' });

  const handleOtp = async (data) => {
    try {
      setIsLoading(true);
      localStorage.setItem('email', JSON.stringify(data));
      const res = await forgetPasswordOtp(data);
      toast.success(res.data.message);
      navigate('/forget-password-otp');
    } catch (error) {
      setIsLoading(false);
    }
  };

  return (
    <>
      <AuthLayout>
        <Helmet>
          <title>FundR | ForgetPassword</title>
        </Helmet>
        <Card backgroundColor="white">
          <Text
            textColor="#04004D"
            design="flex justify-center md:text-xl text-lg font-interMedium md:font-interSemiBold md:mb-5 mb-2"
          >
            Forgot password?
          </Text>
          <Text
            textColor="#70747E"
            design="flex justify-center md:text-[15px] text-xs font-inter md:mb-6 mb-4 leading-5"
          >
            Enter your email and we’ll send you an OTP to reset your password
          </Text>
          <form onSubmit={handleSubmit(handleOtp)}>
            <Input
              {...register('email')}
              label="Work Email"
              type="email"
              placeholder="janedoe@gmail.com"
              borderColor="border-[#E6EAEE]"
              required
              error={errors.email?.message}
            />

            <div className="mt-8">
              <Button
                loading={isLoading}
                isDisabled={isLoading}
                type="submit"
                label="Send OTP"
                btnColor="default"
                size="block"
                custom
              />
            </div>
          </form>
        </Card>
      </AuthLayout>
      {/* <div className="w-full h-screen grid-cols-1 lg:grid lg:grid-cols-2">
        <div className="grid content-center bg-white">
          <div className=" rounded-[30px] lg:px-20 px-6 md:px-10 grow">
            <div className="mb-10 lg:mb-16">
              <p className="text-base text-left text-black font-interSemiBold lg:text-3xl">
                Forgot your password?
              </p>
              <small className="text-[#2F2F2F]">
                Enter your email and we’ll send you an OTP to reset your
                password
              </small>
            </div>
            <form onSubmit={handleSubmit(handleOtp)}>
              <div className="lg:mb-20 mb-60">
                <label
                  htmlFor="first_name"
                  className="block mb-4 text-sm lg:text-base font-interMedium text-[#2F2F2F]"
                >
                  Email address
                </label>
                <input
                  {...register("email")}
                  type="text"
                  id="otp"
                  className="border border-[#ECECEC] text-base rounded-lg focus:border-[#ECECE] focus:ring-0 focus:outline-none block w-full lg:p-4 py-3 px-4"
                  required
                />
                <p className="text-brand-red">{errors.email?.message}</p>
              </div>

              <div className="mb-8">
                <Button
                  loading={isLoading}
                  type="submit"
                  label="Send OTP"
                  btnColor="login"
                  size="block"
                  custom
                />
              </div>
            </form>
          </div>
        </div>
      </div> */}
    </>
  );
}
