/* eslint-disable react/button-has-type */
/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable operator-linebreak */
/* eslint-disable react/no-array-index-key */
/* eslint-disable indent */
/* eslint-disable no-nested-ternary */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable no-console */
/* eslint-disable import/prefer-default-export */
// eslint-disable react/no-unstable-nested-components
import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useLocation, useNavigate } from 'react-router-dom';
import moment from 'moment';
import { NumericFormat } from 'react-number-format';
import DatePicker from 'react-datepicker';
import { isNil, isEmpty, isArray } from 'lodash';
import DataTable from 'react-data-table-component';
import clsx from 'clsx';
import { Fade, Zoom } from 'react-reveal';
import DashboardLayout from '../components/layout/dashboardLayout';
import { SubHeader } from '../components/SubHeader';
import { Share, Sign, Earn, CopyWhite, Filter } from '../icons';
import { Button } from '../components/atoms';
import { useAuth, useUser } from '../context';
import Image from '../assets/images/proIcon.png';

export function Refer() {
  const { referDetails } = useUser();
  // const {} =useUser()
  const [buttonText, setButtonText] = useState('Copy');
  const referralCode = 'fundrreferral10';

  const handleCopy = () => {
    navigator.clipboard.writeText(referDetails?.user_referral_code).then(() => {
      setButtonText('Copied');
      setTimeout(() => {
        setButtonText('Copy');
      }, 5000);
    });
  };

  return (
    <DashboardLayout>
      <Helmet>
        <title>FundR | Refer</title>
      </Helmet>
      <div className="w-full px-4 lg:px-8 pt-4 pb-20 lg:pb-8">
        <div className="flex flex-col mt-9 max-md:max-w-full">
          <div className="">
            <div className="flex justify-between items-center">
              <Fade left>
                <div className="mb-6">
                  <p className="text-[#101928] font-interSemiBold text-xl">
                    Refer businesses and earn rewards
                  </p>
                  <small className="text[#475367] text-xs">
                    Get NGN 100 for every successful sign up
                  </small>

                  <hr className="my-12" />

                  <div>
                    <p className="mb-2">Share your unique referral code</p>

                    <div className="grid grid-cols-8 gap-3 w-full max-w-[23rem]">
                      <label htmlFor="code" className="sr-only">
                        Label
                      </label>
                      <input
                        id="code"
                        type="text"
                        className="col-span-5 bg-white border border-[#E6EAEE] text-[#23262F] text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full py-3 px-2.5"
                        value={referDetails?.user_referral_code}
                        disabled
                        readOnly
                      />
                      <button
                        onClick={handleCopy}
                        className="col-span-3 text-white bg-[#3976E8] hover:bg-[#3976E8] focus:ring-0 focus:outline-none font-medium rounded-lg text-sm w-full sm:w-auto py-2.5 text-center items-center inline-flex justify-center"
                      >
                        {buttonText}
                        <CopyWhite className="ml-2" />
                      </button>
                    </div>
                  </div>
                </div>
              </Fade>
              <Zoom>
                <div className="bg-white border border-[#D7D7D7] rounded-[20px] p-8 space-y-4">
                  <div className="flex items-center space-x-3">
                    <Share />
                    <div>
                      <p className="text-base text-[#101928] font-interSemiBold">
                        Share your referral code
                      </p>
                      <p className="text-[#475367] text-sm mb-3">
                        Invite your friends to join Fundr with your referral
                        code
                      </p>
                      <hr />
                    </div>
                  </div>
                  <div className="flex items-center space-x-3">
                    <Sign />
                    <div>
                      <p className="text-base text-[#101928] font-interSemiBold">
                        Your referral signs up
                      </p>
                      <p className="text-[#475367] text-sm mb-3">
                        Your referral signs up with your referral code and now{' '}
                        <br />
                        has a Fundr account
                      </p>
                      <hr />
                    </div>
                  </div>
                  <div className="flex items-center space-x-3">
                    <Earn />
                    <div>
                      <p className="text-base text-[#101928] font-interSemiBold">
                        You earn rewards
                      </p>
                      <p className="text-[#475367] text-sm">
                        You get NGN 100 for every successful referral
                      </p>
                    </div>
                  </div>
                </div>
              </Zoom>
            </div>
          </div>
          <Fade right>
            <div className="my-10 bg-white rounded-lg border shadow">
              <div className="flex items-center justify-between py-7 px-6 ">
                <div className="flex items-center">
                  <p>Referral History</p>
                  <p className="text-[#3976E8] bg-[#F1F6FD] rounded-full px-2 py-1 ml-3 text-xs">
                    {referDetails?.total_referral} referrals
                  </p>
                </div>
                <Button
                  label="Filter"
                  icon
                  iconLeft={<Filter />}
                  btnColor="white"
                  outline
                />
              </div>

              <div className="relative overflow-x-auto">
                {isArray(referDetails?.referred_users) &&
                isEmpty(referDetails?.referred_users) ? (
                  <div className="flex flex-col items-center mb-4">
                    <p className="font-interMedium">
                      You do not have any referral yet.
                    </p>
                    <p className="text-lg font-interBold">Refer Now</p>
                    <button
                      onClick={() => {
                        navigator.clipboard.writeText(
                          referDetails?.user_referral_code,
                        );
                      }}
                      className="text-white bg-[#3976E8] hover:bg-[#3976E8] focus:ring-0 focus:outline-none font-medium rounded-lg text-sm w-full sm:w-auto py-2.5 px-3 text-center items-center inline-flex justify-center"
                    >
                      Copy referral code
                      <CopyWhite className="ml-2" />
                    </button>
                  </div>
                ) : (
                  <table className="w-full text-sm text-left rtl:text-right">
                    <thead className="text-xs text-[#667085] capitalize border-[#EAECF0] bg-[#F9FAFB] border-b">
                      <tr>
                        <th scope="col" className="px-6 py-3">
                          Full Name
                        </th>
                        <th scope="col" className="px-6 py-3 text-right">
                          Date Joined
                        </th>
                        <th scope="col" className="px-6 py-3 text-right">
                          Bonus Amount
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {isArray(referDetails?.referred_users) &&
                        !isEmpty(referDetails?.referred_users) &&
                        referDetails?.referred_users.map((item, index) => (
                          <tr
                            className="bg-white border-b dark:bg-gray-800 dark:border-gray-700"
                            key={index}
                          >
                            <th className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap flex items-center">
                              <img src={Image} alt="profile" className="pr-2" />
                              {item.name}
                            </th>
                            <td className="px-6 py-4 text-[#667085] text-right">
                              {item.created_at}
                            </td>
                            <td className="pr-10 pl-6 py-4 text-[#667085] text-right">
                              NGN {item.amount}
                            </td>
                          </tr>
                        ))}
                    </tbody>
                  </table>
                )}
              </div>
            </div>
          </Fade>
        </div>
      </div>
    </DashboardLayout>
  );
}
