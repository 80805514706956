/* eslint-disable no-console */
/* eslint-disable operator-linebreak */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable wrap-iife */
/* eslint-disable react/jsx-props-no-spreading */
import { isEmpty, isNil, join, pick, split } from 'lodash';
import { yupResolver } from '@hookform/resolvers/yup';
import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import useFormPersist from 'react-hook-form-persist';
import * as yup from 'yup';
import clsx from 'clsx';
import { IoArrowBack } from 'react-icons/io5';
import { toast } from 'react-toastify';

import { Button, FileUploadForm } from '../../atoms/index';
import OnboardLayout from '../../layout/onboardingLayout';
import storageName from '../../../helpers/storageHelpers';
import { useAuth, useUser } from '../../../context';

const schema = yup.object().shape({
  cac_certificate_asset_url: yup.string().url().required(),
  business_name_form_asset_url: yup.string().url().required(),
  proof_of_address_url: yup.string().url().required(),
  government_issue_id: yup.object({
    idUrl: yup.string().url().required(),
  }),
});

function BusinessDoc() {
  const { handleAuthenticateUser, kycDetails } = useAuth();
  const { handleCreateBusinesskycDraft, handleIsLoading, isLoading } =
    useUser();
  const navigate = useNavigate();
  const {
    handleSubmit,
    setValue,
    watch,
    getValues,
    formState: { errors },
  } = useForm({ resolver: yupResolver(schema) });
  useFormPersist(storageName.business_creation, {
    watch,
    setValue,
    storage: window.localStorage,
  });

  const setCoi = (res) => {
    setValue('cac_certificate_asset_url', res);
  };
  const setBnf = (res) => {
    setValue('business_name_form_asset_url', res);
  };
  const setPoa = (res) => {
    setValue('proof_of_address_url', res);
  };
  const setUgi = (res) => {
    setValue('government_issue_id.idUrl', res);
  };

  const handleBusinessSubmit = async (values) => {
    try {
      handleIsLoading(true);
      // const details = getValues();

      await handleCreateBusinesskycDraft(values, (error) => {
        if (error) {
          toast.error(
            error?.response?.data?.message ?? 'kyc documents upload failed',
          );
          return;
        }
        toast.success('kyc documents saved successfully');
        navigate('/onboard/business/review');
      });
    } catch (error) {
      toast.error(
        error?.response?.data?.message ?? 'kyc document upload failed',
      );
      if (process.env.NODE_ENV === 'development') {
        console.log('handle business documents ======>>> error', error);
      }
    } finally {
      handleIsLoading(false);
      await handleAuthenticateUser();
    }
  };

  useEffect(() => {
    if (isNil(kycDetails?.kyc) || isEmpty(kycDetails?.kyc)) {
      return;
    }

    setValue(
      'business_name_form_asset_url',
      kycDetails?.kyc?.business_details?.business_name_form_asset_url,
    );

    setValue('proof_of_address_url', kycDetails?.kyc?.proof_of_address_url);

    setValue(
      'cac_certificate_asset_url',
      kycDetails?.kyc?.business_details?.cac_certificate_asset_url,
    );

    setValue(
      'government_issue_id',
      pick(kycDetails?.kyc?.business_details?.government_issue_id, [
        'status',
        'verificationStatus',
        'verificationMode',
        'verificationType',
        'verificationValue',
        'idUrl',
      ]),
    );

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <OnboardLayout>
      <div className="lg:px-16 pl-12 pr-8 xl:w-[65%] mx-auto">
        <div
          className="sm:mt-[5rem] mt-24 text-base text-[#292D32] font-interMedium flex items-center gap-x-2 lg:mb-5 mb-3 cursor-pointer"
          onClick={() => navigate('/home')}
          role="button"
          onKeyDown={() => {}}
          tabIndex={-1}
        >
          <IoArrowBack className={clsx('w-5 h-5 text-[#3976E8]')} />
          <span className="hidden md:block text-[#3976E8]">
            Back to Dashboard
          </span>
        </div>

        <div className="flex flex-col py-6 mx-2 xl:w[60rem]">
          <p className="mb-10 text-xl font-interSemiBold">
            Registered Business Name
          </p>
          <form onSubmit={handleSubmit(handleBusinessSubmit)}>
            {/* onSubmit={handleSubmit(handleBusinessSubmit)} */}
            <div className="lg:gap-y-2 grid">
              <FileUploadForm
                label="Proof of Address"
                response={setPoa}
                persisted={getValues('proof_of_address_url')}
                error={errors?.proof_of_address_url?.message}
              />
              <FileUploadForm
                label="Business Name Form"
                response={setBnf}
                persisted={getValues('business_name_form_asset_url')}
                error={errors?.business_name_form?.message}
              />
              <FileUploadForm
                label="Certificate of Incorporation"
                response={setCoi}
                persisted={getValues('cac_certificate_asset_url')}
                error={errors?.cac_certificate_asset_url?.message}
              />
              <FileUploadForm
                label="Upload Government Issued ID"
                response={setUgi}
                persisted={getValues('government_issue_id.idUrl')}
                error={join(
                  split(errors?.government_issue_id?.idUrl?.message, '_'),
                  ' ',
                )}
              />

              <div className="mt-10">
                <Button
                  loading={isLoading}
                  type="submit"
                  label="Save and Continue"
                  btnColor="default"
                  size="block"
                  className={clsx('h-[55px]')}
                />
              </div>
            </div>
          </form>
        </div>
      </div>
    </OnboardLayout>
  );
}

export default BusinessDoc;
