/* eslint-disable camelcase */
/* eslint-disable react/function-component-definition */
/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable implicit-arrow-linebreak */
/* eslint-disable no-restricted-syntax */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable react/jsx-curly-brace-presence */
/* eslint-disable indent */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable operator-linebreak */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  useForm,
  FormProvider,
  useWatch,
  Controller,
  useFormContext,
} from 'react-hook-form';
import { Helmet } from 'react-helmet';
import { NumericFormat } from 'react-number-format';
import clsx from 'clsx';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import { IoArrowBack } from 'react-icons/io5';
import {
  isEmpty,
  isNil,
  toNumber,
  last,
  truncate,
  keys,
  forEach,
  size,
  isNumber,
} from 'lodash';
import { useDropzone } from 'react-dropzone';
import { useToggle } from 'usehooks-ts';
import { Line } from 'rc-progress';
import ReactDatePicker from 'react-datepicker';
import useFormPersist from 'react-hook-form-persist';

import { Upload } from '../../icons';
import { Input, Button, Select } from '../atoms/index';
import DashboardLayout from '../layout/dashboardLayout';
import { InvoiceForm } from './InvoiceItemForm';
import { useUser, useAuth } from '../../context';
import { upload } from '../../context/Actions/user';

import StorageName from '../../helpers/storageHelpers';

const defaultInvoiceItemValues = {
  quantity: 0,
  price: 0,
  item: '',
};
const itemsSchema = yup.object().shape({
  item: yup
    .string()
    .required('please enter an item')
    .max(100, 'description too long'),
  quantity: yup
    .number()
    .min(1, 'quantity must be 1 or more')
    .max(1000, 'quantity too large')
    .required('please enter a quantity')
    .typeError('please enter a quantity'),
  price: yup
    .number()
    .when(['quantity'], {
      is: (quantity) => isNumber(quantity) && toNumber(quantity) >= 1,
      then: (schema) => schema.min(1, 'price must be 1.00 or more'),
      otherwise: (schema) => schema.min(0),
    })
    .required('please enter a price')
    .typeError('please enter a price'),
});

const schema = yup.object().shape({
  invoice_title: yup
    .string()
    .max(100, 'title is too long')
    .required('please enter an invoice title'),
  invoice_description: yup
    .string()
    .max(100, 'description is too long')
    .optional(),
  invoice_business_address: yup
    .string()
    .max(100, 'business is too long')
    .required('please enter a business address'),
  invoice_message: yup.string().max(300, 'invoice notes is too long'),
  invoice_number: yup.string().max(100, 'invoice number is too long'),
  invoice_date: yup
    .string()
    // .min(new Date(), 'invoice date must be today or a date in the future')
    .required('invoice date is required'),
  invoice_due_date: yup
    .string()
    // .min(new Date(), 'invoice due date must be today or a date in the future')
    .required('invoice due date is required'),
  recipient_name: yup
    .string()
    .max(100, 'recipient name is too long')
    .required('please enter recipient name'),
  recipient_email: yup
    .string()
    .email('recipient email must be a valid email')
    .max(100, 'recipient email is too long')
    .required('please enter recipient email'),
  items: yup
    .array()
    .of(itemsSchema)
    .min(1, 'please enter atleast one item')
    .max(10, 'cannot more than 10 items')
    .required(),
  payment_account: yup.string().required(),
});

const businessLogoFileSize = toNumber(
  process.env?.BUSINESS_LOGO_FILE_SIZE ?? 5000000,
);

const SubTotal = () => {
  const { control } = useFormContext();
  const items = useWatch({
    name: 'items',
    control,
  });
  const subTotal = useMemo(() => {
    const total = items.reduce(
      (acc, curItem) =>
        // eslint-disable-next-line implicit-arrow-linebreak
        acc + toNumber(curItem.price) * toNumber(curItem.quantity),
      0,
    );
    return total;
  }, [items]);

  return (
    <div className={clsx('flex justify-center items-center pt-1')}>
      <div className="bg-[#F9FAFB] px-5 flex items-center justify-between w-[99%] h-[38px]">
        <div>
          <p className="text-xs text-[#23262F] font-interMedium">Subtotal</p>
        </div>
        <div>
          <NumericFormat
            value={subTotal}
            decimalSeparator="."
            decimalScale={2}
            displayType="text"
            prefix="NGN "
            thousandSeparator
            fixedDecimalScale
            className="text-xs text-[#23262F]  font-interMedium"
          />
        </div>
      </div>
    </div>
  );
};

export function CreateInvoice() {
  const navigate = useNavigate();
  const { userDetails } = useAuth();
  const { business, accounts } = useUser();
  // const { inVoiceSubTotal: subTotal, disableInvoiceAction } = useInvoice();
  const editAddressRef = useRef(null);
  const [editAddress, toggleEditAddress, setEditAddress] = useToggle();
  const methods = useForm({
    mode: 'onChange',
    resolver: yupResolver(schema),
    defaultValues: {
      items: [defaultInvoiceItemValues],
    },
  });

  const {
    register,
    formState,
    handleSubmit,
    control,
    getValues,
    setValue,
    watch,
    trigger,
  } = methods;

  useFormPersist(StorageName.invoice_creation, {
    watch,
    setValue,
    storage: window.localStorage,
  });

  const { errors } = formState;

  const onSubmit = handleSubmit((data) => {
    const result = `invoice form data ==========>>>>> ${data}`;
    alert(result);
    console.log(result);
  });

  const [uploadUrl, setUploadUrl] = useState(null);

  const [businessAddress, setBusinessAddress] = useState(null);

  const [businessPhoneNumber, setBusinessPhoneNumber] = useState(null);

  const [prevBusinessAddress, setPrevBusinessAddress] = useState('');

  const paymentAccouts = useMemo(() => {
    const accountType = [
      {
        key: 'Select Account',
        value: 'default',
        disabled: true,
      },
    ];

    if (!isEmpty(accounts)) {
      forEach(accounts, ({ account_details, id }) => {
        accountType.push({
          key: `${account_details?.account_number} - ${account_details?.account_name}`,
          value: id,
          disabled: false,
        });
      });
    }

    return accountType;
  }, [accounts]);

  const [isUploading, setIsUploading] = useState(false);
  const [uploadProgress, setUploadProgress] = useState(0);

  const onDrop = useCallback(
    async (acceptedFiles) => {
      try {
        if (isEmpty(acceptedFiles)) {
          return;
        }
        setIsUploading(true);
        const results = [];
        for (const file of acceptedFiles) {
          const formData = new FormData();
          formData.append('file', file);
          results.push(
            upload(formData, {
              onUploadProgress: (progressEvent) => {
                const progress = Math.round(
                  (progressEvent.loaded / file.size) * 100,
                );
                setUploadProgress(progress);
                // console.log('file upload progress ========>>>> ', progress);
              },
            }),
          );
        }

        const uploads = await Promise.all(results);

        if (uploads?.length > 1) {
          const uploadsArray = [];
          for (const uploaded of uploads) {
            if (uploaded.data.status) {
              uploadsArray.push(uploaded.data.assetUrl);
            }
          }

          setUploadUrl(uploadsArray);
        } else {
          const uploadedFile = last(uploads);

          if (uploadedFile?.data?.status) {
            setUploadUrl(uploadedFile?.data?.assetUrl);
          }
        }
        // response(await Promise.all(results));
      } catch (error) {
        toast.error('Failed to upload file');
        if (process.env.NODE_ENV !== 'production') {
          console.error('invoice logo upload error ========>>>>  :', error);
        }
      } finally {
        setIsUploading(false);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [uploadUrl],
  );

  const {
    getRootProps,
    getInputProps,
    isDragActive,
    isDragAccept,
    isDragReject,
    open,
  } = useDropzone({
    onDrop,
    accept: {
      'image/png': ['.png'],
      'image/jpeg': ['.jpeg', '.jpg'],
      'image/svg+xml': ['.svg'],
    },
    maxSize: businessLogoFileSize,
    multiple: false,
  });

  const handleSetDefaultBusiness = () => {
    const businessLocation = business?.location ?? {};
    const businessLocationKeys = keys(businessLocation);

    const businessAddressRaw = [];

    forEach(businessLocationKeys, (locKey) => {
      if (
        !isNil(businessLocation[locKey]) &&
        !isEmpty(businessLocation[locKey]) &&
        locKey !== 'country'
      ) {
        businessAddressRaw.push(`${businessLocation[locKey]}`);
      }
      if (locKey !== 'country') {
        businessAddressRaw.push(
          `${business?.location?.country ?? business?.country?.name}`,
        );
      }
    });

    const businessAddressResult = businessAddressRaw.join(', ');

    setBusinessAddress(businessAddressResult);

    setTimeout(() => {
      // setPrevBusinessAddress(getValues('invoice_business_address'));
      setValue('invoice_business_address', businessAddressResult);
      if (!isNil(editAddressRef?.current)) {
        editAddressRef.current.value = getValues('invoice_business_address');
      }
    }, 100);

    setBusinessPhoneNumber(
      `(${
        business?.country?.countryCode ?? userDetails?.country?.countryCode
      }) ${business?.contact?.phone ?? userDetails?.mobile}`,
    );
  };

  const handleToggleEditAddress = () => {
    toggleEditAddress();
    setTimeout(() => {
      setPrevBusinessAddress(getValues('invoice_business_address'));
      if (!isNil(editAddressRef?.current)) {
        editAddressRef.current.value = getValues('invoice_business_address');
      }
    }, 100);
  };

  const handleSaveEditAddress = () => {
    setPrevBusinessAddress(getValues('invoice_business_address'));
    setTimeout(() => {
      setEditAddress(false);
    }, 100);
  };

  const handleCancelEditAddress = (action = null) => {
    if (action === 'reset') {
      setValue('invoice_business_address', businessAddress);
    } else {
      setValue('invoice_business_address', prevBusinessAddress);
    }
    setTimeout(() => {
      setEditAddress(false);
    }, 100);
    // handleSetDefaultBusiness();
  };

  const handleTriggerForm = async () => {
    const triggerFormResult = await trigger();
    console.log('triggerFormResult ===========>>> ', triggerFormResult);
  };

  useEffect(() => {
    if (
      !isNil(business?.social?.business_logo) &&
      !isEmpty(business?.social?.business_logo)
    ) {
      setUploadUrl(business?.social?.business_logo);
    }

    handleSetDefaultBusiness();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (editAddress) {
      setTimeout(() => {
        if (isNil(editAddressRef?.current)) {
          editAddressRef.current.focus();
          editAddressRef.current.selectionStart =
            editAddressRef.current.value.length;
        }
      }, 100);
    }
  }, [editAddress]);

  return (
    <DashboardLayout>
      <Helmet>
        <title>FundR | CreateInvoice</title>
      </Helmet>
      <div
        className={clsx(
          'h-[90dvh] overflow-y-auto pt-8 px-5 lg:mt-16 mt-10 sm:mt-14 mb-10',
        )}
      >
        <div
          className="text-base text-[#292D32] font-interMedium flex items-center gap-x-2 mt-5 mb-8 cursor-pointer"
          onClick={() => navigate('/invoices')}
          role="button"
          onKeyDown={() => {}}
          tabIndex={-1}
        >
          <IoArrowBack className={clsx('w-5 h-5 text-[#3976E8]')} />
          <span className="hidden md:block text-[#3976E8]">Go Back</span>
        </div>

        <FormProvider {...methods}>
          <form onSubmit={onSubmit}>
            <div className="bg-white mb-10 shadow-md py-6 px-6">
              <div className="mx-6 py-6 bg-white border-b sm:mx-10 border-brand-border">
                <div className="flex items-start justify-between pb-14">
                  <div className="w-[260px] h-[242px]">
                    {!isUploading && (
                      <div className="w-full h-full">
                        <div className="w-full h-full flex flex-col justify-start items-start gap-y-2 px">
                          {isNil(uploadUrl) && isEmpty(uploadUrl) ? (
                            <label
                              htmlFor="dropzone-file"
                              className={clsx(
                                'flex flex-col items-center justify-center w-full h-64 border-2 border-gray-300 border-dashed rounded-lg cursor-pointer bg-gray-50 dark:hover:bg-bray-800',
                                'dark:bg-gray-700 hover:bg-gray-100 dark:border-gray-600 dark:hover:border-gray-500 dark:hover:bg-gray-600 px-4',
                                isDragAccept && 'border-green-500',
                                isDragReject && 'border-red-500',
                              )}
                              {...getRootProps()}
                            >
                              <div className="flex flex-col items-center justify-center pt-5 pb-6">
                                <Upload className={clsx('h-[40px] w-[40px]')} />
                                {isDragActive ? (
                                  <div>
                                    <p className="mb-2 text-sm text-gray-500 dark:text-gray-400">
                                      Drop the files here ...
                                    </p>
                                  </div>
                                ) : (
                                  <div>
                                    <p className="mb-2 text-sm text-gray-500 dark:text-gray-400">
                                      Click to upload or drag and drop
                                    </p>
                                  </div>
                                )}
                                <div>
                                  <p className="text-xs text-gray-500 dark:text-gray-400">
                                    SVG, PNG, JPG or GIF
                                  </p>
                                </div>
                              </div>
                              <input
                                disabled={isDragReject}
                                {...getInputProps()}
                              />
                            </label>
                          ) : (
                            <div
                              className={clsx(
                                'border-2 border-gray-300 border-dashed rounded-lg w-full h-full',
                              )}
                            >
                              <img
                                src={uploadUrl}
                                className={clsx(
                                  'w-full h-full bg-center bg-cover',
                                )}
                              />
                            </div>
                          )}
                        </div>
                        {!isUploading && (
                          <div
                            className={clsx('cursor-pointer mt-4')}
                            onClick={open}
                          >
                            <div>
                              <p className={clsx('text-blue-500 font-normal')}>
                                Change logo image
                              </p>
                            </div>
                          </div>
                        )}
                      </div>
                    )}
                    {isUploading && (
                      <div
                        className={clsx(
                          'w-full h-full flex flex-col justify-center items-center border-2 border-dashed rounded-lg border-gray-400 border-dotted',
                        )}
                      >
                        <div>
                          <p className={clsx('text-sm text-blue-500')}>
                            {uploadProgress}%
                          </p>
                        </div>
                        <div className={clsx('w-4/5')}>
                          <Line
                            percent={uploadProgress}
                            strokeWidth={4}
                            strokeColor="#3b82f6"
                          />
                        </div>
                      </div>
                    )}
                  </div>
                  <div className="sm:w-[400px] min:w-[400px] max-w-[400px] flex flex-col justify-start">
                    <div className={clsx('flex items-start w-full')}>
                      <Input
                        {...register('invoice_title')}
                        placeholder="Invoice Title"
                        required
                        inputWrapperClassName={clsx('w-full')}
                        className={clsx(
                          'md:!h-[38px] !pl-2 !pr-0 placeholder:text-sm text-sm',
                        )}
                        error={errors?.invoice_title?.message}
                      />
                    </div>
                    <div className={clsx('flex items-start w-full')}>
                      <Input
                        {...register('invoice_description')}
                        placeholder="Invoice description"
                        inputWrapperClassName={clsx('w-full')}
                        className={clsx(
                          'md:!h-[38px] !pl-2 !pr-0 placeholder:text-sm text-sm',
                        )}
                        error={errors?.invoice_description?.message}
                      />
                    </div>
                    <div className="text-left float-right self-end">
                      <div className={clsx('w-full flex justify-start mb-2')}>
                        <p className="text-sm font-interSemiBold">
                          {truncate(business?.name, { length: 20 })}
                        </p>
                      </div>
                      <div className={clsx('max-h-[96px] h-[86px] w-[120px]')}>
                        <div className={clsx('h-full w-full flex flex-col')}>
                          <div className={clsx('text-wrap mb-2')}>
                            <Controller
                              control={control}
                              name="invoice_business_address"
                              rules={{ maxLength: 100 }}
                              render={({ field: { onChange, value } }) => (
                                <textarea
                                  value={value}
                                  onChange={onChange}
                                  ref={editAddressRef}
                                  className={clsx(
                                    'max-h-[95%] max-w-[100%] h-[50px] resize-none outline-none border-0 outline-[0.5px] text-xs rounded-md p-1',
                                    {
                                      'border border-b-gray-400 shadow-sm p-0':
                                        !editAddress,
                                    },
                                  )}
                                  disabled={!editAddress}
                                />
                              )}
                            />
                            {!isNil(errors?.invoice_business_address) && (
                              <div>
                                <p className="text-brand-red text-xs mb-2">
                                  {errors?.invoice_business_address?.message}
                                </p>
                              </div>
                            )}
                          </div>

                          <div className={clsx('mt-3')}>
                            <p className="text-xs">{businessPhoneNumber}</p>
                          </div>
                        </div>
                      </div>
                      <div className={clsx('cursor-pointer mt-2')}>
                        {!editAddress && (
                          <div onClick={handleToggleEditAddress}>
                            <p
                              className={clsx(
                                'text-blue-500 font-normal text-xs',
                              )}
                            >
                              Edit business address
                            </p>
                          </div>
                        )}

                        {editAddress && (
                          <div className={clsx('flex items-center gap-x-2')}>
                            <div className={clsx('flex items-center gap-x-2')}>
                              <div onClick={() => handleSaveEditAddress()}>
                                <p
                                  className={clsx(
                                    'text-blue-500 font-normal text-xs',
                                  )}
                                >
                                  Save
                                </p>
                              </div>
                            </div>
                            <div onClick={() => handleCancelEditAddress()}>
                              <div>
                                <p
                                  className={clsx(
                                    'text-gray-500 font-normal text-xs',
                                  )}
                                >
                                  Cancel
                                </p>
                              </div>
                            </div>
                            <div
                              onClick={() => handleCancelEditAddress('reset')}
                            >
                              <div>
                                <p
                                  className={clsx(
                                    'text-gray-500 font-normal text-xs',
                                  )}
                                >
                                  Reset
                                </p>
                              </div>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="h-full px-3 py-8 sm:p-8 md:h-fit">
                <div className="flex justify-between mb-8">
                  <div className="sm:w-[300px]">
                    <div>
                      <Input
                        {...register('recipient_name')}
                        placeholder="John Doe"
                        label="Recipient Name"
                        required
                        labelClassName={clsx('text-sm !mb-2')}
                        inputWrapperClassName={clsx('w-full')}
                        className={clsx(
                          'md:!h-[38px] !pl-2 !pr-0 placeholder:text-sm text-sm',
                        )}
                        error={errors?.recipient_name?.message}
                      />
                    </div>
                    <div>
                      <Input
                        {...register('recipient_email')}
                        placeholder="johndoe@gmail.com"
                        label="Email address"
                        required
                        inputWrapperClassName={clsx('w-full')}
                        labelClassName={clsx('text-sm !mb-2')}
                        className={clsx(
                          'md:!h-[38px] !pl-2 !pr-0 placeholder:text-sm text-sm',
                        )}
                        error={errors?.recipient_email?.message}
                      />
                    </div>
                  </div>
                  <div className="sm:w-[300px]">
                    <div>
                      <Input
                        {...register('invoice_number')}
                        placeholder="Inv-001"
                        label="Invoice Number"
                        required
                        inputWrapperClassName={clsx('w-full')}
                        labelClassName={clsx('text-sm !mb-2')}
                        className={clsx(
                          'md:!h-[38px] !pl-2 !pr-0 placeholder:text-sm text-sm',
                        )}
                        error={errors?.invoice_number?.message}
                      />
                    </div>
                    <div>
                      <Controller
                        control={control}
                        name="invoice_date"
                        render={({ field: { onChange, onBlur, value } }) => (
                          <ReactDatePicker
                            onChange={onChange} // send value to hook form
                            onBlur={onBlur} // notify when input is touched/blur
                            selected={value}
                            dateFormat="dd MMM, yyyy"
                            minDate={new Date()}
                            customInput={
                              // eslint-disable-next-line react/jsx-wrap-multilines
                              <Input
                                label="Invoice Date"
                                required
                                inputWrapperClassName={clsx('w-full')}
                                labelClassName={clsx('text-sm !mb-2')}
                                className={clsx(
                                  'md:!h-[38px] !pl-2 !pr-0 placeholder:text-sm text-sm',
                                )}
                                error={errors?.invoice_date?.message}
                              />
                            }
                          />
                        )}
                      />
                    </div>
                    <div>
                      <Controller
                        control={control}
                        name="invoice_due_date"
                        render={({ field: { onChange, onBlur, value } }) => (
                          <ReactDatePicker
                            onChange={onChange} // send value to hook form
                            onBlur={onBlur} // notify when input is touched/blur
                            selected={value}
                            dateFormat="dd MMM, yyyy"
                            minDate={new Date()}
                            customInput={
                              // eslint-disable-next-line react/jsx-wrap-multilines
                              <Input
                                label="Due Date"
                                required
                                inputWrapperClassName={clsx('w-full')}
                                labelClassName={clsx('text-sm !mb-2')}
                                className={clsx(
                                  'md:!h-[38px] !pl-2 !pr-0 placeholder:text-sm text-sm',
                                )}
                                error={errors?.invoice_due_date?.message}
                              />
                            }
                          />
                        )}
                      />
                    </div>
                  </div>
                </div>
                <div>
                  <InvoiceForm />
                  <hr className="border-b border-[#EAECF0] my-3" />
                </div>
                <div>
                  <SubTotal />
                </div>
                <div className="flex items-start justify-between mt-8 gap-x-60">
                  <div className="w-1/2">
                    <label
                      htmlFor="message"
                      className="block mb-2 text-sm tracking-tight font-inter text-[#344054] leading-[22.5px]"
                    >
                      Enter notes/terms
                    </label>
                    <div>
                      <Controller
                        control={control}
                        name="invoice_message"
                        render={({ field: { onChange, value } }) => (
                          <textarea
                            id="message"
                            rows="4"
                            value={value}
                            onChange={onChange}
                            className={clsx(
                              'block p-2.5 w-full h-[110px] text-sm text-gray-900 bg-white rounded-lg border border-[#D0D5DD] !pl-2 !pr-0',
                              'placeholder:text-sm text-sm placeholder:text-[#808080] resize-none',
                              {
                                'focus:ring-blue-500 focus:border-blue-500':
                                  isNil(errors?.invoice_message) &&
                                  isEmpty(errors?.invoice_message?.message),
                                'border-red-600 outline-red-600':
                                  !isNil(errors?.invoice_message) &&
                                  !isEmpty(errors?.invoice_message?.message),
                              },
                            )}
                            placeholder="A note or terms of service to your customer"
                          />
                        )}
                      />
                      <div
                        className={clsx('flex justify-end items-center mb-2')}
                      >
                        {!isNil(errors?.invoice_message) && (
                          <div className={clsx('self-start mr-auto')}>
                            <p className="text-brand-red text-xs">
                              {errors?.invoice_message?.message}
                            </p>
                          </div>
                        )}
                        {size(getValues('invoice_message')) > 279 && (
                          <div>
                            <p
                              className={clsx('text-xs text-gray-500', {
                                'text-yellow-400':
                                  size(getValues('invoice_message')) > 279 &&
                                  size(getValues('invoice_message')) < 299,
                                'text-red-600':
                                  size(getValues('invoice_message')) > 300,
                              })}
                            >
                              {300 - size(getValues('invoice_message'))}/300
                            </p>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="w-1/2">
                    <Select
                      {...register('payment_account')}
                      defaultValue="default"
                      placeholder="Select Account"
                      options={paymentAccouts}
                      required
                      error={errors?.payment_account?.message}
                      label="Payment account"
                      labelClassName={clsx('text-sm !mb-2')}
                      className={clsx(
                        'md:!h-[38px] !py-0 !pl-2 !pr-0 placeholder:text-sm text-sm',
                      )}
                    />
                    {false && (
                      <div className="relative mt-6 mb-8 flex justify-center items-center">
                        <div className={clsx('z-10 w-1 h-1 bg-white')}>
                          <p className="text-xs">Or</p>
                        </div>
                        <hr className="w-full absolute z-0 top-2" />
                      </div>
                    )}
                    {false && (
                      <div>
                        <div>
                          <p className="text-[#3976E8] font-interMedium text-sm underline underline-offset-4">
                            Pay using a one-time account
                          </p>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>

            <div className="w-full flex items-center gap-x-6">
              <div className="w-1/2">
                <Button
                  type="button"
                  label="Preview Invoice"
                  size="block"
                  custom
                  btnColor="outline"
                  onClick={handleTriggerForm}
                  // isDisabled={!isDirty || !isValid}
                  className={clsx('rounded-lg')}
                />
              </div>
              <div className="w-1/2">
                <Button
                  type="submit"
                  label="Save and Continue"
                  btnColor="default"
                  size="block"
                  custom
                  // isDisabled={!isDirty || !isValid}
                  className={clsx('rounded-lg')}
                />
              </div>
            </div>
            <div className={clsx('p-5')} />
          </form>
        </FormProvider>
      </div>
    </DashboardLayout>
  );
}

export default CreateInvoice;
