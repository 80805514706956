/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable function-paren-newline */
/* eslint-disable no-shadow */
/* eslint-disable object-curly-newline */
/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable operator-linebreak */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable indent */
/* eslint-disable no-nested-ternary */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/no-array-index-key */
/* eslint-disable wrap-iife */
/* eslint-disable no-console */
/* eslint-disable no-alert */
/* eslint-disable import/prefer-default-export */
/* eslint-disable implicit-arrow-linebreak */
import clsx from 'clsx';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { Flip, toast } from 'react-toastify';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { NumericFormat } from 'react-number-format';
import { isArray, isEmpty, map, size } from 'lodash';
import { Fade, Zoom } from 'react-reveal';

import DashboardLayout from '../components/layout/dashboardLayout';
import { Modal } from '../components/Modal';
import { Button, Input, Card } from '../components/atoms/index';
import { Cross, Copy, Close } from '../icons';
import { useAuth, useUser } from '../context';
import { Loader } from '../components/Loader';

const schema = yup.object().shape({
  currency: yup.string(),
  bank_code: yup.string(),
  extension_name: yup.string().required('Please enter extension name'),
});

export function Accounts() {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({ resolver: yupResolver(schema) });

  const { userDetails } = useAuth();
  const {
    accounts: acccountDetails,
    virtualAccountDeposits: transactionDetails,
    deposits: allDeposits,
    handleCreateBusinessSectionVirtualAccount,
    isLoading: pageLoading,
    handleFetchVirtualActionTransactions,
  } = useUser();
  const [acccountModal, setAccountModal] = useState(false);
  const [virtualAccounDeposits, setVirtualAccountDeposits] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const initialText = `${userDetails?.business?.name}- `;
  const [text] = useState(initialText);
  const [paddingLeft, setPaddingLeft] = useState('40px');
  const [page, setPage] = useState(0);
  const rowsPerPage = 10; // Change this value as per your requirement

  // Create account endpoint
  const handleNewAccount = async (data) => {
    try {
      setIsLoading(true);
      await handleCreateBusinessSectionVirtualAccount(data, (err, res) => {
        if (err) {
          return;
        }
        toast.success(res?.message);
        setAccountModal(false);
      });
    } catch (error) {
      if (process.env.NODE_ENV === 'development') {
        console.log('login--> error', error);
      }
    } finally {
      setIsLoading(false);
    }
  };

  // To copy each detail
  const copyToClipboard = (text) => {
    if (navigator.clipboard) {
      navigator.clipboard
        .writeText(text)
        .then(() => {
          toast.info('Details copied to clipboard!!', {
            transition: Flip,
          });
        })
        .catch((error) => {
          console.error('Copy to clipboard failed:', error);
        });
    } else {
      console.error('Clipboard API is not available');
    }
  };

  const handleButtonClick = (itemDescription, index) => {
    const textToCopy = document.getElementById(
      `item-desc-${index}`,
    ).textContent;
    copyToClipboard(textToCopy);
  };

  // Create new business form input
  useEffect(() => {
    const hyphenIndex = text.indexOf('-');
    if (hyphenIndex !== -1) {
      const textBeforeHyphen = text.substring(0, hyphenIndex + 1);
      const calculatedPadding = `${textBeforeHyphen.length * 15 + 80}px`; // Adjust multiplier as needed
      setPaddingLeft(calculatedPadding);
    }
  }, [text]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const virtualColumns = [
    {
      name: 'Amount',
      field: 'amount',
    },
    {
      name: 'Description',
      field: 'description',
    },
    {
      name: 'From',
      field: 'from',
    },
    {
      name: 'Date',
      field: 'created_at',
    },
    {
      name: 'Status',
      field: 'status',
    },
  ];

  const walletColumns = [
    {
      name: 'Amount',
      field: 'amount',
    },
    {
      name: 'Date',
      field: 'created_at',
    },
    {
      name: 'Status',
      field: 'status',
    },
  ];

  const getTdContent = (columnField, row, rowIndex, colIndex) => {
    switch (columnField) {
      case 'amount':
        return (
          <td
            key={`${rowIndex}-${colIndex}`}
            className="px-6 py-4 whitespace-nowrap text-sm text-black capitalize"
          >
            <NumericFormat
              value={row[columnField]}
              decimalSeparator="."
              thousandSeparator
              displayType="text"
              prefix="₦"
            />
          </td>
        );
      case 'created_at':
        return (
          <td
            key={`${rowIndex}-${colIndex}`}
            className="px-6 py-4 whitespace-nowrap text-sm text-gray-500"
          >
            {moment(row[columnField]).format('MMM Do YYYY, h:mm a')}
          </td>
        );

      case 'status':
        return (
          <td
            key={`${rowIndex}-${colIndex}`}
            className="px-6 py-4 whitespace-nowrap text-sm text-green-500"
          >
            {row[columnField] ||
            row.transaction[columnField] === 'completed' ? (
              <div className="flex gap-1 justify-between px-3 py-1 whitespace-nowrap bg-[#EFFDED] border border-solid border-[color:var(--Side-colors-Green,#5DC090)] rounded-[100px] text-[#144909] max-w-[120px]">
                <div className="my-auto mr-2 w-2 h-2 bg-[#92EF80] rounded-full" />
                <div className="capitalize">
                  {row[columnField] || row.transaction[columnField]}
                </div>
              </div>
            ) : (
              <div className="flex gap-1 justify-between px-3 py-1 whitespace-nowrap bg-[#FEECEE] border border-solid border-[color:var(--Side-colors-Red,#F14156)] rounded-[100px] text-[#740613] max-w-[120px]">
                <div className="my-auto mr-2 w-2 h-2 bg-[#F14156] rounded-full" />
                <div className="capitalize">{row[columnField]}</div>
              </div>
            )}
          </td>
        );

      default:
        return (
          <td
            key={`${rowIndex}-${colIndex}`}
            className="px-6 py-4 whitespace-nowrap text-sm text-[#535379] capitalize"
          >
            {row.transaction[columnField]}
          </td>
        );
    }
  };

  const fetchVirtualAccountTransactions = async (event) => {
    const res = await handleFetchVirtualActionTransactions(event.target.value);
    // console.log(res.data.data);
    setVirtualAccountDeposits(res.data.data.revenue);
  };

  useEffect(() => {
    // console.log(transactionDetails, '>>>>>>>>>>>>>>', allDeposits);
  }, []);

  return (
    <DashboardLayout>
      <Helmet>
        <title>FundR | Accounts</title>
      </Helmet>
      <Loader isActive={pageLoading} />

      <div className="w-full px-4 lg:px-0 lg:pl-8 lg:pr-20  pt-4 pb-20 lg:pb-8">
        <div className="flex flex-col mt-9 max-md:max-w-full">
          <div className="flex gap-5 justify-between self-center w-full font-medium whitespace-nowrap  max-md:flex-wrap mb-3 lg:mb-0 border-b-2 pb-3">
            <div className="flex gap-1.5 px-5 my-auto text-base leading-6 text-[#2E2E2E] font-interSemiBold">
              <div className="grow">Sort By:</div>
            </div>
            <div className="flex shadow-sm ">
              <Button
                label="Create new account"
                btnColor="default"
                isDisabled={size(acccountDetails) >= 5}
                icon
                iconLeft={<Cross />}
                onClick={() => {
                  setAccountModal(true);
                }}
                className={clsx('w-[195px] text-sm')}
              />
            </div>
          </div>
          <div className={clsx('lg:px-5 mt-12 max-md:px-5')}>
            <Fade right>
              <div className="flex overflow-x-auto gap-x-4  snap-x">
                {!isEmpty(acccountDetails) &&
                  map(acccountDetails, (item, index) => (
                    <div className="sm:my-5 my-7">
                      <input
                        className="hidden peer"
                        value={item.id}
                        id={index}
                        type="radio"
                        name="radio"
                        onChange={fetchVirtualAccountTransactions}
                      />

                      <label
                        className="flex cursor-pointer flex-col justify-center rounded-lg border border-[#E4E4E7] py-7 px-4 peer-checked:border peer-checked:border-[#3976E8] peer-hover:border-[#3976E8] h[120px] lg:w-[20rem] snap-center bg-white"
                        htmlFor={index}
                      >
                        <p className="text-xs text-[#8F8E8E] mb-1">
                          ACCOUNT DETAILS
                        </p>
                        <div>
                          <p className="mb-1 text-xs text-black uppercase font-interMedium">
                            {item.account_details?.bank_name}
                          </p>

                          <div className="flex items-center justify-between mt-2">
                            <div>
                              <p
                                className="mr-4 text-xl text-black font-interBold"
                                id={`item-desc-${index}`}
                              >
                                {item.account_details?.account_number}
                              </p>
                              <p className="text-sm text-black uppercase font-inter mb-">
                                {item.account_details?.account_name}
                              </p>
                            </div>
                            <p
                              data-desc-ref={`item-desc-${index}`}
                              id={`btn-${index}`}
                              className="bg-[#9F56D433]/20 rounded-lg p-2 flex items-center cursor-pointer"
                              onClick={() =>
                                handleButtonClick(item.description, index)
                              }
                            >
                              <Copy className="mr-2" />
                              <span className="text-[#9F56D4] text-xs">
                                Copy
                              </span>
                            </p>
                          </div>
                        </div>
                      </label>
                    </div>
                  ))}
              </div>
            </Fade>
          </div>

          <Zoom>
            <div className="overflow-x-auto hidden lg:block border  border-gray-200 rounded-lg mt-10">
              <table className="min-w-full divide-y divide-gray-200 ">
                <thead className="bg-gray-50 h-16">
                  <tr>
                    {/* Render checkbox in the first column of the table head */}
                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      <input type="checkbox" className="form-checkbox" />
                    </th>
                    {isArray(virtualAccounDeposits) &&
                    !isEmpty(virtualAccounDeposits) ? (
                      <>
                        {virtualColumns.map((column) => (
                          <th
                            key={column.field}
                            className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                          >
                            {column.name}
                          </th>
                        ))}
                      </>
                    ) : (
                      <>
                        {walletColumns.map((column) => (
                          <th
                            key={column.field}
                            className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                          >
                            {column.name}
                          </th>
                        ))}{' '}
                      </>
                    )}
                  </tr>
                </thead>
                <tbody className="bg-white divide-y divide-gray-200 ">
                  {isArray(virtualAccounDeposits) &&
                  !isEmpty(virtualAccounDeposits) ? (
                    <>
                      {isEmpty(virtualAccounDeposits) ? (
                        <tr className="bg-white">
                          <td className="text-lg font-interMedium text-pretty">
                            No Transactions on Account Yet. <br />
                            Please perform transactions to see history
                          </td>
                        </tr>
                      ) : (
                        <>
                          {' '}
                          {virtualAccounDeposits
                            .slice(
                              page * rowsPerPage,
                              page * rowsPerPage + rowsPerPage,
                            )
                            .map((row, rowIndex) => (
                              <tr
                                key={rowIndex}
                                className={
                                  rowIndex % 2 === 0
                                    ? ' bg-white '
                                    : 'bg-white/70'
                                }
                              >
                                {/* Render checkbox in the first column of each table row */}
                                <td className="px-6 py-4 whitespace-nowrap text-sm text-red-500">
                                  <input
                                    type="checkbox"
                                    className="form-checkbox rounded-sm"
                                  />
                                </td>

                                {virtualColumns.map((column, colIndex) =>
                                  getTdContent(
                                    column.field,
                                    row,
                                    rowIndex,
                                    colIndex,
                                  ),
                                )}
                              </tr>
                            ))}
                        </>
                      )}
                    </>
                  ) : (
                    <>
                      {!isEmpty(allDeposits) ? (
                        <>
                          {allDeposits
                            .slice(
                              page * rowsPerPage,
                              page * rowsPerPage + rowsPerPage,
                            )
                            .map((row, rowIndex) => (
                              <tr
                                key={rowIndex}
                                className={
                                  rowIndex % 2 === 0
                                    ? ' bg-white '
                                    : 'bg-white/70'
                                }
                              >
                                {/* Render checkbox in the first column of each table row */}
                                <td className="px-6 py-4 whitespace-nowrap text-sm text-red-500">
                                  <input
                                    type="checkbox"
                                    className="form-checkbox rounded-sm"
                                  />
                                </td>

                                {walletColumns.map((column, colIndex) =>
                                  getTdContent(
                                    column.field,
                                    row,
                                    rowIndex,
                                    colIndex,
                                  ),
                                )}
                              </tr>
                            ))}
                        </>
                      ) : (
                        <div>
                          <p className="text-lg font-interMedium text-pretty">
                            No transaction on wallet. Please perform
                            transactions to see details
                          </p>
                        </div>
                      )}
                    </>
                  )}
                </tbody>
              </table>
            </div>
          </Zoom>
          <Zoom>
            <div className="lg:hidden">
              {!isEmpty(virtualAccounDeposits) &&
              isArray(virtualAccounDeposits) ? (
                <>
                  {virtualAccounDeposits
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row) => (
                      <div className="mb-4 border border-[#BEBEBE33] rounded flex gap-5 justify-between flex-wrap py-4 pr-14 pl-6 bg-white  max-md:flex-wrap max-md:px-5 max-md:max-w-full">
                        <div className="border-b border-[#BEBEBE33] flex justify-between items-center w-full">
                          <p className="grow self-start  text-black ">
                            AMOUNT :{' '}
                          </p>
                          <p className="text-right">
                            {' '}
                            <NumericFormat
                              value={row.amount}
                              decimalSeparator="."
                              thousandSeparator
                              displayType="text"
                              prefix="₦"
                            />
                          </p>
                        </div>
                        {row?.transaction ? (
                          <div className="border-b border-[#BEBEBE33] flex justify-between items-center w-full capitalize">
                            <p className="grow self-start  text-black ">
                              TRANSACTION TYPE :{' '}
                            </p>

                            <p className="text-right">
                              {row?.transaction?.type}
                            </p>
                          </div>
                        ) : (
                          ''
                        )}

                        <div className="border-b border-[#BEBEBE33] flex justify-between items-center w-full">
                          <p className="grow self-start  text-black ">
                            DATE :{' '}
                          </p>
                          <p className="text-right">
                            {moment(row.created_at).format('MMM Do YYYY')}
                          </p>
                        </div>
                        <div className="border-b border-[#BEBEBE33] flex justify-between items-center w-full">
                          <p className="grow self-start  text-black ">
                            TIME :{' '}
                          </p>
                          <p className="text-right">
                            {moment(row.created_at).format('h:mm a')}
                          </p>
                        </div>
                        <div className="flex justify-between items-center w-full">
                          <div className="grow self-start text-black">
                            STATUS :{' '}
                          </div>
                          <div className="flex gap-1 justify-between px-3 py-1 whitespace-nowrap bg-green-50 border border-solid border-[color:var(--Side-colors-Green,#5DC090)] rounded-[100px] text-lime-950">
                            <div className="my-auto  w-2 h-2 bg-green-300 rounded-full" />
                            <div className="grow capitalize">
                              {row.transaction?.status}
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                </>
              ) : (
                <>
                  {allDeposits
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row) => (
                      <div className="mb-4 border border-[#BEBEBE33] rounded flex gap-5 justify-between flex-wrap py-4 pr-14 pl-6 bg-white  max-md:flex-wrap max-md:px-5 max-md:max-w-full">
                        <div className="border-b border-[#BEBEBE33] flex justify-between items-center w-full">
                          <p className="grow self-start  text-black ">
                            AMOUNT :{' '}
                          </p>
                          <p className="text-right">
                            {' '}
                            <NumericFormat
                              value={row.amount}
                              decimalSeparator="."
                              thousandSeparator
                              displayType="text"
                              prefix="₦"
                            />
                          </p>
                        </div>

                        <div className="border-b border-[#BEBEBE33] flex justify-between items-center w-full">
                          <p className="grow self-start  text-black ">
                            DATE :{' '}
                          </p>
                          <p className="text-right">
                            {moment(row.created_at).format('MMM Do YYYY')}
                          </p>
                        </div>
                        <div className="border-b border-[#BEBEBE33] flex justify-between items-center w-full">
                          <p className="grow self-start  text-black ">
                            TIME :{' '}
                          </p>
                          <p className="text-right">
                            {moment(row.created_at).format('h:mm a')}
                          </p>
                        </div>
                        <div className="flex justify-between items-center w-full">
                          <div className="grow self-start text-black">
                            STATUS :{' '}
                          </div>
                          <div className="flex gap-1 justify-between px-3 py-1 whitespace-nowrap bg-green-50 border border-solid border-[color:var(--Side-colors-Green,#5DC090)] rounded-[100px] text-lime-950">
                            <div className="my-auto  w-2 h-2 bg-green-300 rounded-full" />
                            <div className="grow capitalize">{row.status}</div>
                          </div>
                        </div>
                      </div>
                    ))}
                </>
              )}
            </div>
          </Zoom>
          <Fade left>
            {!isEmpty(allDeposits) && isEmpty(virtualAccounDeposits) ? (
              <div className="flex gap-5 justify-between self-center mt-3 w-full max-w-[1053px] max-md:flex-wrap max-md:max-w-full mb-6">
                {/* Show number of results */}
                <div className="flex-auto my-auto text-slate-500 text-sm">
                  Showing{' '}
                  {Math.min((page + 1) * rowsPerPage, allDeposits.length)} of{' '}
                  {allDeposits.length} results
                </div>

                <div className="flex gap-2 text-center whitespace-nowrap text-slate-600">
                  <button
                    type="button"
                    onClick={() => handleChangePage(null, page - 1)}
                    disabled={page === 0}
                  >
                    <img
                      loading="lazy"
                      src="https://cdn.builder.io/api/v1/image/assets/TEMP/4a89813b313caef866b316d5943f6760d234b7e20e9b9becd54879ffd7ef2bc6?apiKey=bb278f95022541509fa4b766a9320e21&"
                      className="w-8 aspect-square"
                      alt="left"
                    />
                  </button>
                  {Array.from(
                    Array(Math.ceil(allDeposits.length / rowsPerPage)).keys(),
                  ).map((pageNumber) => (
                    <button
                      type="button"
                      className={`justify-center items-center h-8 ${
                        page === pageNumber
                          ? 'text-blue-600 border-[color:var(--Main-blue,#1F62FF)]'
                          : 'border-[color:var(--Border-bd-dark,#DADAE7)]'
                      }  bg-white rounded border border-solid aspect-square `}
                      key={pageNumber}
                      onClick={() => setPage(pageNumber)}
                      disabled={page === pageNumber}
                    >
                      {pageNumber + 1}
                    </button>
                  ))}

                  <button
                    type="button"
                    onClick={() => handleChangePage(null, page + 1)}
                    disabled={
                      page === Math.ceil(allDeposits.length / rowsPerPage) - 1
                    }
                  >
                    <img
                      loading="lazy"
                      src="https://cdn.builder.io/api/v1/image/assets/TEMP/5865f0336c20abccc3587c9ba3d59bd337ea221a3d15684a7ea27b73d438917e?apiKey=bb278f95022541509fa4b766a9320e21&"
                      className="w-8 aspect-square"
                      alt="right"
                    />
                  </button>
                </div>
              </div>
            ) : !isEmpty(virtualAccounDeposits) ? (
              <div className="flex gap-5 justify-between self-center mt-3 w-full max-w-[1053px] max-md:flex-wrap max-md:max-w-full mb-6">
                {/* Show number of results */}
                <div className="flex-auto my-auto text-slate-500 text-sm">
                  Showing{' '}
                  {Math.min(
                    (page + 1) * rowsPerPage,
                    virtualAccounDeposits.length,
                  )}{' '}
                  of {virtualAccounDeposits.length} results
                </div>

                <div className="flex gap-2 text-center whitespace-nowrap text-slate-600">
                  <button
                    type="button"
                    onClick={() => handleChangePage(null, page - 1)}
                    disabled={page === 0}
                  >
                    <img
                      loading="lazy"
                      src="https://cdn.builder.io/api/v1/image/assets/TEMP/4a89813b313caef866b316d5943f6760d234b7e20e9b9becd54879ffd7ef2bc6?apiKey=bb278f95022541509fa4b766a9320e21&"
                      className="w-8 aspect-square"
                      alt="left"
                    />
                  </button>
                  {Array.from(
                    Array(
                      Math.ceil(virtualAccounDeposits.length / rowsPerPage),
                    ).keys(),
                  ).map((pageNumber) => (
                    <button
                      type="button"
                      className={`justify-center items-center h-8 ${
                        page === pageNumber
                          ? 'text-blue-600 border-[color:var(--Main-blue,#1F62FF)]'
                          : 'border-[color:var(--Border-bd-dark,#DADAE7)]'
                      }  bg-white rounded border border-solid aspect-square `}
                      key={pageNumber}
                      onClick={() => setPage(pageNumber)}
                      disabled={page === pageNumber}
                    >
                      {pageNumber + 1}
                    </button>
                  ))}

                  <button
                    type="button"
                    onClick={() => handleChangePage(null, page + 1)}
                    disabled={
                      page ===
                      Math.ceil(virtualAccounDeposits.length / rowsPerPage) - 1
                    }
                  >
                    <img
                      loading="lazy"
                      src="https://cdn.builder.io/api/v1/image/assets/TEMP/5865f0336c20abccc3587c9ba3d59bd337ea221a3d15684a7ea27b73d438917e?apiKey=bb278f95022541509fa4b766a9320e21&"
                      className="w-8 aspect-square"
                      alt="right"
                    />
                  </button>
                </div>
              </div>
            ) : (
              ''
            )}
          </Fade>
        </div>
      </div>

      {acccountModal && (
        <Modal size="lg">
          <>
            <div className="sticky top-0 z-40 flex items-center justify-between px-3 pt-6 pb-4 bg-white sm:px-5">
              <div className={clsx('mx-auto')}>
                <p className="text-xl text-[#04004D] font-interMedium">
                  Create new account
                </p>
              </div>
              <Close
                className="cursor-pointer"
                onClick={() => {
                  setAccountModal(false);
                }}
              />
            </div>
            <div className="h-full px-3 py-8 sm:pt-4 sm:pb-8 sm:px-8 md:h-fit">
              <form onSubmit={handleSubmit(handleNewAccount)}>
                <div className="mb-6">
                  <Input
                    {...register('currency')}
                    label="Account Type"
                    placeholder="Select currency"
                    value="NGN"
                    required
                    disabled
                    className={clsx('bg-white')}
                  />
                </div>
                <div className="mb-6">
                  <Input
                    {...register('bankName')}
                    label="Bank Name"
                    placeholder="Bank"
                    value="Wema Bank"
                    disabled
                    className={clsx('bg-white')}
                  />
                </div>

                <div className="mb-4">
                  <label
                    htmlFor="businessName"
                    className="block mb-1.5 text-base tracking-tight font-inter text-[#344054] leading-5"
                  >
                    Account Name
                  </label>
                  <div className="relative">
                    <div className="absolute inset-y-0 left-0 flex items-center justify-center pl-[18px] cursor-pointer text-black">
                      {initialText}
                    </div>

                    <input
                      {...register('extension_name')}
                      type="text"
                      id="text"
                      className="border border-[#E5E7EB] text-base rounded-lg placeholder:text-[#B7BABF] placeholder:text-sm focus:border-[#3976E8] focus:ring-0 focus:outline-none block w-full px-3 py-2.5"
                      style={{ paddingLeft }}
                      placeholder="Business Extention"
                      required
                    />
                  </div>

                  <p className="text-brand-red">
                    {errors.extension_name?.message}
                  </p>
                </div>

                <div className="flex space-x-4">
                  <Button
                    loading={isLoading}
                    type="submit"
                    btnColor="default"
                    label="Create"
                    size="block"
                    className={clsx('h-[40px] rounded-[2px]')}
                  />
                </div>
              </form>
            </div>
          </>
        </Modal>
      )}

      {/* : isEmpty(virtualAccounDeposits) ? (
                <>
                  <p className="font-interMedium text-center text-2xl">
                    No Transaction Yet
                  </p>
                </>
              ) */}
    </DashboardLayout>
  );
}
