/* eslint-disable consistent-return */
/* eslint-disable object-curly-newline */
/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable no-console */
/* eslint-disable import/prefer-default-export */
import { yupResolver } from '@hookform/resolvers/yup';
// import { useLocalStorage } from 'usehooks-ts';
import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { useForm } from 'react-hook-form';
import { Link, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import * as yup from 'yup';
// import { login, verifyInvite } from '../api/Auth';
import { Show, Hide } from '../icons';
import { Button, Card, Text, Input } from '../components/atoms';
import { useAuth } from '../context';
import { AuthLayout } from '../components/layout/authLayout';
import { login } from '../context/Actions/auth';
// import storageName from '../utils/storageHelpers';

const symbols = new RegExp(process.env.REACT_APP_PASSWORD_VALIDATION, 'g');

const schema = yup.object().shape({
  email: yup.string().email().required('Email is required'),
  password: yup
    .string()
    .required('Please enter a password')
    .min(8, 'Password too short')
    .max(24, 'Password too long')
    .matches(symbols, process.env.REACT_APP_PASSWORD_VALIDATION_MESSAGE),
});

export function Login() {
  const queryParams = new URLSearchParams(window.location.search);
  const { signIn, handleVerifyInvite } = useAuth();
  const [isLoading, setIsLoading] = useState(false);
  const [showPassword, setShowpassword] = useState(false);
  const [inviteToken, setInviteToken] = useState();
  const navigate = useNavigate();
  // handle validation
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm({ resolver: yupResolver(schema), mode: 'onChange' });

  const handleLogin = async (data) => {
    try {
      const redirect = queryParams.get('redirect_url');
      setIsLoading(true);
      const res = await login(data);
      await signIn(res, () => {
        toast.success(res?.data?.message);
        if (res?.data?.response === true) {
          if (redirect === 'invite') return navigate('/settings?tab=2');
          navigate('/dashboard');
        }
      });
    } catch (error) {
      if (process.env.NODE_ENV !== 'production') {
        console.log('login--> error', error);
      }
    } finally {
      setIsLoading(false);
    }
  };

  // verify invite token
  useEffect(() => {
    try {
      if (inviteToken) {
        (async () => {
          await handleVerifyInvite(inviteToken, (error, result) => {
            if (error) {
              return;
            }
            setValue('email', result.data.user.email);
          });
        })();
      }
    } catch (err) {
      toast.error('Something went wrong');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inviteToken]);

  useEffect(() => {
    setInviteToken(queryParams.get('invite'));
  }, []);

  return (
    <AuthLayout>
      <Helmet>
        <title>FundR | Login</title>
      </Helmet>
      <Card backgroundColor="white">
        <Text
          textColor="#04004D"
          design="flex justify-center md:text-xl text-lg font-interMedium md:font-interSemiBold md:mb-6 mb-2"
        >
          Login to your account
        </Text>
        <form onSubmit={handleSubmit(handleLogin)}>
          <Input
            {...register('email')}
            label="Work Email"
            type="email"
            placeholder="janedoe@gmail.com"
            borderColor="border-[#E6EAEE]"
            required
            error={errors.email?.message}
          />
          <Input
            {...register('password')}
            label="Password"
            type={showPassword ? 'text' : 'password'}
            placeholder="Enter password"
            borderColor="border-[#E6EAEE]"
            required
            error={errors.password?.message}
            icon={
              !showPassword ? (
                <Show
                  className="mr-2"
                  onClick={() => {
                    setShowpassword(true);
                  }}
                />
              ) : (
                <Hide
                  className="mr-2"
                  onClick={() => {
                    setShowpassword(false);
                  }}
                />
              )
            }
          />
          <div className="mt-6 mb-3">
            <Button
              loading={isLoading}
              isDisabled={isLoading}
              type="submit"
              label="Log in"
              btnColor="default"
              size="block"
              custom
            />
          </div>
        </form>
        <div>
          <Text
            textColor="#04004D"
            design="flex justify-center text-xs font-inter mb-4 whitespace-nowrap"
          >
            Don’t have an account?{' '}
            <Link to="/register">
              <span className="text-[#3976E8] ml-1">Sign Up</span>
            </Link>
          </Text>
          <Text
            textColor="#04004D"
            design="flex justify-center text-xs font-inter whitespace-nowrap"
          >
            Forgot password?{' '}
            <Link to="/forget-password">
              <span className="text-[#3976E8] ml-1">Reset your password</span>
            </Link>
          </Text>
        </div>
      </Card>
    </AuthLayout>
  );
}
