/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable implicit-arrow-linebreak */
/* eslint-disable operator-linebreak */
/* eslint-disable indent */
/* eslint-disable max-len */
/* eslint-disable no-nested-ternary */
/* eslint-disable react/button-has-type */
/* eslint-disable react/prop-types */
/* eslint-disable import/prefer-default-export */
/* eslint-disable function-paren-newline */
/* eslint-disable function-paren-newline,  object-curly-newline */
import React, { Fragment, useState } from 'react';
import { Menu, Transition } from '@headlessui/react';
import { useNavigate, NavLink } from 'react-router-dom';
import Avatar from 'react-avatar';
import { concat, join } from 'lodash';
import clsx from 'clsx';
import { IoCaretDown, IoReorderThreeOutline } from 'react-icons/io5';

import { Bell, Logout, MiniSupport, Profile } from '../../icons';
import { useAuth } from '../../context';
import Logo from '../../assets/images/dashLogo.png';
import Invite from '../Invite';

export function Header(props) {
  const { onClick } = props;

  const { userDetails, signOut } = useAuth();
  const navigate = useNavigate();
  const fullName = join(
    concat(userDetails?.firstName, ' ', userDetails?.lastName),
    '',
  );
  const [showInvite, setShowInvite] = useState(false);

  const handleShowInvite = () => {
    setShowInvite(true);
  };
  const handleHideInvite = () => {
    setShowInvite(false);
  };
  const handleLogout = async () => {
    await signOut('signout', true, false);
    // navigate('/');
  };
  return (
    <>
      <nav className="relative z-50 w-full bg-white border-b border-gray-200">
        <div className="px-3 py-3 lg:px-9">
          <div className="flex items-center justify-between">
            <div className="flex items-center justify-start col-span-4">
              <IoReorderThreeOutline
                className="w-10 h-10 lg:hidden"
                onClick={onClick}
              />
              <a
                href="/dashboard"
                className="flex object-cover ml-2 md:mr-24 lg:hidden"
              >
                <img src={Logo} className="h-6 w-[95px] mr-3" alt="Logo" />
              </a>
            </div>
            <div className="flex items-center col-end-7 col-span-2">
              <div className="flex items-center ml-3">
                <div className="flex items-center">
                  <div className={clsx('relative mx-6 cursor-pointer')}>
                    <Bell className={clsx('h-6 w-6 ')} />
                    <div
                      className={clsx(
                        'absolute top-0 right-0 rounded-full w-2 h-2 border-1 border-white bg-red-500',
                      )}
                    />
                  </div>

                  <div className="relative inline-block text-right">
                    <Menu as="div" className="relative inline-block text-left">
                      <div>
                        <Menu.Button className="p-2.5 items-center inline-flex w-full cursor-pointer">
                          <div className="flex items-center pr-2">
                            {userDetails?.avatar_url ? (
                              <img
                                src={userDetails?.avatar_url}
                                alt="profileImage"
                                className="object-cover w-8 h-8 rounded-full sm:w-12 sm:h-12"
                              />
                            ) : (
                              <Avatar
                                name={fullName}
                                size="40px"
                                className="rounded-full"
                              />
                            )}
                          </div>
                          <IoCaretDown className={clsx('h-3 w-3')} />
                        </Menu.Button>
                      </div>

                      <Transition
                        as={Fragment}
                        enter="transition ease-out duration-100"
                        enterFrom="transform opacity-0 scale-95"
                        enterTo="transform opacity-100 scale-100"
                        leave="transition ease-in duration-75"
                        leaveFrom="transform opacity-100 scale-100"
                        leaveTo="transform opacity-0 scale-95"
                      >
                        <Menu.Items className="absolute right-0 z-10 w-72 mt-2 origin-top-right bg-white rounded-md shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                          <div className="py-1">
                            <Menu.Item>
                              <div className="flex items-stretch px-4 py-2 border-b border-brand-border">
                                <Avatar
                                  name={fullName}
                                  size="40px"
                                  className="object-cover w-10 h-10 rounded-full"
                                />
                                <div className="ml-3 grow">
                                  <p className="capitalize font-interSemiBold text-brand-black break-al">
                                    {fullName}
                                  </p>
                                  <p className="text-sm break-all text-brand-ghostwhite">
                                    {userDetails?.email}
                                  </p>
                                </div>
                              </div>
                            </Menu.Item>
                            <Menu.Item>
                              <div
                                className="flex items-center cursor-pointer px-4 py-2 hover:bg-[#EDF3FD] hover:text-sm hover:font-interSemiBold hover:text-brand-black hover:mx-2 mx-2 hover:rounded-lg  hover:px-4 hover:py-2"
                                onClick={() => {
                                  navigate('/settings');
                                }}
                              >
                                <Profile />
                                <p
                                  className="ml-4 text-sm font-inter text-brand-ghostwhite "
                                  tabIndex="-1"
                                  id="menu-item-0"
                                >
                                  View profile
                                </p>
                              </div>
                            </Menu.Item>
                            <Menu.Item>
                              <div
                                className="flex items-center cursor-pointer px-4 py-2 hover:bg-[#EDF3FD] hover:text-sm hover:font-interSemiBold hover:text-brand-black hover:mx-2 mx-2 hover:rounded-lg  hover:px-4 hover:py-2"
                                onClick={() => {
                                  navigate('/settings');
                                }}
                              >
                                <MiniSupport />
                                <p
                                  className="block ml-4 text-sm text-brand-ghostwhite"
                                  tabIndex="-1"
                                  id="menu-item-1"
                                >
                                  Support
                                </p>
                              </div>
                            </Menu.Item>
                            {userDetails?.roles?.length &&
                              userDetails?.roles?.includes('owner') && (
                                <Menu.Item onClick={handleShowInvite}>
                                  <div className="flex items-center cursor-pointer px-4 py-2 hover:bg-[#EDF3FD] hover:text-sm hover:font-interSemiBold hover:text-brand-black hover:mx-2 mx-2 hover:rounded-lg  hover:px-4 hover:py-2">
                                    <Profile />
                                    <p
                                      href="#"
                                      className="block ml-4 text-sm text-brand-ghostwhite"
                                      tabIndex="-1"
                                      id="menu-item-1"
                                    >
                                      Invite a member
                                    </p>
                                  </div>
                                </Menu.Item>
                              )}
                            <Menu.Item
                              onClick={() => {
                                handleLogout();
                                // signOut('signout', );
                              }}
                            >
                              <div className="flex items-center cursor-pointer px-4 py-2 hover:bg-[#EDF3FD] hover:text-sm hover:font-interSemiBold hover:text-brand-black hover:mx-2 mx-2 hover:rounded-lg hover:px-4 hover:py-2">
                                <Logout />
                                <NavLink
                                  to="/login"
                                  className="block ml-4 text-sm text-brand-ghostwhite"
                                  tabIndex="-1"
                                  id="menu-item-3"
                                >
                                  Logout
                                </NavLink>
                              </div>
                            </Menu.Item>
                          </div>
                        </Menu.Items>
                      </Transition>
                    </Menu>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </nav>
      <Invite isVisible={showInvite} onClose={handleHideInvite} />
    </>
  );
}

// export default Header;
