/* eslint-disable no-console */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable wrap-iife */
/* eslint-disable operator-linebreak, object-curly-newline */
import { yupResolver } from '@hookform/resolvers/yup';
import {
  first,
  forEach,
  isEmpty,
  isNil,
  join,
  merge,
  pick,
  pickBy,
  split,
} from 'lodash';
import clsx from 'clsx';
import React, { useEffect, useMemo, useState } from 'react';
import { useForm, useWatch } from 'react-hook-form';
import useFormPersist from 'react-hook-form-persist';
import { useNavigate } from 'react-router-dom';
import { useDebounce } from 'usehooks-ts';
import * as yup from 'yup';
import { IoArrowBack } from 'react-icons/io5';
import { toast } from 'react-toastify';

import {
  Button,
  Input,
  Select,
  FileUploadForm,
  Radio,
} from '../../atoms/index';
import { DojahVerification } from '../../DojahVerification';
import OnboardLayout from '../../layout/onboardingLayout';
import { ColorCross } from '../../../icons';
import storageName from '../../../helpers/storageHelpers';
import useAuth from '../../../context/Auth';
import { useUser } from '../../../context';

const nubanRegex = new RegExp(
  process.env.REACT_APP_BANK_ACCOUNT_VALIDATION,
  'g',
);
const bvnRegex = new RegExp(process.env.REACT_APP_BVN_VALIDATION, 'g');

const schema = yup.object().shape({
  business_name: yup
    .string()
    .min(3, 'business name too short')
    .max(100, 'business name too long'),
  business_type: yup.string().oneOf(['full_registration']),
  business_rc_bn_number: yup
    .string()
    .min(3, 'tax id too short')
    .max(100, 'tax id too long'),
  tax_id: yup.string().min(3, 'tax id too short').max(100, 'tax id too long'),
  business_address: yup
    .string()
    .min(3, 'business address too short')
    .max(200, 'business address too long'),
  proof_of_address_url: yup.string().url().required(),
  business_number_of_directors: yup
    .number()
    .min(1, 'number of directors must be 1 or more')
    .max(100, 'number of directors must be 100 or less'),
  bvn_of_owner_or_directors: yup.object({
    verificationValue: yup
      .string()
      .min(11, 'bvn too short')
      .max(11, 'bvn too long')
      .matches(bvnRegex, 'bvn is invalid')
      .required('please enter bvn'),
  }),
  government_issue_id: yup.object({
    verificationType: yup
      .string()
      .required('please enter government issued id'),
  }),
  business_account: yup.object({
    bank_code: yup
      .string()
      .min(3, 'bank code is too short')
      .max(6, 'bank code is too long'),
    bank_name: yup
      .string()
      .min(2, 'bank name is too short')
      .max(150, 'bank name is too long'),
    nuban: yup
      .string()
      .min(10, 'bank account number too short')
      .max(10, 'bank account number too long')
      .matches(nubanRegex, 'bank account number invalid'),
    account_name: yup
      .string()
      .min(2, 'bank account name is too short')
      .max(150, 'bank account name is too long'),
    has_account: yup.string().required('please select bank account status'),
    bvn: yup
      .string()
      .min(11, 'bvn too short')
      .max(11, 'bvn too long')
      .matches(bvnRegex, 'bvn is invalid'),
  }),
});
// .matches(nubanRegex, 'bank Account is invalid')

function CompanyInfo() {
  const { kycDetails, handleAuthenticateUser } = useAuth();
  const {
    handleIsLoading,
    handleValidateBankAccount,
    handleCreateBusinesskycDraft,
    banks,
    isLoading,
  } = useUser();

  const {
    control,
    register,
    handleSubmit,
    setValue,
    watch,
    getValues,
    clearErrors,
    setError,
    formState: { errors },
  } = useForm({ resolver: yupResolver(schema) });
  useFormPersist(storageName.business_creation, {
    watch,
    setValue,
    storage: window.localStorage,
  });
  const [showbank, setShowBank] = useState();
  const navigate = useNavigate();

  const nuban = useWatch({
    control,
    name: 'business_account.nuban',
  });

  const proofOfAddressUrl = useWatch({
    control,
    name: 'proof_of_address_url',
  });

  const debouncedAccountNumberValue = useDebounce(nuban, 500);
  const [bankCodeValue, setBankCodeValue] = useState('');
  const debouncedBankCodeValue = useDebounce(bankCodeValue, 500);

  const setPoa = (res) => {
    setValue('proof_of_address_url', res);
  };

  const setHasAccount = (event) => {
    if (event.target.value === 'true') {
      setValue('business_account.has_account', true);
      setShowBank(true);
    }
    if (event.target.value === 'false') {
      setShowBank(false);
      setValue('business_account', {});
      setValue('business_account.has_account', false);
    }
  };

  const handleBankCodeChange = (event) => {
    const { label, value } = first(event.target.selectedOptions);
    setBankCodeValue(value);
    setValue('business_account.bank_name', label);
    setValue('business_account.bank_code', value);
  };

  const getDojahBvn = (data) => {
    setValue(
      'bvn_of_owner_or_directors',
      merge(
        {},
        kycDetails?.kyc?.bvn_of_owner_or_directors ?? {},
        pickBy(
          {
            verificationType: data?.verificationType,
            verificationMode: data?.verificationMode,
            verificationValue:
              data?.governmentData?.bvn ?? data?.verificationValue,
            status: data?.status,
          },
          (value) => !isNil(value) || !isEmpty(value),
        ),
      ),
    );
    setValue(
      'business_account.bvn',
      data?.governmentData?.bvn ?? data?.verificationValue,
    );
  };

  const getGovtId = (data) => {
    setValue(
      'government_issue_id',
      merge(
        {},
        kycDetails?.kyc?.government_issue_id ?? {},
        pickBy(
          {
            verificationType: data?.verificationType,
            idUrl: data?.idUrl,
            verificationMode: data?.verificationMode,
            verificationValue: data?.verificationValue,
            status: data?.status,
          },
          (value) => !isNil(value) || !isEmpty(value),
        ),
      ),
    );
  };

  const handleBusinessSubmit = async (values) => {
    try {
      handleIsLoading(true);
      await handleCreateBusinesskycDraft(values, (error) => {
        if (error) {
          toast.error(
            error?.response?.data?.message ?? 'kyc information save failed',
          );
          return;
        }
        toast.success('kyc information saved successfully');
        navigate('/onboard/company/documentation');
      });
      // toast.success('kyc saved successfully');
    } catch (error) {
      toast.error(
        error?.response?.data?.message ?? 'kyc information save failed',
      );
      if (process.env.NODE_ENV === 'development') {
        console.log('handle company information ======>>> error', error);
      }
    } finally {
      handleIsLoading(false);
      await handleAuthenticateUser();
    }
  };

  const parsedBanks = useMemo(() => {
    const results = [];

    forEach(banks, (value) => {
      results.push({
        key: value.name,
        value: value.code,
      });
    });
    results.unshift({ key: 'Select Bank', value: '' });
    return results;
  }, [banks]);

  useEffect(() => {
    (async () => {
      const handleError = () => {
        setError('business_account.nuban', {
          message: 'bank account entered is invalid',
          type: 'manual',
        });
        setValue('business_account.account_name', null);
        // setIsDisabled(true);
      };
      try {
        if (
          !isEmpty(debouncedBankCodeValue) &&
          !isEmpty(debouncedAccountNumberValue) &&
          debouncedAccountNumberValue.length === 10
        ) {
          const data = {
            bank_code: debouncedBankCodeValue,
            nuban: debouncedAccountNumberValue,
          };
          handleIsLoading(true);

          await handleValidateBankAccount(data, (error, result) => {
            if (error) {
              handleError();
              return;
            }
            setValue(
              'business_account.account_name',
              result.data.account.account_name,
            );
            setValue('business_account.has_account', true);
            handleIsLoading(false);
            clearErrors('business_account.nuban');
          });
        }
      } catch (error) {
        handleError();
        if (process.env.NODE_ENV !== 'production') {
          console.log(
            'validate bank account ------->>> error ======>>> ',
            error,
          );
        }
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedBankCodeValue, debouncedAccountNumberValue]);

  useEffect(() => {
    if (isNil(kycDetails?.kyc) || isEmpty(kycDetails?.kyc)) {
      return;
    }

    setValue('business_name', kycDetails?.kyc?.business_details?.business_name);
    setValue(
      'business_type',
      getValues('business_type') ??
        kycDetails?.kyc?.business_details?.business_type,
    );
    setValue(
      'business_rc_bn_number',
      kycDetails?.kyc?.business_details?.business_rc_bn_number,
    );
    setValue('tax_id', kycDetails?.kyc?.business_details?.tax_id);
    setValue('business_address', kycDetails?.kyc?.business_address);
    setValue(
      'business_number_of_directors',
      kycDetails?.kyc?.business_details?.business_number_of_directors,
    );

    setValue(
      'bvn_of_owner_or_directors',
      pick(kycDetails?.kyc?.business_details?.bvn_of_owner_or_directors, [
        'verificationUrl',
        'verificationStatus',
        'verificationMode',
        'verificationType',
        'verificationValue',
      ]),
    );

    setValue(
      'government_issue_id',
      pick(kycDetails?.kyc?.business_details?.government_issue_id, [
        'status',
        'verificationStatus',
        'verificationMode',
        'verificationType',
        'verificationValue',
        'idUrl',
      ]),
    );

    setValue('proof_of_address_url', kycDetails?.kyc?.proof_of_address_url);

    setValue(
      'business_account.account_name',
      kycDetails?.kyc?.bank_account?.account_name,
    );

    setValue(
      'business_account.bank_code',
      kycDetails?.kyc?.bank_account?.bank_code,
    );
    setValue(
      'business_account.has_account',
      kycDetails?.kyc?.bank_account?.has_account,
    );

    setValue('business_account.nuban', kycDetails?.kyc?.bank_account?.nuban);
    setValue(
      'business_account.bank_name',
      kycDetails?.kyc?.bank_account?.bank_name,
    );

    setValue(
      'business_account.bvn',
      kycDetails?.kyc?.business_details?.bvn_of_owner_or_directors
        ?.verificationValue ?? kycDetails?.kyc?.bank_account?.bvn,
    );
    setBankCodeValue(
      getValues('business_account.bank_code') ??
        kycDetails?.kyc?.bank_account?.bank_code,
    );
    setShowBank(
      getValues('business_account.has_account') ??
        kycDetails?.kyc?.bank_account?.has_account,
    );

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <OnboardLayout>
      <div className="lg:px-16 pl-12 pr-8 xl:w-[65%] mx-auto">
        <div
          className="sm:mt-[5rem] mt-24 text-base text-[#292D32] font-interMedium flex items-center gap-x-2 lg:mb-5 mb-3 cursor-pointer"
          onClick={() => navigate(-1)}
          role="button"
          onKeyDown={() => {}}
          tabIndex={-1}
        >
          <IoArrowBack className={clsx('w-5 h-5 text-[#3976E8]')} />
          <span className="hidden md:block text-[#3976E8]">
            Back to Dashboard
          </span>
        </div>

        <div className="flex flex-col py-6  mx-2 xl:w[60rem]">
          <p className="mb-10 text-xl font-interSemiBold">Registered Company</p>
          <form
            className="lg:w-[30rem] gap-x-10"
            onSubmit={handleSubmit(handleBusinessSubmit)}
          >
            <Input
              {...register('business_name')}
              label="Company Name"
              type="text"
              placeholder="Enter Your Company Name"
              borderColor="border-[#E6EAEE]"
              required
              error={errors.business_name?.message}
            />
            <Input
              {...register('business_rc_bn_number')}
              label="RC Number"
              type="text"
              placeholder="Enter Your RC Number"
              borderColor="border-[#E6EAEE]"
              required
              error={errors.business_rc_bn_number?.message}
            />
            <Input
              {...register('tax_id')}
              label="Tax ID"
              type="text"
              placeholder="Enter Your Tax ID"
              borderColor="border-[#E6EAEE]"
              required
              error={errors.tax_id?.message}
            />
            <Input
              {...register('business_address')}
              label="Company Address"
              type="text"
              placeholder="Enter Your Company Address"
              borderColor="border-[#E6EAEE]"
              required
              error={errors.business_address?.message}
            />
            <Input
              {...register('business_number_of_directors')}
              label="Number of directors with majority shareholdings"
              type="text"
              placeholder="Enter Your Company Address"
              borderColor="border-[#E6EAEE]"
              required
              error={errors.business_number_of_directors?.message}
            />
            <div
              className={clsx('mb-4', {
                cursor_not_allowed: getValues('bvn_of_owner_or_directors'),
                cursor_pointer: !getValues('bvn_of_owner_or_directors'),
              })}
            >
              <label
                htmlFor="bvn"
                className="block mb-1.5 text-base tracking-tight font-inter text-[#344054] leading-5"
              >
                BVN of Business Owner
              </label>
              <div
                className={clsx(
                  'border  rounded-lg',
                  !getValues('bvn_of_owner_or_directors')
                    ? 'border-[#E6EAEE] bg-[#F2F2F2]'
                    : 'border-brand-green bg-brand-green/50 cursor',
                )}
              >
                <DojahVerification
                  isDisabled={!isEmpty(getValues('bvn_of_owner_or_directors'))}
                  successData={getDojahBvn}
                >
                  <p className="text-sm text-[#919191] flex items-center justify-center">
                    <ColorCross className={clsx('mx-2 h-6')} />
                    <span>
                      {!getValues('bvn_of_owner_or_directors')
                        ? 'Click to add'
                        : 'Details Added'}
                    </span>
                  </p>
                </DojahVerification>
              </div>
              <p className="capitalize text-brand-red">
                {join(
                  split(errors.bvn_of_owner_or_directors?.message, '_'),
                  ' ',
                )}
              </p>
            </div>
            <div
              className={clsx('mb-4', {
                cursor_not_allowed: getValues('government_issue_id'),
                cursor_pointer: !getValues('government_issue_id'),
              })}
            >
              <label
                htmlFor="bvn"
                className="block mb-1.5 text-base tracking-tight font-inter text-[#344054] leading-5"
              >
                Upload Government Issued ID of Directors
              </label>
              <div
                className={[
                  'border rounded-lg cursor-pointer',
                  !getValues('government_issue_id')
                    ? 'border-[#E6EAEE] bg-[#F2F2F2]'
                    : 'border-brand-green bg-brand-green/50 cursor',
                ].join(' ')}
              >
                <DojahVerification
                  isDisabled={getValues('government_issue_id')}
                  useWidget="govtId"
                  successData={getGovtId}
                >
                  <p className="text-sm text-[#919191] flex items-center justify-center">
                    <ColorCross className={clsx('mx-2 h-6')} />
                    <span>
                      {!getValues('government_issue_id')
                        ? 'Click to Upload'
                        : 'Details Added'}
                    </span>
                  </p>
                </DojahVerification>
              </div>
              <p className="capitalize text-brand-red">
                {join(split(errors.government_issue_id?.message, '_'), ' ')}
              </p>
            </div>
            <FileUploadForm
              label="Proof of Address"
              response={setPoa}
              persisted={proofOfAddressUrl}
              error={errors.proof_of_address_url?.message}
            />
            <div>
              <p className="text-base ext-[#344054] font-inter mb-2">
                Do you have an existing bank account?
              </p>
              <div className="flex items-center gap-x-12">
                <Radio
                  label="Yes"
                  checked={showbank === true}
                  value={showbank}
                  onChange={setHasAccount}
                />
                <Radio
                  label="No"
                  checked={showbank === false}
                  value={showbank}
                  onChange={setHasAccount}
                />
              </div>

              {showbank === true && (
                <div className="border border-[#213F7D0F] py-8 px-[30px] rounded-lg">
                  <Select
                    onChange={handleBankCodeChange}
                    placeholder="Select Bank"
                    value={bankCodeValue}
                    options={parsedBanks}
                    required
                    error={errors.business_account?.bank_code?.message}
                  />
                  <Input
                    {...register('business_account.nuban')}
                    type="text"
                    placeholder="Account Number"
                    borderColor="border-[#E6EAEE]"
                    required
                    length={10}
                    autoComplete="off"
                    error={errors.business_account?.nuban?.message}
                  />
                  {/* <div className="hidden">
                    <Input
                      {...register('business_account.bvn')}
                      length={11}
                      type="text"
                      placeholder="BVN"
                      borderColor="border-[#E6EAEE]"
                      required
                      disabled
                      error={errors.business_account?.bvn?.message}
                    />
                  </div> */}
                  {getValues('business_account.account_name') && (
                    <Input
                      {...register('business_account.account_name')}
                      type="text"
                      placeholder="Account Name"
                      borderColor="border-[#E6EAEE]"
                      required
                      disabled
                      error={errors.business_account?.account_name?.message}
                    />
                  )}
                </div>
              )}
            </div>
            <div className="mt-10">
              <Button
                loading={isLoading}
                // isDisabled={isDisabled}
                // isDisabled={isDisabled}
                // onClick={handleSubmit}
                type="submit"
                label="Save and Continue"
                btnColor="default"
                size="block"
                className={clsx('h-[55px]')}
              />
            </div>
          </form>
        </div>
      </div>
    </OnboardLayout>
  );
}

export default CompanyInfo;
