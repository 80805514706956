/* eslint-disable camelcase */
/* eslint-disable jsx-a11y/interactive-supports-focus */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable implicit-arrow-linebreak */
/* eslint-disable operator-linebreak */
/* eslint-disable indent */
/* eslint-disable max-len */
/* eslint-disable no-nested-ternary */
/* eslint-disable react/button-has-type */
/* eslint-disable react/prop-types */
/* eslint-disable import/prefer-default-export */
/* eslint-disable function-paren-newline */
/* eslint-disable function-paren-newline,  object-curly-newline */

import React, { Fragment, useState, useEffect } from 'react';
import { Menu, Transition } from '@headlessui/react';
import { initFlowbite } from 'flowbite';
import { useLocation, useNavigate, NavLink } from 'react-router-dom';
import Avatar from 'react-avatar';
import { includes, map, values, concat, join } from 'lodash';
import clsx from 'clsx';
import { IoCheckmarkCircle } from 'react-icons/io5';

import useAuth from '../../context/Auth';
import Logo from '../../assets/images/dashLogo.png';
import {
  Bell,
  Logout,
  MiniSupport,
  Dropdown,
  ActiveDashboard,
  Dashboard,
} from '../../icons';
import { Loader } from '../Loader';

function OnboardLayout({ children }) {
  const { userDetails, signOut, isLoading } = useAuth();
  const navigate = useNavigate();
  const fullName = join(
    concat(userDetails?.firstName, ' ', userDetails?.lastName),
    '',
  );

  const { pathname } = useLocation();
  const [businessType, setBusinessType] = useState('unregistered');

  const handleLogout = () => {
    signOut();
    navigate('/');
  };

  useEffect(() => {
    initFlowbite();
  }, []);

  useEffect(() => {
    const business_type = pathname.split('/')[2];
    setBusinessType(business_type);
  }, [pathname]);

  const infoRoute = [
    '/onboard/unregistered/information',
    '/onboard/business/information',
    '/onboard/company/information',
  ];
  const docuRoute = [
    '/onboard/unregistered/documentation',
    '/onboard/business/documentation',
    '/onboard/company/documentation',
  ];

  const reviewRoute = [
    '/onboard/unregistered/review',
    '/onboard/business/review',
    '/onboard/company/review',
  ];

  function routeParser(business_type, routesArray) {
    return routesArray.find((route) => includes(route, business_type));
  }

  const routes = {
    information: {
      route: routeParser(businessType, infoRoute),
      title: 'Business Information',
      hidden: false,
      disabled: false,
      icon: Dashboard,
      activeIcon: ActiveDashboard,
      isActive: includes(infoRoute, pathname),
      allowed: true,
    },
    document: {
      route: routeParser(businessType, docuRoute),
      title: 'Business Documentation',
      hidden: false,
      disabled: false,
      icon: Dashboard,
      activeIcon: ActiveDashboard,
      isActive: includes(docuRoute, pathname),
      allowed: true,
    },
    review: {
      route: routeParser(businessType, reviewRoute),
      title: 'Review and Submit',
      hidden: false,
      disabled: false,
      icon: Dashboard,
      activeIcon: ActiveDashboard,
      isActive: includes(reviewRoute, pathname),
      allowed: true,
    },
  };

  return (
    <>
      <Loader isActive={isLoading} />
      <main className="h-screen  bg-[#fafafe]">
        <nav className="fixed top-0 z-50 w-full border-b border-gray-200  bg-[#fafafe]">
          <div className="px-3 py-3 lg:px-9">
            <div className="grid md:grid-cols-2 grid-cols-6">
              <div className="flex items-center justify-start col-span-4">
                <button
                  data-drawer-target="drawer-example"
                  data-drawer-show="drawer-example"
                  aria-controls="drawer-example"
                  type="button"
                  className="inline-flex items-center p-2 text-sm text-gray-500 rounded-lg sm:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200 dark:text-gray-400 dark:hover:bg-gray-700 dark:focus:ring-gray-600"
                >
                  <span className="sr-only">Open sidebar</span>
                  <svg
                    className="w-6 h-6"
                    aria-hidden="true"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      clipRule="evenodd"
                      fillRule="evenodd"
                      d="M2 4.75A.75.75 0 012.75 4h14.5a.75.75 0 010 1.5H2.75A.75.75 0 012 4.75zm0 10.5a.75.75 0 01.75-.75h7.5a.75.75 0 010 1.5h-7.5a.75.75 0 01-.75-.75zM2 10a.75.75 0 01.75-.75h14.5a.75.75 0 010 1.5H2.75A.75.75 0 012 10z"
                    />
                  </svg>
                </button>
                <a
                  href="/dashboard"
                  className="flex object-cover ml-2 md:mr-24"
                >
                  <img src={Logo} className="h-6 w-[100px] mr-3" alt="Logo" />
                </a>
              </div>
              <div className="flex items-center col-end-7 col-span-2">
                <div className="flex items-center ml-3">
                  <div className="flex items-center">
                    <Bell />
                    <div className="relative inline-block text-right">
                      <Menu
                        as="div"
                        className="relative inline-block text-left"
                      >
                        <div>
                          <Menu.Button className="p-2.5 items-center inline-flex w-full cursor-pointer">
                            <div className="flex items-center pr-2">
                              {userDetails?.avatar_url ? (
                                <img
                                  src={userDetails?.avatar_url}
                                  alt="profileImage"
                                  className="object-cover w-8 h-8 rounded-full sm:w-12 sm:h-12"
                                />
                              ) : (
                                <Avatar
                                  name={fullName}
                                  size="40px"
                                  className="rounded-full"
                                />
                              )}
                            </div>
                            <Dropdown />
                          </Menu.Button>
                        </div>

                        <Transition
                          as={Fragment}
                          enter="transition ease-out duration-100"
                          enterFrom="transform opacity-0 scale-95"
                          enterTo="transform opacity-100 scale-100"
                          leave="transition ease-in duration-75"
                          leaveFrom="transform opacity-100 scale-100"
                          leaveTo="transform opacity-0 scale-95"
                        >
                          <Menu.Items className="absolute right-0 z-10 w-64 mt-2 origin-top-right bg-white rounded-md shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                            <div className="py-1">
                              <Menu.Item>
                                <div className="flex items-stretch px-4 py-2 border-b border-brand-border">
                                  <Avatar
                                    name={fullName}
                                    size="40px"
                                    className="object-cover w-10 h-10 rounded-full"
                                  />
                                  <div className="ml-3 grow">
                                    <p className="capitalize font-interSemiBold text-brand-black break-al">
                                      {fullName}
                                    </p>
                                    <p className="text-sm break-all text-brand-ghostwhite">
                                      {userDetails?.email}
                                    </p>
                                  </div>
                                </div>
                              </Menu.Item>
                              <Menu.Item>
                                <div
                                  className="flex items-center cursor-pointer px-4 py-2 hover:bg-[#EDF3FD] hover:text-sm hover:font-interSemiBold hover:text-brand-black hover:mx-2 mx-2 hover:rounded-lg  hover:px-4 hover:py-2"
                                  onClick={() => {
                                    navigate('/settings');
                                  }}
                                  role="button"
                                >
                                  <MiniSupport />
                                  <p
                                    className="block ml-4 text-sm text-brand-ghostwhite"
                                    tabIndex="-1"
                                    id="menu-item-1"
                                  >
                                    Support
                                  </p>
                                </div>
                              </Menu.Item>

                              <Menu.Item
                                onClick={() => {
                                  handleLogout();
                                }}
                              >
                                <div className="flex items-center cursor-pointer px-4 py-2 hover:bg-[#EDF3FD] hover:text-sm hover:font-interSemiBold hover:text-brand-black hover:mx-2 mx-2 hover:rounded-lg hover:px-4 hover:py-2">
                                  <Logout />
                                  <NavLink
                                    to="/login"
                                    className="block ml-4 text-sm text-brand-ghostwhite"
                                    role="menuitem"
                                    tabIndex="-1"
                                    id="menu-item-3"
                                  >
                                    Logout
                                  </NavLink>
                                </div>
                              </Menu.Item>
                            </div>
                          </Menu.Items>
                        </Transition>
                      </Menu>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </nav>
        {/*  */}
        <div
          id="drawer-example"
          className="fixed top-0 bg-white lg:bg-[#fafafe] left-0 z-40 md:w-[19rem] lg:w-80 h-screen pt-20 pl-4 lg:pl-20 transition-transform -translate-x-full sm:translate-x-0"
          aria-label="Sidebar"
        >
          <div className="border-l-4 border-gray-200 mt-16">
            {/* mt-8 space-y-2 font-medium  ul class */}
            <ul className="flex flex-col  -ml-[4px] overflow-x-scroll text-sm font-medium whitespace-nowrap scrollbar-hide snap-proximity snap-center ">
              {map(values(routes), (route, idx) => (
                <li
                  key={idx}
                  className={clsx({
                    hidden: !route.allowed || route?.hidden,
                    'pointer-events-auto': !route.allowed,
                  })}
                >
                  <NavLink
                    onClick={(e) => {
                      if (route?.disabled) {
                        e.preventDefault();
                      }
                    }}
                    to={route?.route}
                    className={
                      ({ isActive }) =>
                        // eslint-disable-next-line implicit-arrow-linebreak
                        clsx(
                          'flex items-center font-inter text-base leading-[22.5px] font-normal tracking-tight px-8 py-3.5',
                          'text-[#060809] ',
                          {
                            'bg-gray-400 text-gray-600 cursor-not-allowed hover:bg-gray-400 hover:!text-gray-600':
                              route.disabled,
                            'border-l-4 border-blue-600 text-[#060809] font-interMedium text-base':
                              isActive || route.isActive,
                          },
                        )
                      // eslint-disable-next-line react/jsx-curly-newline
                    }
                  >
                    <span className="ml-3 capitalize">{route?.title}</span>
                  </NavLink>
                </li>
              ))}
            </ul>
          </div>

          <div className="bg-[#0B182E] p-6 lg:mt-6 rounded-lg w-[267px] fixed bottom-[3px] hidden md:block">
            <p className="text-white font-interMedium">
              {' '}
              Tips for uploading documents
            </p>

            <ul className="max-w-md text-white mt-4">
              <li className="flex items-center h-[48px]">
                <IoCheckmarkCircle
                  className={clsx('mr-2 text-white w-4 h-4')}
                />
                <p className="text-xs">Use clear, non-blurry photos</p>
              </li>
              <li className="flex items-center  h-[48px]">
                <IoCheckmarkCircle
                  className={clsx('mr-2 text-white w-4 h-4')}
                />
                <p className="text-xs">Make sure ID numbers are visible</p>
              </li>
              <li className="flex items-center h-[48px]">
                <IoCheckmarkCircle
                  className={clsx('mr-2 text-white w-5 h-5')}
                />
                <p className="text-xs">
                  If applicable, make sure your photo is visible
                </p>
              </li>
            </ul>
          </div>
        </div>

        <div className="px-6 pt-10 lg:p-16 sm:ml-64 lg:h-full overflow-y-auto">
          {children}
        </div>
      </main>
    </>
  );
}

export default OnboardLayout;
