/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable operator-linebreak */
/* eslint-disable implicit-arrow-linebreak */
/* eslint-disable function-paren-newline */
/* eslint-disable react/button-has-type */
/* eslint-disable react/no-array-index-key */
/* eslint-disable no-console */
/* eslint-disable indent */
/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable no-nested-ternary */
import React, { useState } from 'react';
import moment from 'moment';
import { NumericFormat } from 'react-number-format';
import { Zoom, Fade } from 'react-reveal';

import { isArray, isEmpty } from 'lodash';
import { Loader } from '../Loader';
import { useUser } from '../../context';

function WithdrawalPage() {
  const { withdrawals: allWithdrawals, isLoading: pageLoading } = useUser();
  const [page, setPage] = useState(0);
  const rowsPerPage = 10; // Change this value as per your requirement

  const columns = [
    {
      name: 'Amount',
      field: 'amount',
    },
    {
      name: 'Reference',
      field: 'reference',
    },
    {
      name: 'Date',
      field: 'created_at',
    },
    {
      name: 'Time',
      field: 'created_at',
    },
    {
      name: 'Status',
      field: 'status',
    },
  ];
  const getTdContent = (columnField, row, rowIndex, colIndex) => {
    switch (columnField) {
      case 'amount':
        return (
          <td
            key={`${rowIndex}-${colIndex}`}
            className="px-6 py-4 whitespace-nowrap text-sm text-black capitalize"
          >
            <NumericFormat
              value={row[columnField]}
              decimalSeparator="."
              thousandSeparator
              displayType="text"
              prefix="₦"
            />
          </td>
        );
      case 'created_at':
        if (colIndex === 2) {
          // assuming date column is the first column
          return (
            <td
              key={`${rowIndex}-${colIndex}`}
              className="px-6 py-4 whitespace-nowrap text-sm text-gray-500"
            >
              {moment(row[columnField]).format('MMM Do YYYY')}
            </td>
          );
        }
        if (colIndex === 3) {
          // assuming time column is the second column
          return (
            <td
              key={`${rowIndex}-${colIndex}`}
              className="px-6 py-4 whitespace-nowrap text-sm text-gray-500"
            >
              {moment(row[columnField]).format('h:mm a')}
            </td>
          );
        }
        break;
      case 'status':
        return (
          <td
            key={`${rowIndex}-${colIndex}`}
            className="px-6 py-4 whitespace-nowrap text-sm text-green-500"
          >
            {row[columnField] === 'completed' ? (
              <div className="flex gap-1 justify-center px-3 py-1 whitespace-nowrap bg-[#EFFDED] border border-solid border-[color:var(--Side-colors-Green,#5DC090)] rounded-[100px] text-[#144909] max-w-[120px]">
                <div className="my-auto mr-2 w-2 h-2 bg-[#92EF80] rounded-full" />
                <div className="capitalize">{row[columnField]}</div>
              </div>
            ) : (
              <div className="flex gap-1 justify-center px-3 py-1 whitespace-nowrap bg-[#FEECEE] border border-solid border-[color:var(--Side-colors-Red,#F14156)] rounded-[100px] text-[#740613] max-w-[120px]">
                <div className="my-auto mr-2 w-2 h-2 bg-[#F14156] rounded-full " />
                <div className="capitalize">{row[columnField]}</div>
              </div>
            )}
          </td>
        );
      default:
        return (
          <td
            key={`${rowIndex}-${colIndex}`}
            className="px-6 py-4 whitespace-nowrap text-sm text-[#535379] capitalize"
          >
            {row[columnField]}
          </td>
        );
    }
  };
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  return (
    <>
      <Loader isActive={pageLoading} />
      <div className="">
        <Zoom>
          <Zoom>
            <div className="overflow-x-auto hidden lg:block border  border-gray-200 rounded-lg mt-5">
              <table className="min-w-full divide-y divide-gray-200 ">
                <thead className="bg-gray-50 h-16">
                  <tr>
                    {/* Render checkbox in the first column of the table head */}
                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      <input type="checkbox" className="form-checkbox" />
                    </th>

                    {columns.map((column) => (
                      <th
                        key={column.field}
                        className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                      >
                        {column.name}
                      </th>
                    ))}
                  </tr>
                </thead>
                <tbody className="bg-white divide-y divide-gray-200 ">
                  {isArray(allWithdrawals) &&
                    !isEmpty(allWithdrawals) &&
                    allWithdrawals
                      .slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage,
                      )
                      .map((row, rowIndex) => (
                        <tr
                          key={rowIndex}
                          className={
                            rowIndex % 2 === 0 ? ' bg-white ' : 'bg-white/70'
                          }
                        >
                          {/* Render checkbox in the first column of each table row */}
                          <td className="px-6 py-4 whitespace-nowrap text-sm text-red-500">
                            <input
                              type="checkbox"
                              className="form-checkbox rounded-sm"
                            />
                          </td>

                          {columns.map((column, colIndex) =>
                            getTdContent(column.field, row, rowIndex, colIndex),
                          )}
                        </tr>
                      ))}
                </tbody>
              </table>
            </div>
          </Zoom>
        </Zoom>
        <Zoom>
          <div className="lg:hidden">
            {isArray(allWithdrawals) &&
              !isEmpty(allWithdrawals) &&
              allWithdrawals
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, rowIndex) => (
                  <div className="mb-4 border border-[#BEBEBE33] rounded flex gap-5 justify-between flex-wrap py-4 pr-14 pl-6 bg-white  max-md:flex-wrap max-md:px-5 max-md:max-w-full">
                    <div className="border-b border-[#BEBEBE33] flex justify-between items-center w-full">
                      <p className="grow self-start  text-black ">AMOUNT : </p>
                      <p className="text-right">
                        {' '}
                        <NumericFormat
                          value={row.amount}
                          decimalSeparator="."
                          thousandSeparator
                          displayType="text"
                          prefix="₦"
                        />
                      </p>
                    </div>
                    <div className="border-b border-[#BEBEBE33] flex justify-between items-center w-full capitalize">
                      <p className="grow self-start  text-black ">
                        REFERENCE :{' '}
                      </p>
                      <p className="text-right">{row.reference}</p>
                    </div>
                    <div className="border-b border-[#BEBEBE33] flex justify-between items-center w-full">
                      <p className="grow self-start  text-black ">DATE : </p>
                      <p className="text-right">
                        {moment(row.created_at).format('MMM Do YYYY')}
                      </p>
                    </div>
                    <div className="border-b border-[#BEBEBE33] flex justify-between items-center w-full">
                      <p className="grow self-start  text-black ">TIME : </p>
                      <p className="text-right">
                        {moment(row.created_at).format('h:mm a')}
                      </p>
                    </div>
                    <div className=" flex justify-between items-center w-full">
                      <div className="grow self-start  text-black ">
                        STATUS :
                      </div>
                      <div className="flex gap-1 justify-between px-3 py-1 whitespace-nowrap bg-green-50 border border-solid border-[color:var(--Side-colors-Green,#5DC090)] rounded-[100px] text-lime-950">
                        <div className="my-auto w-2 h-2 bg-green-300 rounded-full" />
                        <div className="grow capitalize">{row.status}</div>
                      </div>
                    </div>
                  </div>
                ))}
          </div>
        </Zoom>
        <Fade right>
          <div className="flex gap-5 justify-between self-center mt-3 w-full max-w-[1053px] max-md:flex-wrap max-md:max-w-full mb-14 lg:mb-6">
            {/* Show number of results */}
            <div className="flex-auto my-auto text-slate-500 text-sm">
              Showing{' '}
              {Math.min((page + 1) * rowsPerPage, allWithdrawals?.length ?? 0)}{' '}
              of {allWithdrawals?.length ?? 0} results
            </div>

            <div className="flex gap-2 text-center whitespace-nowrap text-slate-600">
              <button
                onClick={() => handleChangePage(null, page - 1)}
                disabled={page === 0}
              >
                <img
                  loading="lazy"
                  src="https://cdn.builder.io/api/v1/image/assets/TEMP/4a89813b313caef866b316d5943f6760d234b7e20e9b9becd54879ffd7ef2bc6?apiKey=bb278f95022541509fa4b766a9320e21&"
                  className="w-8 aspect-square"
                  alt="left"
                />
              </button>
              {Array.from(
                Array(
                  Math.ceil((allWithdrawals?.length ?? 0) / rowsPerPage),
                ).keys(),
              ).map((pageNumber) => (
                <button
                  className={`justify-center items-center h-8 ${
                    page === pageNumber
                      ? 'text-blue-600 border-[color:var(--Main-blue,#1F62FF)]'
                      : 'border-[color:var(--Border-bd-dark,#DADAE7)]'
                  }  bg-white rounded border border-solid aspect-square `}
                  key={pageNumber}
                  onClick={() => setPage(pageNumber)}
                  disabled={page === pageNumber}
                >
                  {pageNumber + 1}
                </button>
              ))}

              <button
                onClick={() => handleChangePage(null, page + 1)}
                disabled={
                  page ===
                  Math.ceil((allWithdrawals?.length ?? 0) / rowsPerPage) - 1
                }
              >
                <img
                  loading="lazy"
                  src="https://cdn.builder.io/api/v1/image/assets/TEMP/5865f0336c20abccc3587c9ba3d59bd337ea221a3d15684a7ea27b73d438917e?apiKey=bb278f95022541509fa4b766a9320e21&"
                  className="w-8 aspect-square"
                  alt="right"
                />
              </button>
            </div>

            {/* Pagination buttons */}
          </div>
        </Fade>
      </div>
    </>
  );
}

export default WithdrawalPage;
