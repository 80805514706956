/* eslint-disable react/prop-types */
import clsx from 'clsx';
import React from 'react';

export function Modal({ size, children, height }) {
  const sizes = {
    md: 'md:max-w-md',
    lg: 'md:max-w-lg',
    xl: 'md:max-w-xl',
    '3xl': 'md:max-w-3xl',
  };
  const appliedSize = sizes[size] || sizes.md;
  return (
    <div className="z-50 fixed inset-0 bg-[#54545440] backdrop-blur-sm">
      <div className="relative md:px-4 px-6 min-h-screen flex items-center justify-center">
        <div
          className={clsx(
            'inset-x-0 relative  w-full overflow-y-scroll border rounded-[20px] border-brand-border scrollbar-hide',
            appliedSize,
            height ? 'max-h-[920px]' : 'max-h-[520px]',
          )}
        >
          <div className="w-full bg-white md:rounded rounded-b-none rounded-lg h-fit relative overflow-auto">
            {children}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Modal;
